import logoText from '../assets/ShoofDoctor.png';
import logoRound from '../assets/logoHeartRound-192.png';
import logo from '../assets/logoShoof.png';

export const logoWithText = {
  alt: 'ShoofDoctor',
  src: logo,
};

export const logoWithoutText = {
  alt: 'ShoofDoctor',
  src: logoRound,
};

export const logoOnlyText = {
  alt: 'ShoofDoctor',
  src: logoText,
};
