import { Admin } from './Admin';
import { Doctor } from './Doctor';
import { Lab } from './Lab';
import { Patient } from './Patient';
import { Radio } from './Radio';

export enum UserRoleTypes {
  DOCTOR = 'doctor',
  PATIENT = 'patient',
  LAB = 'lab',
  RADIO = 'radio',
  ADMIN = 'admin',
}

export type UserProfile =
  | (Patient & { kind: UserRoleTypes.PATIENT })
  | (Doctor & { kind: UserRoleTypes.DOCTOR })
  | (Lab & { kind: UserRoleTypes.LAB })
  | (Radio & { kind: UserRoleTypes.RADIO })
  | (Admin & { kind: UserRoleTypes.ADMIN });

export type Verifiable = Extract<UserProfile, { verified?: boolean }>;
export type Testable = Extract<UserProfile, { testing?: boolean }>;
