import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React, { useState, ReactNode } from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    heading: {
      fontSize: theme.typography.pxToRem(15),
      flexShrink: 0,
    },
    activeHeading: {
      fontSize: theme.typography.pxToRem(15),
      flexShrink: 0,
      color: 'red',
    },
  }),
);

interface ExpansionElementProps {
  title: string;
  Component?: React.ComponentType;
  children?: ReactNode;
  isExpanded: boolean;
  active?: boolean;
  disabled?: boolean;
}

const ExpansionElementBooking = (props: ExpansionElementProps) => {
  const cx = useStyles();
  const { title, Component, children, isExpanded, active, disabled } = props;
  const [expanded, setExpanded] = useState<boolean>(isExpanded);
  const toggleTrueFalse = () => setExpanded(!expanded);

  return (
    <Accordion
      key={title}
      TransitionProps={{ unmountOnExit: true }}
      expanded={expanded}
      onChange={toggleTrueFalse}
      style={{ margin: '10px' }}
      disabled={disabled}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon style={{ color: 'black' }} />}
      >
        <Typography
          className={cx.heading}
          color={active ? 'primary' : 'textPrimary'}
          component={'span'}
        >
          <h1 style={{ margin: 0 }}>{title}</h1>
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        {Component ? <Component /> : children}
      </AccordionDetails>
    </Accordion>
  );
};

export default ExpansionElementBooking;
