/**
 * Turns array of objects into object with specified properties.
 * Use it for optimizing frequent searches of objects by some key.
 *
 * normalize('id', [
 *   {id: 'obj1', name: 'Rick'},
 *   {id: 'obj2', name: 'Morty'}
 * ])
 *
 * will result into
 *
 * {
 *   'obj1': {id: 'obj1', name: 'Rick'},
 *   'obj2': {id: 'obj2', name: 'Morty'}
 * }
 */
export const normalize = (key: string, objArr: { [key: string]: any }[]) =>
  objArr.reduce(
    (acc, next) => ({
      ...acc,
      [next[key]]: next,
    }),
    {},
  );
