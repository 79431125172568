import {
  Box,
  Grid,
  FormLabel,
  RadioGroup,
  FormControl,
} from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import moment from 'moment';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connectField } from 'uniforms';

import { fullDateWithTime } from '../../../constants/ConfigMoment';
import Calendar from './Calendar';
import DayData from './DayData';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
    },
    boxLegend: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      margin: theme.spacing(2),
      [theme.breakpoints.down('md')]: {
        margin: 0,
        marginBottom: theme.spacing(2),
      },
    },
    submitButton: {
      alignSelf: 'flex-end',
    },
    legend: {
      color: theme.palette.primary.main,
      margin: theme.spacing(2),
    },
  }),
);

const DateTimeForm = (props: any) => {
  const { doctorId, onChange, value, handleTimeslotChange } = props;
  const [date, setDate] = useState<any>(moment());

  const cx = useStyles();
  const { t } = useTranslation();
  const defaultDate = moment();
  const changeDate = (date: any) => {
    setDate(date);
  };

  return (
    <>
      <FormControl component="fieldset" className={cx.root}>
        <Box className={cx.boxLegend}>
          <FormLabel component="legend" classes={{ root: cx.legend }}>
            {value
              ? `${t('booking.visitStart')}${moment(value)
                  .locale('pl')
                  .format(fullDateWithTime)}`
              : `${t('booking.visitStartPicker')}`}
          </FormLabel>
          <Calendar handleChangeDate={changeDate} />
        </Box>
        <RadioGroup onChange={onChange} name="startName">
          <Grid container spacing={3} justify="center" wrap="nowrap">
            <Grid item xs={4}>
              <DayData
                doctorId={doctorId}
                handleTimeslotChange={handleTimeslotChange}
                day={(date || defaultDate).clone().subtract(1, 'days')}
                checkedTimeslot={value}
              />
            </Grid>
            <Grid item xs={4}>
              <DayData
                doctorId={doctorId}
                handleTimeslotChange={handleTimeslotChange}
                day={date || defaultDate}
                checkedTimeslot={value}
              />
            </Grid>
            <Grid item xs={4}>
              <DayData
                doctorId={doctorId}
                handleTimeslotChange={handleTimeslotChange}
                day={(date || defaultDate).clone().add(1, 'days')}
                checkedTimeslot={value}
              />
            </Grid>
          </Grid>
        </RadioGroup>
      </FormControl>
    </>
  );
};

export default connectField(DateTimeForm);
