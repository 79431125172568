export enum ReferralTypes {
  OPEN = 'open',
  USED = 'used',
}

export type DoctorReferralRaw = {
  id: string;
  bookingId: string;
  patientId: string;
  specialtyId: string | null;
  medTitle: string | null;
  status: ReferralTypes.OPEN | ReferralTypes.USED;
  note?: string;
};

export type DoctorReferral = {
  readonly id: string;
  readonly bookingId: string;
  readonly doctorId: string;
  readonly patientId: string;
  readonly createdAt: Date;
  updatedAt: Date;
  specialtyId: string;
  medTitle: string;
  status: ReferralTypes.OPEN | ReferralTypes.USED;
  note?: string;
};
