import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import React from 'react';
import { useTranslation } from 'react-i18next';

type RadioGroupFilterProps = {
  title: string;
  value: string;
  handleChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    uncheck: boolean,
  ) => void;
  options: { label: string; value: string }[];
  disableFilter?: boolean;
};

const RadioGroupFilter = ({
  title,
  value,
  handleChange,
  options,
  disableFilter,
}: RadioGroupFilterProps) => {
  const { t } = useTranslation();

  const handlePriceRangeChange = (event: any) => {
    value === event.target.value
      ? handleChange(event, true)
      : handleChange(event, false);
  };

  return (
    <FormControl component="fieldset">
      <FormLabel component="legend">{title}</FormLabel>
      <RadioGroup
        aria-label="radio"
        name="radioFilter"
        value={value}
        onChange={e => handleChange(e, false)}
      >
        {options.map(option => (
          <FormControlLabel
            key={option.label}
            value={option.value}
            control={<Radio onClick={handlePriceRangeChange} />}
            label={t(option.label)}
            disabled={disableFilter}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};

export default RadioGroupFilter;
