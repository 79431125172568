/* eslint-disable complexity */
import { Box, Button, Typography } from '@material-ui/core';
import { isEmpty, get } from 'lodash';
import React, { useState, useCallback, useEffect, CSSProperties } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';

import { doProfileUpdate } from '../../api/actions/auth';
import { showErrorToast } from '../../api/actions/uiControls';
import { BookingTypes, Booking, Doctor } from '../../api/types';
import { useUserId, useProfile, useSpecialties } from '../../api/usages';
import { stepsDescription } from '../../constants/ConfigStepper';
import { setSessionData } from '../../lib/sessionStorage';
import { isMobile } from '../../lib/utils';
import BookingRegistration from '../BookingRegistration';
import Doctors from '../Doctors/Doctors';
import DoctorDetails from '../Doctors/DoctorsDetails';
import Confirming from '../SetLabRadioBooking/Confirming';
import Complaint from './Complaint';
import GeoLimitationPopup from './GeoLimitationPopup';
// import IsUserInEgyptPopup from './IsUserInEgyptPopup';
import Payment from './Payment';
import Specialties from './Specialties';
import Stepper from './Stepper';
import DateTime from './TimePicker/DateTime';
// import TimezoneWrongPopup from './TimezoneWrongPopup';

// Constant id of primary care / family medicine specialty
const primaryCareSpecialtyId = 'obhPwt9YdkSKPqVa2Mkh';

// eslint-disable-next-line complexity
const SetBooking = (props: any) => {
  const {
    specialtyId: forcedSpecialtyId,
    medTitle,
    stepNo,
    doctor,
    model: forcedModel,
    withSpecialties,
  } = props;
  const { t } = useTranslation();
  const { state } = useLocation<{
    bookDoctorFromFavourite: boolean;
  }>();
  const [specialtyId, setSpecialtyId] = useState(forcedSpecialtyId);
  const [step, setStep] = useState(stepNo || 0);
  const [model, updateModel] = useState<Booking>(
    forcedModel || { type: BookingTypes.INITIAL },
  );
  const [doctorToConfirm, setDoctorToConfirm] = useState(doctor);
  const [practice, setPractice] = useState('');
  const [timeslot, setTimeslot] = useState<number>(15);
  const noUser = !useUserId();
  const profile = useProfile() || {};
  const userEmail = get(profile, 'email', undefined);
  const handleStepChange = useCallback((step: any) => {
    setStep(step);
  }, []);

  const handleConfirmDoctor = (doctor: Doctor) => {
    setDoctorToConfirm(doctor);
    setStep(step + 1);
  };
  const handleConfirmPractice = (practice: string) => {
    setPractice(practice);
    setStep(step + 1);
  };

  const handleGoBack = () => {
    setStep(step + -1);
  };

  const returnBackToStep3 = () => {
    showErrorToast(t('booking.dateChosenBefore'));
    let updatedModel: any;
    // eslint-disable-next-line prefer-const
    updatedModel = {
      ...model,
      startDate: null,
    };
    updateModel(updatedModel);
    setStep(3);
  };

  const handleGoBackWithSpecialtyId = (specialtyId: string) => {
    setStep((step: number) => step - 1);
    setSpecialtyId(specialtyId);
  };

  const handleStepSubmit = (value?: any) => {
    let updatedModel;
    if (typeof value === 'object') {
      updatedModel = {
        ...model,
        ...value,
      };
    } else {
      updatedModel = {
        ...model,
      };
    }
    if (step === 1) {
      updateModel(updatedModel);
    } else if (step === 3 && !value?.startDate) {
      updateModel(updatedModel);
    } else if (step === 4 && !value?.complaint) {
      showErrorToast(t('booking.complaintError'));
      updateModel(updatedModel);
    } else {
      updateModel(updatedModel);
      setStep(step + 1);
    }
  };

  useEffect(() => {
    if (step === 0 && specialtyId !== primaryCareSpecialtyId) {
      setSpecialtyId(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step]);

  const handleSetFavouriteDoctor = (
    doctorId: string,
    makeFavourite: boolean,
  ) => {
    let favouriteDoctorsIds = get(profile, 'favouriteDoctorsIds', []);
    if (makeFavourite) {
      favouriteDoctorsIds.push(doctorId);
    } else {
      favouriteDoctorsIds = favouriteDoctorsIds.filter(
        (id: string) => id !== doctorId,
      );
    }
    const updatedProfile = {
      ...profile,
      favouriteDoctorsIds,
    };
    doProfileUpdate(updatedProfile);
  };

  const handleUpdateTimeslot = (timeslot: number) => {
    setTimeslot(timeslot);
  };

  const onAuthorized = () => {
    setSessionData('booking', { model, step, doctor: doctorToConfirm });
  };
  // console.log(model);
  // console.log(step);
  return (
    <>
      <Helmet>
        <title>{t('home.specialistDoctor')}</title>
      </Helmet>
      <Stepper
        stepsList={stepsDescription}
        activeStep={step}
        onStepChange={handleStepChange}
      >
        {step === 0 && (
          <>
            {withSpecialties &&
            (!specialtyId || specialtyId !== primaryCareSpecialtyId) ? (
              <Specialties
                choosenSpecialtyId={specialtyId}
                setSpecialtyId={setSpecialtyId}
                primaryCareSpecialtyId={primaryCareSpecialtyId}
              />
            ) : null}

            <div style={{ textAlign: 'center' }}>
              <h1>{t('booking.chooseSpecialtyType')}</h1>

              <Box display={'flex'} gridGap={3}>
                <BookingCards
                  specialtyId={specialtyId || forcedSpecialtyId}
                  handleConfirmPractice={handleConfirmPractice}
                />
              </Box>
            </div>
          </>
        )}
        {step === 1 && (
          <>
            <GeoLimitationPopup />
            {specialtyId || forcedSpecialtyId ? (
              <Doctors
                specialtyId={specialtyId || forcedSpecialtyId}
                medTitle={medTitle}
                onChooseDoctor={handleConfirmDoctor}
                profile={profile}
                handleSetFavouriteDoctor={handleSetFavouriteDoctor}
              />
            ) : null}
          </>
        )}

        {step === 2 && (
          <DoctorDetails
            doctor={doctorToConfirm}
            setNextStep={handleStepSubmit}
            setPreviousStep={handleGoBackWithSpecialtyId}
            profile={profile}
            handleSetFavouriteDoctor={handleSetFavouriteDoctor}
          />
        )}

        {step === 3 && (
          <DateTime
            doctorId={doctorToConfirm.id}
            updateTimeslot={handleUpdateTimeslot}
            onSubmit={handleStepSubmit}
            setPreviousStep={handleGoBack}
            startDate={model.startDate}
            bookDoctorFromFavourite={state?.bookDoctorFromFavourite}
          />
        )}

        {step === 4 && (
          <Complaint onSubmit={handleStepSubmit} complaint={model.complaint} />
        )}

        {step === 5 && (noUser || !userEmail) && (
          <BookingRegistration onAuthorization={onAuthorized} />
        )}

        {step === 5 && !noUser && userEmail && (
          <Confirming onBack={handleGoBack} onSubmit={handleStepSubmit} />
        )}

        {step === 6 && (
          <Payment
            booking={{
              ...model,
              visitedByDoctor: false,
              visitedByPatient: false,
            }}
            doctor={doctorToConfirm}
            timeslot={timeslot}
            returnBackToStep3={returnBackToStep3}
          />
        )}
      </Stepper>
    </>
  );
};
export default SetBooking;

const BookingCards = ({ specialtyId, handleConfirmPractice }: any) => {
  const { specialties, error, loading } = useSpecialties();
  const history = useHistory();
  const { t } = useTranslation();
  const filteredSpecialties = specialties.filter(
    spec => spec.id === specialtyId,
  );

  if (error || loading) {
    return null;
  }
  // const { enabledClinic, enabledHome, enabledVideo } =
  //   filteredSpecialties[0] || {};
  const isHomeEnabled =
    !isEmpty(filteredSpecialties) &&
    filteredSpecialties[0]?.enabledHome &&
    filteredSpecialties[0].activeDrsCount.home >= 1;
  const isClinicEnabled =
    !isEmpty(filteredSpecialties) &&
    filteredSpecialties[0]?.enabledClinic &&
    filteredSpecialties[0].activeDrsCount.clinic >= 1;

  const isVideoEnabled =
    !isEmpty(filteredSpecialties) &&
    filteredSpecialties[0]?.enabledVideo &&
    filteredSpecialties[0].activeDrsCount.video >= 1;

  const phoneNumber = '+201101252220';

  const handleConfirmHomePractice = (name: string) => {
    if (isMobile) {
      window.location.href = `tel:${phoneNumber}`;
    } else {
      window.open(`tel:${phoneNumber}`);
    }
  };
  const handleConfirmClinicPractice = (name: string) => {
    // handleConfirmPractice(name);
    history.push('/clinic', {
      specialtyId,
    });
  };
  return (
    <>
      {specialtyId !== 'obhPwt9YdkSKPqVa2Mkh' && (
        <CardButton
          icon={'/bookingIcons/clinic.svg'}
          title={t('booking.bookingCards.clinic.title')}
          hint={t('booking.bookingCards.clinic.description')}
          handleConfirmPractice={() => handleConfirmClinicPractice('clinic')}
          isEnabled={isClinicEnabled}
        />
      )}
      <CardButton
        icon={'/bookingIcons/home.svg'}
        title={t('booking.bookingCards.home.title')}
        hint={t('booking.bookingCards.home.description')}
        handleConfirmPractice={() => handleConfirmHomePractice('home')}
        isEnabled={isHomeEnabled}
      />

      <CardButton
        icon={'/bookingIcons/video.svg'}
        title={t('booking.bookingCards.video.title')}
        hint={t('booking.bookingCards.video.description')}
        handleConfirmPractice={() => handleConfirmPractice('video')}
        isEnabled={isVideoEnabled}
      />
    </>
  );
};

const CardButton = ({
  icon,
  title,
  hint,
  handleConfirmPractice,
  isEnabled,
}: any) => {
  const buttonStyles: CSSProperties = {
    flex: '1',
    margin: '20px',
    color: 'white',
  };
  const boxStyles: CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 20px',
    gridGap: '20px',
  };
  return (
    <Button
      color="primary"
      variant="contained"
      style={buttonStyles}
      onClick={handleConfirmPractice}
      disabled={!isEnabled}
    >
      <Box style={boxStyles}>
        <img src={icon} alt="" width="50px" height="50px" />
        <Typography variant="h5" style={{ fontWeight: 'bold' }}>
          {title}
        </Typography>
        <Typography variant="subtitle1">{hint}</Typography>
      </Box>
    </Button>
  );
};
