import { FormControl, InputLabel, Input } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { connectField } from 'uniforms';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .MuiFormLabel-root': {
        direction: (props: any) => props.directionLabel,
        fontSize: '14px',
        [theme.breakpoints.up('sm')]: {
          fontSize: '16px',
        },
      },
      '& .MuiInputBase-input': {
        textAlign: (props: any) => props.textAlign,
        direction: (props: any) => props.directionInput,
      },
    },
  }),
);

function PhoneCodeField(props: any) {
  const {
    value = '',
    onChange,
    label,
    phoneNumber,
    properCodeLength,
    handleChangeCodeLength,
    disabled,
  } = props;
  const { i18n, t } = useTranslation();
  const dir = i18n.language === 'ar' ? 'rtl' : 'ltr';
  const styleProps = {
    textAlign: dir === 'rtl' ? 'right' : 'left',
    directionLabel: dir === 'rtl' ? 'rtl' : 'ltr',
    directionInput: dir === 'rtl' ? 'ltr' : 'ltr',
  };
  const cx = useStyles(styleProps);
  // const refference = useRef();
  let refference: HTMLDivElement | null = null;
  const [counter, setCounter] = useState<number>(0);

  function formatNumber(str: string) {
    if (str.length > 1 && dir === 'rtl') {
      return str.substring(1);
    }
    return str;
  }

  function handleChange({ target: { value } }: any) {
    onChange(value);
    handleChangeCodeLength(value.length);
  }

  const extendedLabel = `${label} ${formatNumber(phoneNumber)} (${t(
    'common.sixDigits',
  )})`;

  const sleep = (milliseconds: number) => {
    return new Promise(resolve => setTimeout(resolve, milliseconds));
  };

  useEffect(() => {
    if (counter < 5) {
      // Wait some unknown time for captcha and try a few times to set focus
      sleep(1000).then(() => {
        refference?.focus();
        setCounter(counter => counter + 1);
      });
    }
  }, [counter, refference]);

  return (
    <Box className={cx.root}>
      <FormControl fullWidth>
        <InputLabel htmlFor="phone-code">{extendedLabel}</InputLabel>
        <Input
          autoFocus
          inputRef={ref => (refference = ref)}
          id="phone-code"
          type="text"
          value={value}
          onChange={handleChange}
          inputProps={{ maxLength: properCodeLength }}
          disabled={disabled}
        />
      </FormControl>
    </Box>
  );
}

export default connectField(PhoneCodeField);
