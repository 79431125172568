import { Hidden, TextField, Typography } from '@material-ui/core';
import { Autocomplete, Skeleton } from '@material-ui/lab';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Language, Nationality } from '../../api/types';
import { useNationalities } from '../../api/usages';

interface CountryCodeInputProps {
  countryCode: string | undefined;
  handleCodeChange: (code: string | undefined) => void;
  isDialog?: boolean;
}

function countryToFlag(isoCode: string) {
  const charFromCodePoint =
    typeof String.fromCodePoint !== 'undefined'
      ? isoCode
          .toUpperCase()
          .replace(/./g, char =>
            String.fromCodePoint(char.charCodeAt(0) + 127397),
          )
      : isoCode;
  return charFromCodePoint;
}

function CountryCodeInput({
  handleCodeChange,
  isDialog,
  countryCode,
}: CountryCodeInputProps) {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const [
    selectedNationality,
    setSelectedNationality,
  ] = useState<Nationality | null>(null);
  const { nationalities, loading } = useNationalities();

  // useEffect(() => {
  //   if (nationalities.length > 0) {
  //     setSelectedNationality(
  //       nationalities.find((item: Nationality) => item.phoneCode === '20') ||
  //         null,
  //     );
  //   }
  // }, [nationalities]);

  const filterOptions = (
    options: Nationality[],
    { inputValue }: { inputValue: string },
  ) => {
    // Custom filter logic
    return options.filter(
      option =>
        option.translations.en
          .toLowerCase()
          .includes(inputValue.toLowerCase()) ||
        option.translations.ar
          .toLowerCase()
          .includes(inputValue.toLowerCase()) ||
        option.phoneCode.includes(inputValue),
    );
  };

  const renderAutocompleteOption = (option: any) =>
    `${option.translations[language || 'en']} (${option.nameISO3166_2}) ${
      language === 'en' ? '+' : ''
    }${option.phoneCode}${language === 'ar' ? '+' : ''}`;

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        gap: '10px',
        justifyContent: 'center',
      }}
    >
      <Hidden xsDown smDown={isDialog}>
        <div style={{ display: 'flex', flex: 3 }}>
          <Typography style={{ whiteSpace: 'nowrap' }}>
            {t('auth.countryCode')}
          </Typography>
        </div>
      </Hidden>
      <div
        style={{
          flex: 7,
        }}
      >
        <Autocomplete
          fullWidth
          options={nationalities}
          defaultValue={nationalities.find(option => option.phoneCode === '20')}
          autoHighlight
          getOptionLabel={option => renderAutocompleteOption(option)}
          renderOption={renderAutocompleteOption}
          renderInput={params => (
            <TextField
              {...params}
              inputProps={{
                ...params.inputProps,
                autoComplete: 'new-password',
              }}
            />
          )}
          onChange={(event, newValue) => {
            setSelectedNationality(newValue);
            handleCodeChange(newValue?.phoneCode);
          }}
          // groupBy={option => option.continent}
          value={selectedNationality || egyptOption}
          filterOptions={filterOptions}

          // renderGroup={group => <div>{group.group}</div>}
        />
      </div>
    </div>
  );
}

export default CountryCodeInput;

const egyptOption = {
  phoneCode: '20',
  region: 'Northern Africa',
  nameISO3166_2: 'EG',
  translations: {
    fr: 'Égypte',
    en: 'Egypt',
    ar: 'مصر',
  },
  name: 'Egypt',
  nationality: true,
  continent: 'Africa',
  group: 'suggestion',
  groupby: 'preferred',
  status: 'Member State',
  nameISO3166_3: 'EGY',
  id: '197236ddafc84d31bfbf19481a58ac60',
};
