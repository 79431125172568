import firebase from 'firebase/app';

import RouteTypes from '../../constants/routes';
import { auth, functions } from '../../lib/firebase';
import { createAction } from '../../lib/utils';
import { t } from '../../locales';
import {
  Currency,
  PaymentMethod,
  PaymentWallet,
  Price,
} from '../types/Payments';
import { navigate } from './navigation';
import { showErrorToast, showSuccessToast } from './uiControls';

type CashInParams = {
  price: Price;
  paymentMethod: PaymentMethod;
  billingInfo?: PaymentWallet['billingInfo'];
};

export type CashOutParams = {
  amount: number;
  currency: Currency;
  issuer: string;
  msisdn: string;
  firstName: string;
  lastName: string;
  email: string;
};

export const cashInPayment = createAction({
  type: 'cashInPayment',
  async callback({ price, paymentMethod, billingInfo }: CashInParams) {
    const patientId = auth.currentUser?.uid;
    if (!patientId) {
      throw new Error('No user');
    }

    const {
      data: { orderId },
    } = await functions.httpsCallable('cashInPayment')({
      price,
      paymentMethod,
      billingInfo,
    });

    if (orderId) {
      // Continue payment process
      navigate(RouteTypes.PAYMENT_PAY, { orderId });
    } else {
      throw new Error('Cannot make payment');
    }
  },
});

export const cashOutPayment = createAction({
  type: 'cashOutPayment',
  async callback({ amount, currency, ...params }: CashOutParams) {
    const userId = auth.currentUser?.uid;
    if (!userId) {
      throw new Error('No user');
    }

    try {
      const {
        data: { status, message, orderId, issuer },
      } = await functions.httpsCallable('cashOutPayment')({
        ...params,
        price: { amount, currency: currency || Currency.EGP },
      });

      if (orderId && issuer === 'AMAN') {
        // Continue payment process
        navigate(RouteTypes.PAYMENT_PAY, { orderId });
      } else if (status === 'pending' && message) {
        showSuccessToast(message);
      } else if (status.includes('success')) {
        showSuccessToast(t('payments.payoutSuccess'));
      } else {
        showErrorToast(message || t('payments.payoutError'));
      }
    } catch (e) {
      showErrorToast(t('payments.payoutError'));
    }
  },
});

export const getPaymentConfig = async (): Promise<{
  egDrCut: number;
  surCharge: number;
}> => {
  // getting configs
  const snapConf = await firebase
    .firestore()
    .doc('/publicConfigs/payment')
    .get();
  const egDrCut = snapConf.get('egDrCut') || 62.72;
  const surCharge = snapConf.get('surCharge') / 100 || 3.55;
  return { egDrCut, surCharge };
};

export const getGpCap = async (): Promise<number> => {
  // getting configs
  const snapConf = await firebase
    .firestore()
    .doc('/publicConfigs/payment')
    .get();
  const gpCap: number = snapConf.get('gpCap') || 6000;
  return gpCap;
};
