import MomentUtils from '@date-io/moment';
import { IconButton } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { ArrowBackIos, ArrowForwardIos } from '@material-ui/icons';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment from 'moment';
import 'moment/locale/ar-sa';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { fullDate } from '../../../constants/ConfigMoment';
import { i18next } from '../../../locales';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttons: {
      [theme.breakpoints.down('md')]: {
        padding: 5,
      },
    },
  }),
);

interface CalendarProps {
  handleChangeDate: (date: any) => void;
}

function Calendar(props: CalendarProps) {
  const { handleChangeDate } = props;
  const [selectedDate, setSelectedDate] = useState<any>(moment());
  const { t } = useTranslation();
  const cx = useStyles();
  const minDate = moment();

  const { language } = i18next;
  const direction = language === 'ar' ? 'rtl' : 'ltr';

  return (
    <div>
      <IconButton
        onClick={() => {
          setSelectedDate(selectedDate.clone().subtract(1, 'days'));
          handleChangeDate(selectedDate.clone().subtract(1, 'days'));
        }}
        disabled={selectedDate <= minDate}
        color="primary"
        className={cx.buttons}
      >
        {direction === 'ltr' ? <ArrowBackIos /> : <ArrowForwardIos />}
      </IconButton>

      <MuiPickersUtilsProvider utils={MomentUtils} locale="en">
        <DatePicker
          clearable
          label={t('common.selectDate')}
          okLabel={t('common.ok')}
          cancelLabel={t('common.cancel')}
          clearLabel={t('common.clear')}
          format={fullDate}
          value={selectedDate}
          onChange={date => {
            setSelectedDate(date);
            handleChangeDate(date);
          }}
          minDate={minDate}
        />
      </MuiPickersUtilsProvider>
      <IconButton
        onClick={() => {
          setSelectedDate(selectedDate.clone().add(1, 'days'));
          handleChangeDate(selectedDate.clone().add(1, 'days'));
        }}
        color="primary"
        className={cx.buttons}
      >
        {direction === 'ltr' ? <ArrowForwardIos /> : <ArrowBackIos />}
      </IconButton>
    </div>
  );
}

export default Calendar;
