import ROUTES from '../../constants/routes';

const { SIGN_UP_DOCTOR, SIGN_UP_LAB, FOR_DOCTOR, FOR_LAB } = ROUTES;

const englishOnlyPathnames = [SIGN_UP_DOCTOR, SIGN_UP_LAB, FOR_DOCTOR, FOR_LAB];

export function getI18nOptions(pathname: string) {
  if (
    englishOnlyPathnames.some(
      englishOnlyPathname => englishOnlyPathname === pathname,
    )
  ) {
    return { lng: 'en' };
  }

  return undefined;
}
