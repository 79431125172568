import firebase from 'firebase';

import * as navigation from '../api/actions/navigation';
import { UserRoleTypes } from '../api/types';
import RouteTypes from '../constants/routes';
import { changeLang } from '../locales';
import { auth, existsDoc } from './firebase';
import { getCurrentContextRole } from './sessionData';

/**
 * Checks if profile for user exists
 * @param {UserRoleTypes} profileType
 * @param {firebase.User=auth.currentUser} user currently sign in user
 * @throws exception if no user was provided and no one is signed in currently, or when `profileType` is unknown
 * @returns {Promise<boolean>}
 */
export async function hasProfileIn(
  profileType: UserRoleTypes,
  user?: firebase.User | null,
) {
  if (!user) {
    if (!auth.currentUser) {
      throw new Error('No sign in user');
    }
    user = auth.currentUser;
  }
  switch (profileType) {
    case UserRoleTypes.PATIENT:
      return await existsDoc(`patients/${user.uid}`);
    case UserRoleTypes.DOCTOR:
      return await existsDoc(`doctors/${user.uid}`);
    case UserRoleTypes.LAB:
      return await existsDoc(`labs/${user.uid}`);
    case UserRoleTypes.RADIO:
      return await existsDoc(`radios/${user.uid}`);
    case UserRoleTypes.ADMIN:
      return await existsDoc(`admins/${user.uid}`);
    default:
      throw Error('Unknown profile Type');
  }
}

export const goToPatientsLandingPage = async ({
  user = auth.currentUser,
} = {}) => {
  if (!(await hasProfileIn(UserRoleTypes.PATIENT, user))) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    if (user!.email) {
      navigation.replace(RouteTypes.PROFILE_CREATE);
    } else {
      navigation.replace(RouteTypes.PROFILE_CREATE_EMAIL);
    }
  } else {
    // eslint-disable-next-line no-lonely-if
    if (localStorage.getItem('signUpFromBooking')) {
      navigation.navigate(RouteTypes.RADIOS_LABS);
      localStorage.removeItem('signUpFromBooking');
    } else if (localStorage.getItem('signUpFromBookingPrimaryDoctor')) {
      navigation.navigate(RouteTypes.PRIMARY_DOCTORS);
      localStorage.removeItem('signUpFromBookingPrimaryDoctor');
    } else if (localStorage.getItem('signUpFromBookingSpecialists')) {
      navigation.navigate(RouteTypes.SPECIALISTS);
      localStorage.removeItem('signUpFromBookingSpecialists');
    } else if (localStorage.getItem('myProfile')) {
      navigation.navigate(RouteTypes.PROFILE);
      localStorage.removeItem('myProfile');
    } else if (localStorage.getItem('signUpFromBookingClinic')) {
      navigation.navigate(RouteTypes.CLINIC);
      localStorage.removeItem('signUpFromBookingClinic');
    } else {
      navigation.navigate(RouteTypes.HOME);
    }
  }
};

export const goToDoctorsLandingPage = async ({
  user = auth.currentUser,
} = {}) => {
  changeLang('en');
  if (!(await hasProfileIn(UserRoleTypes.DOCTOR, user))) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    if (user!.email) {
      navigation.replace(RouteTypes.PROFILE_CREATE);
    } else {
      navigation.replace(RouteTypes.PROFILE_CREATE_EMAIL);
    }
  } else {
    navigation.replace(RouteTypes.HOME);
  }
};

export const goToDoctorsSchedulePage = async ({
  user = auth.currentUser,
} = {}) => {
  if (!(await hasProfileIn(UserRoleTypes.DOCTOR, user))) {
    navigation.replace(RouteTypes.PROFILE_CREATE);
  } else {
    navigation.replace(RouteTypes.SCHEDULE);
  }
};

export const goToRadiosLandingPage = async ({
  user = auth.currentUser,
} = {}) => {
  changeLang('en');
  if (!(await hasProfileIn(UserRoleTypes.RADIO, user))) {
    // FIXME: need be changed after radiology will have profile
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    if (user!.email) {
      navigation.replace(RouteTypes.PROFILE_CREATE);
    } else {
      navigation.replace(RouteTypes.PROFILE_CREATE_EMAIL);
    }
  } else {
    navigation.replace(RouteTypes.HOME);
  }
};

export const goToLabsLandingPage = async ({ user = auth.currentUser } = {}) => {
  changeLang('en');
  if (!(await hasProfileIn(UserRoleTypes.LAB, user))) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    if (user!.email) {
      navigation.replace(RouteTypes.PROFILE_CREATE);
    } else {
      navigation.replace(RouteTypes.PROFILE_CREATE_EMAIL);
    }
  } else {
    navigation.replace(RouteTypes.HOME);
  }
};

// eslint-disable-next-line @typescript-eslint/require-await
export const goToAdminPanel = async () => {
  // TODO: ensure if user is an admin
  // FIXME: Change home on admin panel, when will be ready
  navigation.replace(RouteTypes.HOME);
};

export const goToLandingByContextRole = async () => {
  switch (await getCurrentContextRole()) {
    case UserRoleTypes.PATIENT:
      return await goToPatientsLandingPage();
    case UserRoleTypes.DOCTOR:
      return await goToDoctorsLandingPage();
    case UserRoleTypes.LAB:
      return await goToLabsLandingPage();
    case UserRoleTypes.RADIO:
      return await goToRadiosLandingPage();
    case UserRoleTypes.ADMIN:
      return await goToAdminPanel();
    default:
      throw Error('Unknown context role');
  }
};
