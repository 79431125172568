import { TextField, TextFieldProps } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import i18next from 'i18next';
import { find } from 'lodash';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Specialty, Language } from '../api/types';

// Consider moving to lib/helpers
export const getTranslatedSpeciality = (
  specialty: Specialty,
): { id: string; name: string } => {
  const name = specialty.translations[i18next.language as Language];
  return name
    ? { id: specialty.id ?? '', name }
    : { id: specialty.id ?? '', name: specialty.translations['en'] };
};

// Consider moving to lib/helpers
export const getSpecialtyId = (
  translatedSpecialties: { id: string; name: string }[],
  specialtyName: string | null,
): string => {
  if (!specialtyName) {
    return '';
  }

  let id = '';
  translatedSpecialties.forEach(s => {
    if (s.name === specialtyName) {
      id = s.id;
    }
  });
  return id;
};

interface SpecialtiesProps {
  choosenSpecialtyId?: string;
  setSpecialtyId: (specialtyId: string) => void;
  specialties: Specialty[];
  textFieldProps?: TextFieldProps;
}

const SpecialtyAutocomplete = ({
  choosenSpecialtyId,
  specialties,
  setSpecialtyId,
  textFieldProps = {},
}: SpecialtiesProps) => {
  const { t } = useTranslation();
  // const [inputValue, setInputValue] = useState('');

  const translatedSpecialties = specialties.map(specialty => {
    return getTranslatedSpeciality(specialty);
  });

  useEffect(() => {
    if (choosenSpecialtyId) {
      const choosenSpecialty = find(
        translatedSpecialties,
        translatedSpecialty => translatedSpecialty.id === choosenSpecialtyId,
      );
      setSpecialtyId(choosenSpecialty?.id || ''); // Set the specialty ID directly
    }
  }, [choosenSpecialtyId, translatedSpecialties, setSpecialtyId]);

  return (
    <Autocomplete
      onChange={(_event: object, newValue: string | null) => {
        const id = getSpecialtyId(translatedSpecialties, newValue);
        setSpecialtyId(id);
      }}
      options={translatedSpecialties.map(s => s.name)}
      renderInput={params => (
        <TextField
          {...params}
          margin="normal"
          label={t('common.speciality')}
          {...textFieldProps}
        />
      )}
      inputValue={
        choosenSpecialtyId
          ? find(
              translatedSpecialties,
              tSpecialty => tSpecialty.id === choosenSpecialtyId,
            )?.name || ''
          : ''
      }
    />
  );
};

export default SpecialtyAutocomplete;
