import React from 'react';
import { useTranslation } from 'react-i18next';

import LayoutDefault from '../components/LayoutDefault';
import Title from '../components/Title';

type AppErrorProps = {
  error: string | Error;
};

export const AppError = ({ error }: AppErrorProps) => {
  const { t } = useTranslation();
  return (
    <LayoutDefault>
      <Title title={t('app.AppError')} />
      <span>{error.toString()}</span>
    </LayoutDefault>
  );
};
