import { Backdrop, CircularProgress } from '@material-ui/core';
import get from 'lodash/get';
import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import { showInfoToast } from '../api/actions/uiControls';
import { useUser } from '../api/usages';
import ROUTES from '../constants/routes';

const CustomRoute = ({ component: Component, ...rest }: any) => {
  const profile = get(rest, 'profile', {});
  const profileKeys = Object.keys(profile);
  const user = useUser();

  if (!user) {
    return null;
  }
  console.log('profile tabStart', profile?.regCompleteness?.tabStart);
  const email = user?.email;
  const requiredFields = ['address', 'name', 'testing', 'email'].every(key =>
    profileKeys.includes(key),
  );

  if (profileKeys.length === 0) {
    return (
      <Backdrop open>
        <CircularProgress color="primary" />
      </Backdrop>
    );
  }
  // const pathes = [
  //   '/profile/edit/video',
  // ]
  console.log(rest.path);
  if (rest.path.includes('/profile/edit/')) {
    return <Route {...rest} render={props => <Component {...props} />} />;
  }
  if (
    !profile?.regCompleteness?.tabStart ||
    profile?.regCompleteness?.tabStart < 4 ||
    !profile?.regCompleteness?.practiceVideo ||
    profile?.regCompleteness?.practiceVideo < 4
  ) {
    return (
      <Redirect
        to={{
          pathname: ROUTES.PROFILE_CREATE,
          state: {
            from: ROUTES.PROFILE_CREATE,
          },
        }}
      />
    );
  }

  // return (
  //   <Route
  //     {...rest}
  //     render={props =>
  //       requiredFields ? (
  //         <Component {...props} />
  //       ) : email || profile.email ? (
  //         <Redirect to={ROUTES.PROFILE_CREATE} />
  //       ) : (
  //         <Redirect to={ROUTES.PROFILE_CREATE_EMAIL} />
  //       )
  //     }
  //   />
  // );
  // console.log('return');
  return <Route {...rest} render={props => <Component {...props} />} />;
};

export default CustomRoute;
