/* eslint-disable complexity */
import {
  Hidden,
  InputAdornment,
  LinearProgress,
  TextField,
  Typography,
} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import React, {
  Suspense,
  lazy,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';

import {
  showErrorToast,
  updateInitialisingProgress,
} from '../../api/actions/uiControls';
import { PhoneValidation } from '../../common';
import { RouteTypes } from '../../constants/routes';
import { functions } from '../../lib/firebase';
import { getI18nOptions } from '../../lib/utils';
import CountryCodeInput from './CountryCodeInput';

const PopupSheet = lazy(() => import('../PopupSheet'));

type EnterPhoneNumberParams = {
  onSentCode: Function;
  canSend?: boolean;
  current?: string;
  setPhoneNumber?: any;
  setFullPhone?: any;
  setErrorValidtion?: any;
  setValidtionMsg?: any;
  setValidtionPass?: any;
  errorStatus?: any;
  setErrorStatus?: any;
  profileType?: string;
  isDialog?: boolean;
};
const useStyles = makeStyles({});

const EnterPhoneNumber = ({
  onSentCode,
  canSend,
  current,
  setPhoneNumber,
  setFullPhone,
  setValidtionPass,
  errorStatus,
  setErrorStatus,
  profileType,
  isDialog,
}: EnterPhoneNumberParams) => {
  const { t } = useTranslation();
  const location = useLocation<any>();
  const options = getI18nOptions(location.pathname);
  const [phoneNumberInputValue, setPhoneNumberInputValue] = useState<string>(
    '',
  );
  const [countryCode, setCountryCode] = useState<string>('20');
  const [validtionMsg, setValidtionMsg] = useState('');
  const [showError, setShowError] = useState(false);
  const [popStatus, setPopStatus] = useState(false);
  const [numberNotExistpopStatus, setNumberNotExistpopStatus] = useState(false);
  const history = useHistory();
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const symbolsArr = ['e', 'E', '+', '-', '.'];

  useEffect(() => {
    console.log(location?.state?.phoneNumberFromSignIn);
    if (location?.state?.phoneNumberFromSignIn) {
      const prefixLength = 3; // Adjust based on the actual length of the prefix
      const phoneNumberWithoutPrefix = location?.state?.phoneNumberFromSignIn.substring(
        prefixLength,
      );
      handleSetPhone(phoneNumberWithoutPrefix);
      if (buttonRef?.current) {
        requestAnimationFrame(() => {
          buttonRef.current?.focus();
        });
      }

      // Remove phoneNumberFromSignIn from location.state
      const { phoneNumberFromSignIn, ...remainingState } = location.state;
      // Replace the current history entry with the updated state
      history.replace({
        ...location,
        state: remainingState,
      });
    }
  }, [location?.state?.phoneNumberFromSignIn]);

  const checkIfEmailAvailable = async (email: any, phoneNumber: any) => {
    try {
      const res = await functions.httpsCallable('checkIfEmailAvailable')({
        email,
        phoneNumber,
        collection: 'patients',
      });
      return res;
    } catch (error) {
      return { data: false };
    }
  };
  // eslint-disable-next-line consistent-return
  const sendSms = useCallback(async () => {
    updateInitialisingProgress(true);
    const validatePhoneNumber = () => {
      // add validation for phone number in egypt
      if (countryCode === '20') {
        let Phone = phoneNumberInputValue;
        console.log('phone number in egypt', phoneNumberInputValue);
        if (Phone.startsWith('0')) {
          Phone = Phone.slice(1);
        }
        if (!(Phone.startsWith('1') && Phone.length === 10)) {
          setValidtionMsg(t('errors.Phone.NotValidNumber'));
          return setShowError(true);
        }
      }
      const validation = PhoneValidation(phoneNumberInputValue);
      if (validation.message === 'zeroError') {
        setValidtionMsg(t('errors.Phone.notvalid'));
        return setShowError(true);
      }
      if (validation.valid === false) {
        setValidtionMsg(validation.message);
        return setShowError(true);
      }
      return validation.valid;
    };

    const handleRegistration = async () => {
      const fullPhone = `+${countryCode}${phoneNumberInputValue}`;
      const email = `${fullPhone}@shoofdoctor.com`;

      const res = await checkIfEmailAvailable(email, fullPhone);

      if (res?.data) {
        const filter = res.data.existIn.filter(
          (item: any) => item === `${profileType}s`,
        );

        if (filter?.length > 0) {
          if (profileType === 'doctor' || profileType === 'patient') {
            return setPopStatus(true);
          }
          return showErrorToast(t('common.emailExistError'));
        }

        setFullPhone(fullPhone);
        onSentCode(fullPhone);
      } else {
        if (profileType === ('doctor' || 'patient')) {
          return setPopStatus(true);
        }
        return showErrorToast(t('common.emailExistError'));
      }
    };

    const handleForgotPassword = async () => {
      const fullPhone = `+${countryCode}${phoneNumberInputValue}`;
      const email = `${fullPhone}@shoofdoctor.com`;

      const res = await checkIfEmailAvailable(email, fullPhone);

      if (res?.data) {
        const filter = res.data.existIn.filter(
          (item: any) => item === `${profileType}s`,
        );

        if (filter?.length > 0) {
          setFullPhone(fullPhone);
          return onSentCode(fullPhone);
        }

        return setNumberNotExistpopStatus(true);
      }
    };

    const handleOtherCases = () => {
      const fullPhone = `+${countryCode}${phoneNumberInputValue}`;
      setFullPhone(fullPhone);
      onSentCode(fullPhone);
    };
    try {
      if (validatePhoneNumber()) {
        switch (current) {
          case 'register':
            await handleRegistration();
            break;
          case 'forgot':
            await handleForgotPassword();
            break;
          default:
            handleOtherCases();
        }
      } else if (phoneNumberInputValue === '') {
        setValidtionMsg(t('common.emptyPhone'));
        setShowError(true);
      } else {
        setShowError(true);
      }
    } catch (error) {
      console.log(error);
    } finally {
      updateInitialisingProgress(false);
    }
  }, [
    onSentCode,
    countryCode,
    phoneNumberInputValue,
    setFullPhone,
    t,
    current,
    profileType,
  ]);
  const handleSetPhone = (val: string) => {
    if (/^[0-9]*$/.exec(val)) {
      const newVal1 = val.replace('١', '1');
      const newVal2 = newVal1.replace('٢', '2');
      const newVal3 = newVal2.replace('٣', '3');
      const newVal4 = newVal3.replace('٤', '4');
      const newVal5 = newVal4.replace('٥', '5');
      const newVal6 = newVal5.replace('٦', '6');
      const newVal7 = newVal6.replace('٧', '7');
      const newVal8 = newVal7.replace('٨', '8');
      const newVal9 = newVal8.replace('٩', '9');
      const newVal0 = newVal9.replace('٠', '0');
      setPhoneNumberInputValue(newVal0);
    }
    setErrorStatus ? setErrorStatus(false) : null;
    setShowError(false);
    if (PhoneValidation(val).message === 'zeroError') {
      console.log('zeroError', val);
      setValidtionMsg(t('errors.Phone.notvalid'));
      setValidtionPass ? setValidtionPass(false) : null;
    } else {
      console.log('errors.Phone.notvalid', val);
      setValidtionMsg(t('errors.Phone.exceed'));
    }
    setPhoneNumber && setPhoneNumber(val);
    if (PhoneValidation(val).valid) {
      setValidtionPass ? setValidtionPass(true) : null;
      const fullPhone = `+${countryCode}${val}`;
      console.log('fullPhone', fullPhone);
      setPhoneNumber && setPhoneNumber(fullPhone);
    }
  };
  const handleCodeChange = (value: any) => {
    setCountryCode(value);
    const newCode = `+${value}`;
    console.log('newCode', newCode);
    if (phoneNumberInputValue !== '') {
      const fullPhone = newCode + phoneNumberInputValue;
      setPhoneNumber && setPhoneNumber(fullPhone);
    }
  };

  useEffect(() => {
    if (phoneNumberInputValue === '') {
      setShowError(false);
    }
    if (errorStatus) {
      setShowError(true);
    }
  }, [phoneNumberInputValue, errorStatus]);

  useEffect(() => {
    if (location.state?.forcedMobileNumber) {
      handleSetPhone(location.state?.forcedMobileNumber);
    }
    // eslint-disable-next-line
  }, [location.state?.forcedMobileNumber]);

  const handleInputFocus = () => {
    if (inputRef?.current) {
      requestAnimationFrame(() => {
        inputRef.current?.focus();
      });
    }
  };

  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (
      e.key === 'Enter' &&
      current !== 'login' &&
      phoneNumberInputValue !== ''
    ) {
      // If Enter key is pressed, simulate a button click
      sendSms();
    }
  };

  return (
    <Box>
      <ErrorBoundary fallback={<p>Error</p>}>
        {profileType === 'doctor' && popStatus ? (
          <Suspense fallback={<LinearProgress />}>
            <PopupSheet
              withCheckCircle
              status={popStatus}
              setStatus={() => setPopStatus(false)}
              title={t('common.registrationPopup')}
              confirmButton={t('common.ok')}
              onOK={() => history.replace(RouteTypes.FOR_DOCTOR)}
            />
          </Suspense>
        ) : null}
        {profileType === 'patient' && popStatus ? (
          <Suspense fallback={<LinearProgress />}>
            <PopupSheet
              status={popStatus}
              setStatus={() => setPopStatus(false)}
              title={t('common.registrationPopup')}
              confirmButton={t('common.ok')}
              onOK={() =>
                history.replace(RouteTypes.SIGN_IN, {
                  forcedMobileNumber: phoneNumberInputValue,
                })
              }
            />
          </Suspense>
        ) : null}
        {profileType === 'patient' && numberNotExistpopStatus ? (
          <Suspense fallback={<LinearProgress />}>
            <PopupSheet
              status={numberNotExistpopStatus}
              setStatus={() => setNumberNotExistpopStatus(false)}
              // title={t('common.registrationPopup')}
              title={t('common.phoneNumberNotExist')}
              confirmButton={t('common.startRegistration')}
              onOK={() =>
                history.replace(RouteTypes.SIGN_UP, {
                  forcedMobileNumber: phoneNumberInputValue,
                })
              }
              onCancel={() => handleInputFocus()}
              cancelText={t('common.correctNumber')}
            />
          </Suspense>
        ) : null}
      </ErrorBoundary>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '10px',
        }}
      >
        <CountryCodeInput
          countryCode={countryCode}
          handleCodeChange={handleCodeChange}
          isDialog={isDialog}
        />

        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '10px',
            justifyContent: 'center',
          }}
        >
          <Hidden xsDown smDown={isDialog}>
            <div style={{ display: 'flex', flex: 3 }}>
              <Typography style={{ whiteSpace: 'nowrap' }}>
                {t('auth.mobileNo')}
              </Typography>
            </div>
          </Hidden>
          <div
            style={{
              flex: 7,
            }}
          >
            <TextField
              inputRef={inputRef}
              onChange={e => handleSetPhone(e.target.value)}
              value={phoneNumberInputValue}
              placeholder={t('auth.enterPhoneNumber')}
              inputProps={{
                inputMode: 'numeric',
                pattern: '[0-9]*',
                autoComplete: 'new-password',
              }}
              fullWidth
              onKeyDown={e => symbolsArr.includes(e.key) && e.preventDefault()}
              onKeyPress={handleKeyPress}
              helperText={showError && validtionMsg}
              error={showError}
              // InputProps={{
              //   startAdornment: (
              //     <InputAdornment
              //       position="start"
              //       style={{ padding: 0, margin: 0 }}
              //     >
              //       +{countryCode}
              //     </InputAdornment>
              //   ),
              //   style: {
              //     direction: 'ltr',
              //     alignItems: 'baseline',
              //   },
              // }}
            />
          </div>
        </div>
      </div>
      {current !== 'login' && (
        <Box>
          <br />
          <Button
            ref={buttonRef}
            variant="contained"
            color="primary"
            onClick={sendSms}
            disabled={!canSend || phoneNumberInputValue.length === 0}
          >
            {t('common.submit', options)}
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default EnterPhoneNumber;
