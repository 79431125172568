// import { LiveChatWidget, EventHandlerPayload } from '@livechat/widget-react';
import { Backdrop, CircularProgress } from '@material-ui/core';
import React, { Suspense } from 'react';
import { withErrorBoundary } from 'react-error-boundary';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Router } from 'react-router-dom';

import AppUrlListener from './AppUrlListener';
import ErrorFallback from './components/ErrorFallback';
import { GlobalProvider } from './context/GlobalContext';
import { initAuth } from './lib/firebase';
import { history } from './lib/utils';
import Screens from './screens';

import './api/actions/auth';
import './lib/syncTime';

// initialize firebase auth
const unsubscribe = initAuth();

// function handleNewEvent(event: EventHandlerPayload<'onNewEvent'>) {
//   console.log('LiveChatWidget.onNewEvent', event);
// }
const queryClient = new QueryClient();

export const App = withErrorBoundary(
  () => (
    <QueryClientProvider client={queryClient}>
      <GlobalProvider>
        <React.StrictMode>
          <Router history={history}>
            <AppUrlListener />
            <Suspense
              fallback={
                <Backdrop open>
                  <CircularProgress color="primary" />
                </Backdrop>
              }
            >
              <Screens />
            </Suspense>
          </Router>

          {/* <LiveChatWidget
        license="14660424"
        visibility="minimized"
        onNewEvent={handleNewEvent}
        customerName="osama"
      /> */}
        </React.StrictMode>
      </GlobalProvider>
    </QueryClientProvider>
  ),
  {
    FallbackComponent: ErrorFallback,
  },
);

window.addEventListener('unload', () => unsubscribe());
