import {
  Booking,
  Doctor,
  DoctorWithSpecialty,
  Language,
} from '../../api/types';

export default function getDoctorName(
  doctor:
    | Doctor
    | DoctorWithSpecialty
    | Booking['doctorDetails']
    | null
    | undefined,
  lang: Language,
) {
  if (!doctor) {
    return undefined;
  }

  return doctor.name?.[lang] || doctor.name?.en;
}
