import React, { createContext, useState } from 'react';

export const GlobalContext = createContext({
  service: '',
  setServiceType: (service: string) => {},
  price: 0,
  setPrice: (price: number) => {},
  categoryId: '',
  setCategoryId: (categoryId: string) => {},
});

export const GlobalProvider = ({ children }: any) => {
  const [service, setServiceType] = useState('');
  const [price, setPrice] = useState(0);
  const [categoryId, setCategoryId] = useState('');

  const setServiceValue = (value: string) => {
    setServiceType(value);
  };
  const setPriceValue = (value: number) => {
    setPrice(value);
  };

  const setCategoryIdValue = (value: string) => {
    setCategoryId(value);
  };

  const v = {
    service,
    setServiceType: setServiceValue,
    price,
    setPrice: setPriceValue,
    categoryId,
    setCategoryId: setCategoryIdValue,
  };
  return <GlobalContext.Provider value={v}>{children}</GlobalContext.Provider>;
};
