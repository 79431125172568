import firebase from 'firebase/app';
import 'firebase/auth';
import { BehaviorSubject, Observable, animationFrameScheduler } from 'rxjs';
import {
  pluck,
  distinctUntilChanged,
  map,
  observeOn,
  tap,
  shareReplay,
  skipWhile,
  switchMap,
} from 'rxjs/operators';

import { CURRENT_RUNTIME, RuntimeTypes } from '../../constants/app';

export const auth = firebase.auth();
const promisePersistence = auth
  .setPersistence(firebase.auth.Auth.Persistence.LOCAL)
  .catch(err => {
    console.error('auth.setPersistence', err);
  });

interface AuthUserContext {
  user: firebase.User | null;
  initializing: boolean;
}

export const authSubject = new BehaviorSubject<AuthUserContext>({
  user: auth.currentUser || null,
  initializing: !auth.currentUser,
});

const tokenSubject = new BehaviorSubject<
  firebase.auth.IdTokenResult | undefined
>(undefined);

export const token$ = tokenSubject.asObservable();

const auth$ = authSubject.pipe(
  switchMap(async data => {
    await promisePersistence;
    data.user = auth.currentUser;
    return data;
  }),
  observeOn(animationFrameScheduler),
);

export const initializingAuth$ = auth$.pipe(
  pluck('initializing'),
  distinctUntilChanged(),
  shareReplay(1),
);

export const authUser$: Observable<firebase.User | null> = auth$.pipe(
  skipWhile(({ initializing }) => initializing),
  pluck('user'),
);

export const userId$: Observable<string | null> = authUser$.pipe(
  map(user => (user && user.uid) || null),
  distinctUntilChanged(),
  tap(uid => {
    if (!uid) {
      console.info('👤 Unlogged in');
      return;
    }
    console.info('👤 User id', uid);
  }),
  shareReplay(1),
);

function onChangAuth(user: firebase.User | null) {
  authSubject.next({ user, initializing: false });
}

async function onChangeToken(user: firebase.User | null) {
  const token = await user?.getIdTokenResult();
  tokenSubject.next(token);
}

export const initAuth = () => {
  const authStateChangeUnsubscribe = auth.onAuthStateChanged(onChangAuth);
  const tokenCahngeUnsubscribe = auth.onIdTokenChanged(onChangeToken);

  return () => {
    authStateChangeUnsubscribe();
    tokenCahngeUnsubscribe();
  };
};

export const RecaptchaVerifier = firebase.auth.RecaptchaVerifier;
export const EmailAuthProvider = firebase.auth.EmailAuthProvider;
export const PhoneAuthProvider = firebase.auth.PhoneAuthProvider;

export const mobileAuth = async (phoneNumber: string) => {
  console.log('CURRENT_RUNTIME', CURRENT_RUNTIME);
  if (CURRENT_RUNTIME === RuntimeTypes.MobileApp) {
    const { signInMobile } = await import('../../mobileAppOnly/phoneAuth');
    return await signInMobile(phoneNumber);
  }
  return await doWithCaptcha(recaptchaVerifier =>
    auth.signInWithPhoneNumber(phoneNumber, recaptchaVerifier),
  );
};

export const mobileReauthenticate = async (user = auth.currentUser) => {
  if (!user || !user.phoneNumber) {
    throw new Error('No user or phone number');
  }
  if (CURRENT_RUNTIME === RuntimeTypes.MobileApp) {
    const { reauthenticateWithMobile } = await import(
      '../../mobileAppOnly/phoneAuth'
    );
    return await reauthenticateWithMobile(user);
  }
  return await doWithCaptcha(recaptchaVerifier =>
    user.reauthenticateWithPhoneNumber(
      user.phoneNumber as string,
      recaptchaVerifier,
    ),
  );
};

type DoWithCaptchaCallback = (
  recaptchaVerifier: firebase.auth.RecaptchaVerifier,
) => Promise<firebase.auth.ConfirmationResult | any>;

function doWithCaptcha(cb: DoWithCaptchaCallback) {
  const container = document.getElementById('recaptcha-container');
  const divElement = document.createElement('div');
  // @ts-ignore
  container.append(divElement);
  const recaptchaVerifier = new firebase.auth.RecaptchaVerifier(divElement, {
    size: 'invisible',
  });
  const resPromise = cb(recaptchaVerifier);
  // eslint-disable-next-line @typescript-eslint/unbound-method
  if (!resPromise?.then) {
    throw new TypeError(
      'Callback passed to withRecaptchaVerifier must be async',
    );
  }
  const finallyCb = () => {
    recaptchaVerifier.clear();
    //@ts-ignore
    container.innerHTML = '';
  };

  resPromise.then(finallyCb, finallyCb);
  return resPromise;
}
