import { Booking } from './Booking';
import { Timestamp } from './Timestamp';

export type Price = {
  amount: number;
  currency: Currency;
};

export enum OrderStatus {
  PENDING = 'pending',
  COMPLETED = 'completed',
  ERROR = 'error',
  TIMEOUT = 'timeout',
}

export enum OrderType {
  CASH_IN = 'cash-in',
  CASH_OUT = 'cash-out',
}

enum Vendor {
  PAYMOB = 'PAYMOB',
}

export enum PaymentMethod {
  FREE = 'free', // If no payment is required, e.g. a follow-up
  WALLET = 'wallet', // Internal Shoof wallet
  PAYMOB_CARD = 'paymob-card', // Pay with card using Paymob gateway
  PAYMOB_WALLET = 'paymob-wallet', // Pay with mobile wallet using Paymob gateway
  PAYMOB_KIOSK = 'paymob-kiosk', // Pay with AMAN (kiosk) using Paymob gateway
}

export enum Currency {
  EGP = 'EGP',
}

export enum WalletOperation {
  CASH_IN = 'cash-in', // Adding funds to wallet through payment provider
  CASH_OUT = 'cash-out', // Paying money out of the system
  VISIT_PAY = 'visit-pay', // Payment for a visit - in-app transfer from patient to system
  VISIT_RECEIVE = 'visit-receive', // Receive payment for a visit - in-app transfer from system to doctor
  VISIT_REFUND = 'visit-refund', // Receive a refund - in-app payment from system to patient
}

export type PaymentOrder = {
  readonly id: string;
  userId: string;
  price: Price;
  paymentMethod?: PaymentMethod;
  bookingData?: Booking;
  vendor: Vendor;
  status: OrderStatus;
  type?: OrderType;
  issuer?: 'AMAN' | 'VODAFONE' | 'ETISALAT' | 'ORANGE';
  vendorOrderId?: string;
  iframeUrl?: string;
  redirectUrl?: string;
  referenceNumber?: string;
  createdAt: Timestamp;
};

export type PaymentWallet = {
  readonly id: string; // same as userId
  accounts?: {
    [Currency.EGP]?: number;
  };
  billingInfo?: {
    apartment?: string;
    building?: string;
    city?: string;
    country?: string;
    email?: string;
    firstName?: string;
    floor?: string;
    lastName?: string;
    phoneNumber?: string;
    postalCode?: string;
    street?: string;
  };
};

export type PaymentWalletLog = {
  readonly id: string;
  price: Price;
  type: WalletOperation;
  orderId?: string;
  bookingId?: string;
  createdAt: Timestamp;
};
