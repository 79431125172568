import { Container } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Alert } from '@material-ui/lab';
import React, { memo, useCallback, useState } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import { useConnected } from '../../api/usages';
import Joyride from '../Joyride';
import Toast from '../Toast';
import AppBar from './AppBar';
import SideBar from './SideBar';
import ThemesProvider from './ThemesProvider';
import VerificationRedirect from './VerificationRedirect';
import VerificationStatusAlert from './VerificationStatusAlert';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    content: {
      flexGrow: 1,
      marginTop: theme.spacing(8),
      maxWidth: '100vw',
      [theme.breakpoints.down('sm')]: {
        marginTop: theme.spacing(8),
      },
      [theme.breakpoints.down('xs')]: {
        marginTop: theme.spacing(7),
      },
    },
    container: {
      [theme.breakpoints.down('sm')]: {
        padding: 0,
      },
    },
    offlineBar: {
      position: 'fixed',
      bottom: 0,
      width: '100%',
    },
  }),
);

interface LayoutDefaultProps {
  children?: any;
  fullSize?: boolean;
  title?: string;
  description?: string;
}

/**
 * Render a default layout for the application.
 *
 * @param {LayoutDefaultProps} props - The props object containing the following properties:
 *   - children: The content to be rendered inside the layout.
 *   - fullSize: A boolean indicating whether the layout should take up the full screen.
 *   - title: The title of the page.
 *   - description: A description of the page.
 * @returns {JSX.Element} The rendered default layout.
 */
const LayoutDefault = ({
  children,
  fullSize,
  title,
  description,
}: LayoutDefaultProps) => {
  const cx = useStyles();
  const [mobileOpen, setMobileOpen] = useState(false);
  const handleDrawerToggle = useCallback(() => setMobileOpen(!mobileOpen), [
    mobileOpen,
  ]);
  const { t } = useTranslation();
  const isConnected = useConnected();
  // @ts-ignore
  return (
    <HelmetProvider>
      <Helmet>
        {title && <title>{title}</title>}
        {description && <meta name="description" content={description} />}
      </Helmet>
      <ThemesProvider>
        <CssBaseline />
        <Joyride />
        <div className={cx.root}>
          <AppBar handleDrawerToggle={handleDrawerToggle} />
          <SideBar
            mobileOpen={mobileOpen}
            handleDrawerToggle={handleDrawerToggle}
          />
          <main className={cx.content}>
            {/* <VerificationStatusAlert /> */}
            <VerificationRedirect />
            {fullSize ? (
              children
            ) : (
              <Container className={cx.container} maxWidth="lg">
                {children}
              </Container>
            )}
            {!isConnected && (
              <Alert severity="warning" className={cx.offlineBar}>
                {t('mobile.offlineInfo')}
              </Alert>
            )}
          </main>
          <Toast key="MainToast" />
        </div>
      </ThemesProvider>
    </HelmetProvider>
  );
};

export const OnlyContent = (props: LayoutDefaultProps) => {
  const cx = useStyles();
  const { children } = props;
  const { t } = useTranslation();
  const isConnected = useConnected();
  if (!isConnected) {
    return null;
  }
  // @ts-ignore
  return (
    <HelmetProvider>
      <ThemesProvider>
        <CssBaseline />
        <div className={cx.root}>
          <main className={cx.content}>
            {/* <VerificationStatusAlert /> */}
            <VerificationRedirect />
            {props.fullSize ? (
              children
            ) : (
              <Container className={cx.container} maxWidth="lg">
                {children}
              </Container>
            )}
            {!isConnected && (
              <Alert severity="warning" className={cx.offlineBar}>
                {t('mobile.offlineInfo')}
              </Alert>
            )}
          </main>
          <Toast key="MainToast" />
        </div>
      </ThemesProvider>
    </HelmetProvider>
  );
};

export default memo(LayoutDefault);
