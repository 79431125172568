import firebase from 'firebase/app';
import 'firebase/storage';
import 'firebase/auth';
import { Observable } from 'rxjs';

export const storage = firebase.storage();
export type StorageReference = firebase.storage.Reference;
export type UploadTask = firebase.storage.UploadTask;
export type UploadTaskSnapshot = firebase.storage.UploadTaskSnapshot;
export type FileBlob = Blob & {
  name: string;
  lastModified?: number;
  type: string;
  size: number;
};

export type Entry = {
  url: string;
};

export type FileEntry = Entry & {
  file: FileBlob;
};

export type ExtendedFileEntry = FileEntry & {
  task: UploadTask;
  upload$: Observable<UploadTaskSnapshot>;
};

export const getLoggedInStorageRef = (
  path: string,
  editedPerson?: unknown,
  patientId?: string,
  editorId?: string,
) => {
  const currentUser = firebase.auth().currentUser;
  const rootDir = editedPerson
    ? editedPerson
    : patientId
    ? patientId
    : currentUser?.uid;

  if (editorId) {
    return storage.ref([rootDir, path, editorId].join('/'));
  }
  return storage.ref([rootDir, path].join('/'));
};
