/* eslint-disable complexity */
import Avatar from '@material-ui/core/Avatar';
import { red } from '@material-ui/core/colors';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Skeleton } from '@material-ui/lab';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme: Theme) => ({
  avatar: {
    backgroundColor: red[500],
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  // if custom avatar component is used in profile page and isProfile is true
  profilePicture: {
    backgroundColor: red[500],
    width: theme.spacing(9),
    height: theme.spacing(9),
    borderRadius: theme.shape.borderRadius,
    margin: '2px',
  },
}));

type AvatarProps = {
  name?: string | null | undefined | { ar: string; en: string };
  photoURL?: string | null | undefined;
  inProfile?: boolean;
};

const AvatarCustom = ({ name = '', photoURL, inProfile }: AvatarProps) => {
  const { t, i18n } = useTranslation();
  const cx = useStyles();
  const [imageLoaded, setImageLoaded] = useState(false);

  if (photoURL) {
    return (
      <>
        <Avatar
          className={inProfile ? cx.profilePicture : cx.avatar}
          src={photoURL}
          imgProps={{
            onLoad: () => setImageLoaded(true),
            onError: () => {
              console.log('error');
              setImageLoaded(true);
            },
          }}
          style={{ display: imageLoaded ? '' : 'none' }}
        />
        <Skeleton
          variant="circle"
          style={{
            display: imageLoaded ? 'none' : '',
            backgroundColor: '#f44336',
          }}
        >
          <Avatar className={inProfile ? cx.profilePicture : cx.avatar} />
        </Skeleton>
      </>
    );
  }
  // show if user didn't upload a photo but have a name as string
  if (typeof name === 'string') {
    return (
      <Avatar
        aria-label="avatar"
        className={inProfile ? cx.profilePicture : cx.avatar}
      >
        {name ? name.trim().charAt(0) : 'A'}
      </Avatar>
    );
  }
  // show if user didn't upload a photo but have a name as object {ar: string, en: string}
  if (typeof name === 'object') {
    const extractedName = i18n.language === 'ar' ? name?.ar : name?.en;
    return (
      <Avatar
        aria-label="avatar"
        className={inProfile ? cx.profilePicture : cx.avatar}
      >
        {extractedName ? extractedName.trim().charAt(0) : 'A'}
      </Avatar>
    );
  }
  return null;
};

export default AvatarCustom;
