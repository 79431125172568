import { Observable } from 'rxjs';
import { useObservable } from 'rxjs-hooks';
import { distinctUntilChanged, map } from 'rxjs/operators';

import { authUser$, userId$, initializingAuth$ } from '../../lib/firebase';
import { profile$ } from '../../lib/sessionData';
import { UserProfile, UserFieldTranslation } from '../types';

export const useAuthReady = () =>
  useObservable<boolean>(() => initializingAuth$.pipe(map(v => !v)), false);
export const useUserId = () => useObservable(() => userId$);

export const useUser = () => useObservable(() => authUser$);

export const useProfile = (): UserProfile | null =>
  useObservable(() => profile$);

const name$: Observable<string> = profile$.pipe(
  map((profile: { name: string | UserFieldTranslation } | null) => {
    if (!profile) {
      return '';
    }

    if (typeof profile.name === 'object') {
      return profile.name.en;
    }

    return profile.name;
  }),
  distinctUntilChanged(),
);

export const useUserName = () => {
  return useObservable(() => name$);
};
