import { Verifiable, UserRoleTypes } from '../../types';
import { useProfile } from '../auth';
import { useContextRole } from '../sessionData';

export const useVerificationStatus = () => {
  const role = useContextRole();
  const profile = useProfile();

  return (
    !role ||
    role === UserRoleTypes.ADMIN ||
    (profile as Verifiable)?.verified ||
    // for situation when user (patient) have created account but not created profile yet
    (role === UserRoleTypes.PATIENT &&
      (!profile || Object.keys(profile).length <= 1))
  );
};
