import { Healing, SvgIconComponent } from '@material-ui/icons';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import ChatIcon from '@material-ui/icons/Chat';
import DateRangeIcon from '@material-ui/icons/DateRange';
import Description from '@material-ui/icons/Description';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import FavoriteIcon from '@material-ui/icons/Favorite';
import HelpIcon from '@material-ui/icons/Help';
import HomeIcon from '@material-ui/icons/Home';
import LocalHospital from '@material-ui/icons/LocalHospital';
import NotesIcon from '@material-ui/icons/Notes';
import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import ViewListIcon from '@material-ui/icons/ViewList';

import RouteTypes from '../constants/routes';

enum ForSessionState {
  NO_AUTH = -1,
  ALL = 0,
  ALL_AUTH = 1,
  PATIENT = 2,
  DOCTOR = 3,
  LAB = 4,
  ADMIN = 5,
}

type SessionStateElement =
  | ForSessionState.NO_AUTH
  | ForSessionState.ALL
  | ForSessionState.ALL_AUTH
  | ForSessionState.PATIENT
  | ForSessionState.DOCTOR
  | ForSessionState.LAB
  | ForSessionState.ADMIN;

export const sideNavWidth = 240;

interface LayoutBaseItem {
  label: string;
  icon?: SvgIconComponent;
  showFor: ForSessionState;
  svg?: string;
  subItems?: Array<any>;
}
export interface LayoutRouteItem extends LayoutBaseItem {
  route: RouteTypes;
}
export interface LayoutActionItem extends LayoutBaseItem {
  callback: Function;
}
export interface LayoutCustomItem extends LayoutBaseItem {
  custom: 'sign-out';
}

export type LayoutItem = LayoutRouteItem | LayoutActionItem | LayoutCustomItem;

const handleShowFor = (
  arr: Array<LayoutItem>,
  showForArray: SessionStateElement[],
) =>
  arr.filter(({ showFor }) =>
    showForArray.some((val: SessionStateElement) => val === showFor),
  );

function getForAnonymous(arr: Array<LayoutItem>) {
  const { NO_AUTH, ALL } = ForSessionState;
  const showForArray = [NO_AUTH, ALL];

  return handleShowFor(arr, showForArray);
}

function getForDoctor(arr: Array<LayoutItem>) {
  const { ALL, ALL_AUTH, DOCTOR } = ForSessionState;
  const showForArray = [ALL, ALL_AUTH, DOCTOR];

  return handleShowFor(arr, showForArray);
}

function getForLab(arr: Array<LayoutItem>) {
  const { ALL, ALL_AUTH, LAB } = ForSessionState;
  const showForArray = [ALL, ALL_AUTH, LAB];
  return handleShowFor(arr, showForArray);
}

function getForPatient(arr: Array<LayoutItem>) {
  const { ALL, ALL_AUTH, PATIENT } = ForSessionState;
  const showForArray = [ALL, ALL_AUTH, PATIENT];

  return handleShowFor(arr, showForArray);
}

function getForAdmin(arr: Array<LayoutItem>) {
  return arr.filter(
    item =>
      item.showFor === ForSessionState.ADMIN ||
      (item as LayoutCustomItem).custom === 'sign-out',
  );
}

const mainNavConfig: Array<LayoutItem> = [
  {
    label: 'common.home',
    route: RouteTypes.HOME,
    icon: HomeIcon,
    showFor: ForSessionState.ALL,
  },
  {
    label: 'common.bookings',
    route: RouteTypes.BOOKINGS,
    icon: DateRangeIcon,
    showFor: ForSessionState.PATIENT,
  },
];

export const mainNavConfigAnonymous = getForAnonymous(mainNavConfig);
export const mainNavConfigDoctor = getForDoctor(mainNavConfig);
export const mainNavConfigPatient = getForPatient(mainNavConfig);
export const mainNavConfigLabRadio = getForLab(mainNavConfig);
export const mainNavConfigAdmin = getForAdmin(mainNavConfig);

//for video, clinic, home practice level (start, edit, finish)
type CompletenessLevel = {
  value: number | undefined;
  label: string;
};
const completenessLevels: CompletenessLevel[] = [
  { value: undefined, label: 'profile.start' },
  { value: 0, label: 'profile.start' },
  { value: 1, label: 'profile.finish' },
  { value: 2, label: 'profile.finish' },
  { value: 3, label: 'profile.edit' },
  { value: 4, label: 'profile.edit' },
];
const getCompletenessLevel = (value: number) => {
  const level = completenessLevels.find(level => level.value === value);
  return level ? level.label : '';
};

const sideNavConfig: Array<LayoutItem> = [
  {
    label: 'common.registration',
    route: RouteTypes.SIGN_UP,
    icon: AddCircleOutlineIcon,
    showFor: ForSessionState.NO_AUTH,
  },
  {
    label: 'common.signIn',
    route: RouteTypes.SIGN_IN,
    icon: AccountCircleIcon,
    showFor: ForSessionState.NO_AUTH,
  },
  {
    label: 'common.home',
    route: RouteTypes.HOME,
    icon: HomeIcon,
    showFor: ForSessionState.ALL,
  },
  {
    label: 'common.profile',
    route: RouteTypes.PROFILE,
    icon: AccountBoxIcon,
    showFor: ForSessionState.PATIENT,
  },
  {
    label: 'common.profileAll',
    route: RouteTypes.PROFILE,
    icon: AccountBoxIcon,
    showFor: ForSessionState.DOCTOR,
    subItems: [
      {
        label: 'booking.video',
        route: `${RouteTypes.PROFILE_EDIT_VIDEO}?editing=true`,
        svg: '/iconPracticeVideo.svg',
        // for practice level
        level: getCompletenessLevel,
      },
      {
        label: 'booking.clinic',
        route: `${RouteTypes.PROFILE_EDIT_CLINIC}?editing=true`,
        svg: '/iconPracticeClinic.svg',
        level: getCompletenessLevel,
      },
      {
        label: 'booking.home',
        route: `${RouteTypes.PROFILE_EDIT_HOME}?editing=true`,
        svg: '/iconPracticeHome.svg',
        level: getCompletenessLevel,
      },
    ],
  },
  {
    label: 'common.profileAll',
    route: RouteTypes.PROFILE,
    icon: AccountBoxIcon,
    showFor: ForSessionState.LAB,
  },
  {
    label: 'common.prescriptions',
    route: RouteTypes.PRESCRIPTIONS,
    icon: NotesIcon,
    showFor: ForSessionState.PATIENT,
  },
  {
    label: 'common.healthRecords',
    route: RouteTypes.HEALTH_RECORDS,
    icon: Healing,
    showFor: ForSessionState.PATIENT,
  },
  {
    label: 'common.favourites',
    route: RouteTypes.FAVOURITES,
    icon: FavoriteIcon,
    showFor: ForSessionState.PATIENT,
  },
  {
    label: 'common.bookings',
    route: RouteTypes.BOOKINGS,
    icon: DateRangeIcon,
    showFor: ForSessionState.PATIENT,
  },
  // {
  //   label: 'common.schedule',
  //   route: RouteTypes.SCHEDULE,
  //   icon: EventAvailableIcon,
  //   showFor: ForSessionState.DOCTOR,
  // },
  {
    label: 'payments.menuEarnings',
    route: RouteTypes.PAYMENT_WALLET,
    icon: AccountBalanceIcon,
    showFor: ForSessionState.DOCTOR,
  },
  {
    label: 'common.settings',
    route: RouteTypes.SETTINGS,
    icon: SettingsApplicationsIcon,
    showFor: ForSessionState.ALL_AUTH,
  },
  {
    label: 'common.help',
    route: RouteTypes.HELP,
    icon: HelpIcon,
    showFor: ForSessionState.ALL,
  },
  {
    label: 'common.doctor',
    route: RouteTypes.FOR_DOCTOR,
    icon: AccountCircleIcon,
    showFor: ForSessionState.NO_AUTH,
  },
  {
    label: 'common.lab',
    route: RouteTypes.FOR_LAB,
    icon: LocalHospital,
    showFor: ForSessionState.NO_AUTH,
  },
  {
    label: 'common.radio',
    route: RouteTypes.FOR_RADIO,
    icon: LocalHospital,
    showFor: ForSessionState.NO_AUTH,
  },
  {
    label: 'common.home',
    route: RouteTypes.HOME,
    icon: HomeIcon,
    showFor: ForSessionState.ADMIN,
  },
  {
    label: 'admin.managePatients',
    route: RouteTypes.MANAGE_PATIENTS,
    svg: '/account-injury-outline.svg',
    showFor: ForSessionState.ADMIN,
  },
  {
    label: 'admin.manageDoctors',
    route: RouteTypes.MANAGE_DOCTORS,
    svg: '/account-doctor-outline.svg',
    showFor: ForSessionState.ADMIN,
  },
  {
    label: 'admin.manageDialogs',
    route: RouteTypes.MANAGE_DIALOGS,
    icon: Description,
    showFor: ForSessionState.ADMIN,
  },
  {
    label: 'admin.manageLabs',
    route: RouteTypes.MANAGE_LABS,
    icon: LocalHospital,
    showFor: ForSessionState.ADMIN,
  },
  {
    label: 'admin.manageRadios',
    route: RouteTypes.MANAGE_RADIOS,
    icon: LocalHospital,
    showFor: ForSessionState.ADMIN,
  },
  {
    label: 'admin.manageAdmins',
    route: RouteTypes.MANAGE_ADMINS,
    icon: SupervisedUserCircleIcon,
    showFor: ForSessionState.ADMIN,
  },
  {
    label: 'admin.dataDumps',
    route: RouteTypes.DUMPS,
    icon: ViewListIcon,
    showFor: ForSessionState.ADMIN,
  },
  {
    label: 'admin.sendNotification',
    route: RouteTypes.SEND_NOTIFICATIONS,
    icon: ChatIcon,
    showFor: ForSessionState.ADMIN,
  },
  {
    label: 'admin.HH',
    route: RouteTypes.HH_FORM_LEADS,
    svg: '/leads.svg',
    showFor: ForSessionState.ADMIN,
  },
  {
    label: 'common.signOut',
    custom: 'sign-out',
    svg: '/signout.svg',
    showFor: ForSessionState.ALL_AUTH,
  },
];

export const sideNavConfigAnonymous = getForAnonymous(sideNavConfig);
export const sideNavConfigDoctor = getForDoctor(sideNavConfig);
export const sideNavConfigPatient = getForPatient(sideNavConfig);
export const sideNavConfigLabRadio = getForLab(sideNavConfig);
export const sideNavConfigAdmin = getForAdmin(sideNavConfig);
