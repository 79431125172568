import { useEffect, useState } from 'react';

import { db } from '../../lib/firebase';
import {
  PaymentOrder,
  PaymentWallet,
  PaymentWalletLog,
} from '../types/Payments';
import { useCollection } from './collection';
import { useDocument } from './document';

export function usePaymentOrders(userId: string | null) {
  const { results, error, loading } = useCollection<PaymentOrder>(
    {
      collectionPath: 'paymentOrders',
      query: {
        where: [
          {
            fieldPath: 'userId',
            opStr: '==',
            value: userId,
          },
        ],
        orderBy: ['createdAt', 'desc'],
      },
    },
    {
      skip: !userId,
    },
  );

  return {
    error,
    loading,
    orders: results,
  };
}

export function usePaymentOrder(orderId: string | null) {
  const { result, error, loading } = useDocument<PaymentOrder>(
    {
      path: `paymentOrders/${orderId}`,
    },
    {
      skip: !orderId,
    },
  );

  return {
    error,
    loading,
    order: result,
  };
}

export function usePaymentWalletAccounts(userId: string | null) {
  const { result, error, loading } = useDocument<PaymentWallet>(
    {
      path: `paymentWallets/${userId}`,
    },
    {
      skip: !userId,
    },
  );

  return {
    error,
    loading,
    wallet: result,
    accounts: result?.accounts || {},
  };
}

export function usePaymentWalletLogs(userId: string | null) {
  // FIXME: temporary workaround as useCollection does not work on subcollections
  const [logs, setLogs] = useState<PaymentWalletLog[]>([]);
  useEffect(() => {
    db.collection(`paymentWallets/${userId}/logs`)
      .orderBy('createdAt', 'desc')
      .get()
      .then(results => {
        setLogs(results.docs.map(ref => ref.data() as PaymentWalletLog));
      });
  }, [userId]);

  // TODO: this should be the actual query:
  // const { results, error, loading } = useCollection<PaymentWalletLog>(
  //   {
  //     collectionPath: `paymentWallets/${userId}/logs`,
  //     query: {
  //       orderBy: ['createdAt', 'desc'],
  //       limit: 100,
  //     },
  //   },
  //   {
  //     skip: !userId,
  //   },
  // );

  return {
    error: null,
    loading: false,
    logs,
  };
}
