import 'firebase/firestore';
import firebase from 'firebase';
import moment from 'moment';

// Visit is callable from 5 minutes (300s) before startTime to specified endTime.
export function isVisitCallable(
  startTime: firebase.firestore.Timestamp,
  endTime: firebase.firestore.Timestamp,
) {
  const now = moment(new Date());
  const start = moment(startTime.toDate()).subtract(300, 'seconds');
  const end = moment(endTime.toDate());

  return now.isBetween(start, end);
}

export function isAfterVisit(endTime: firebase.firestore.Timestamp) {
  const now = moment(new Date());
  const end = moment(endTime.toDate());

  return now.isAfter(end);
}
