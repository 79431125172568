import Grid from '@material-ui/core/Grid';
import { cloneDeep, set } from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  AutoForm,
  LongTextField,
  ErrorField,
  SubmitField,
} from 'uniforms-material';

import { complaintSchema } from '../../api/schemas/Booking';

interface ComplaintProps {
  onSubmit: (complaint: string) => void;
  complaint: any;
}

const Complaint = ({ onSubmit, complaint }: ComplaintProps) => {
  const { t } = useTranslation();
  const [model, setModel] = useState<any>(() => ({
    complaint,
  }));

  const handleFieldChange = (field: string, value: any) => {
    setModel(set(cloneDeep(model), field, value));
  };
  return (
    <Grid container justify="center" spacing={2}>
      <Grid item>
        <AutoForm
          schema={complaintSchema}
          onSubmit={onSubmit}
          model={model}
          onChange={handleFieldChange}
        >
          <LongTextField
            name="complaint"
            label={t('booking.step5_healthProblem')}
          />
          <SubmitField color="primary">{t('common.submit')}</SubmitField>
          <ErrorField name="complaint">
            {t('booking.complaintError')}
          </ErrorField>
        </AutoForm>
      </Grid>
    </Grid>
  );
};

export default Complaint;
