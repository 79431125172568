import i18next from 'i18next';
import queryString from 'query-string';
import React from 'react';

import { Specialty } from '../api/types';
import LayoutDefault from '../components/LayoutDefault';
import SetBooking from '../components/SetBooking';
import { popBookingFromSessionData } from '../lib/sessionStorage';

// Consider moving to lib/helpers
export const getTranslatedSpeciality = (
  specialty: Specialty,
): { id: string; name: string; enabled: boolean } => {
  const { id, translations, enabledClinic, enabledHome, enabledVideo, name } =
    specialty || {};
  const translatedName =
    translations && translations[i18next.language as 'ar' | 'en'];

  return {
    id: id || '',
    name: translatedName || name || 'No specialty defined',
    enabled: enabledClinic || enabledHome || enabledVideo,
  };
};

// Consider moving to lib/helpers
export const getSpecialtyId = (
  translatedSpecialties: { id: string; name: string }[],
  specialtyName: string | null,
): string => {
  if (!specialtyName) {
    return '';
  }

  let id = '';
  translatedSpecialties.forEach(s => {
    if (s.name === specialtyName) {
      id = s.id;
    }
  });
  return id;
};

const Specialists = () => {
  const result = popBookingFromSessionData();
  const { specialtyId } = queryString.parse(window.location.search) ?? {};

  return (
    <LayoutDefault>
      {result ? (
        <SetBooking
          withSpecialties
          stepNo={result.step || 1}
          model={result.model}
          doctor={result.doctor}
        />
      ) : (
        <SetBooking withSpecialties specialtyId={specialtyId} />
      )}
    </LayoutDefault>
  );
};

export default Specialists;
