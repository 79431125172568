/* eslint-disable complexity */
import { Plugins } from '@capacitor/core';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import {
  Theme,
  createStyles,
  makeStyles,
  useTheme,
} from '@material-ui/core/styles';
import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation } from 'react-router-dom';

import { UserRoleTypes } from '../../api/types';
import { useContextRole, useProfile } from '../../api/usages';
import {
  useInitialisingFirebase,
  useRuntime,
} from '../../api/usages/uiControls';
import {
  sideNavWidth,
  sideNavConfigAnonymous,
  sideNavConfigDoctor,
  sideNavConfigPatient,
  sideNavConfigLabRadio,
  sideNavConfigAdmin,
  LayoutRouteItem,
  LayoutActionItem,
  LayoutItem,
  LayoutCustomItem,
} from '../../constants/ConfigLayout';
import { logoOnlyText } from '../../constants/ConfigLogo';
import RouteTypes from '../../constants/routes';
import SignOutListItem from './SignOutListItem';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    drawer: {
      [theme.breakpoints.up('md')]: {
        width: sideNavWidth,
        flexShrink: 0,
      },
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
      width: sideNavWidth,
    },
    logo: {
      width: 'calc(100%)',
      minHeight: 64,
      padding: `calc(0.1*${sideNavWidth}px)`,
      paddingTop: `calc(0.05*${sideNavWidth}px)`,
    },
  }),
);

interface SideBarProps {
  mobileOpen: boolean;
  handleDrawerToggle: () => void;
}

function getItemProps(item: LayoutItem, defaultProps = {}) {
  const route = (item as LayoutRouteItem).route;
  const callback = (item as LayoutActionItem).callback;
  if (route) {
    return { component: NavLink, to: route, ...defaultProps };
  } else if (typeof callback === 'function') {
    return {
      component: 'button',
      onClick: callback as () => void,
      ...defaultProps,
    };
  }
  return { disabled: true, ...defaultProps };
}

const OmittedRoutesForMobileApp = [
  RouteTypes.FOR_DOCTOR,
  RouteTypes.FOR_LAB,
  RouteTypes.FOR_RADIO,
];

const SideBar = (props: SideBarProps) => {
  const profile: any = useProfile();
  const { Browser } = Plugins;
  const { mobileOpen, handleDrawerToggle } = props;
  const isInitialisingFirebase = useInitialisingFirebase();
  const role = useContextRole();
  const { t } = useTranslation();
  const location = useLocation();
  const { isMobileApp } = useRuntime();
  const cx = useStyles();
  const {
    palette: { primary },
  } = useTheme();

  const selectNavConfig = () => {
    switch (role) {
      case UserRoleTypes.DOCTOR:
        return sideNavConfigDoctor;
      case UserRoleTypes.LAB:
        return sideNavConfigLabRadio;
      case UserRoleTypes.RADIO:
        return sideNavConfigLabRadio;
      case UserRoleTypes.PATIENT:
        return sideNavConfigPatient;
      case UserRoleTypes.ADMIN:
        return sideNavConfigAdmin;
      default:
        return sideNavConfigAnonymous;
    }
  };

  const getPracticeName = (label: any) => {
    if (label === 'booking.video') {
      return 'practiceVideo';
    } else if (label === 'booking.home') {
      return 'practiceHome';
    } else if (label === 'booking.clinic') {
      return 'practiceClinic';
    }
    return undefined;
  };

  const renderMenuItems = (items: any[]) => {
    return items.map((item, index) => {
      let practiceLevel = undefined;
      if (item?.level && typeof item.level === 'function') {
        const practices = profile?.regCompleteness;
        const practiceName = getPracticeName(item.label);
        if (practiceName && practices && practices[practiceName]) {
          practiceLevel = item?.level(practices[practiceName]);
        } else {
          return null;
        }
      }
      const route = (item as LayoutRouteItem).route;
      const selected = !!item.route && item.route === location.pathname;
      const itemProps = getItemProps(
        item,
        route
          ? {
              selected,
              exact: route === '/',
              activeStyle: {
                backgroundColor: primary.main,
                color: primary.contrastText,
              },
            }
          : {},
      );

      const content = (
        <>
          <ListItemIcon
            style={{
              color: selected ? primary.contrastText : '#97289B',
            }}
          >
            {item?.icon ? (
              <item.icon />
            ) : (
              item?.svg && <img alt="svg" src={item.svg} />
            )}
          </ListItemIcon>
          <ListItemText
            primary={
              item?.level
                ? t(practiceLevel) + ' ' + t(item.label)
                : t(item.label)
            }
          />
        </>
      );
      if ((item as LayoutCustomItem).custom === 'sign-out') {
        return <SignOutListItem key={item.label}>{content}</SignOutListItem>;
      }
      if (isMobileApp && OmittedRoutesForMobileApp.includes(route)) {
        return (
          <Fragment key={item.label}>
            <ListItem
              button
              {...itemProps}
              onClick={async () => {
                await Browser.open({
                  url: `https://shoofdoctor.com${item.route}`,
                });
              }}
              className={`${item.label.replace('.', '_')}`}
            >
              {content}
            </ListItem>
          </Fragment>
        );
      }
      const notDisabledSidebarItems = ['common.home', 'common.help'];
      return (
        <Fragment key={item.label}>
          <ListItem
            button
            {...itemProps}
            className={`${item.label.replace('.', '_')}`}
            disabled={
              role === UserRoleTypes.DOCTOR &&
              (profile?.regCompleteness?.tabStart === undefined ||
                profile?.regCompleteness?.tabStart < 4 ||
                (profile?.regCompleteness?.practiceVideo || 0) < 4) &&
              !notDisabledSidebarItems.includes(item.label)
            }
          >
            {content}
          </ListItem>
          {item.subItems && item.subItems.length > 0 && (
            <div style={{ margin: '0 20px ' }}>
              {renderMenuItems(item.subItems)}
            </div>
          )}
        </Fragment>
      );
    });
  };
  const sideNavConfig = selectNavConfig();
  const drawer = isInitialisingFirebase ? null : (
    <div>
      <img alt={logoOnlyText.alt} src={logoOnlyText.src} className={cx.logo} />
      <div className={cx.toolbar} />
      <List>{renderMenuItems(sideNavConfig)}</List>
    </div>
  );
  return (
    <nav className={cx.drawer} aria-label="mailbox folders">
      {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
      <Hidden mdUp implementation="css">
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          classes={{
            paper: cx.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          {drawer}
        </Drawer>
      </Hidden>
      <Hidden smDown implementation="css">
        <Drawer
          classes={{
            paper: cx.drawerPaper,
          }}
          variant="permanent"
          open={!isInitialisingFirebase}
        >
          {drawer}
        </Drawer>
      </Hidden>
    </nav>
  );
};

export default SideBar;
