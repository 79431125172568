import LinearProgress from '@material-ui/core/LinearProgress';
import React from 'react';

import { useInitialisingProgress } from '../api/usages/uiControls';

const LoaderLinear = () => {
  const progressState = useInitialisingProgress();
  if (!progressState) {
    return null;
  }
  return <LinearProgress aria-busy {...progressState} />;
};

export default LoaderLinear;
