import EJSON from 'ejson';
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/database';
import { docData } from 'rxfire/firestore';
import { BehaviorSubject, Observable, of, combineLatest } from 'rxjs';
import {
  distinctUntilChanged,
  first,
  map,
  shareReplay,
  skipWhile,
  switchMap,
  filter,
} from 'rxjs/operators';

import { UserProfile, UserRoleTypes, Verifiable, Testable } from '../api/types';
import {
  AvailableLanguages,
  CURRENT_RUNTIME,
  RuntimeTypes,
} from '../constants/app';
import { auth, db, GeoPoint, userId$, token$, authSubject } from './firebase';
import { generateId } from './utils';

const defaultStoreData = () => ({
  userId: null,
  createdAt: firebase.firestore.Timestamp.now(),
  updatedAt: firebase.firestore.Timestamp.now(),
  runtimeType: CURRENT_RUNTIME,
});

const UNLOGGED_IN_DATA_KEY = '_sessionData_';
const readUnloggedInData = () => {
  const savedSessionData = localStorage.getItem(UNLOGGED_IN_DATA_KEY);
  if (savedSessionData) {
    try {
      return EJSON.parse(savedSessionData);
    } catch (err) {
      console.error('readUnloggedInData', err);
    }
  }
  return defaultStoreData();
};

const sessionUnLoggedIn$ = new BehaviorSubject(readUnloggedInData());

let _userSessionRef: firebase.firestore.DocumentReference | null = null;

export const sessionLoggedInRef$ = userId$.pipe(
  skipWhile(uid => !uid),
  distinctUntilChanged(),
  switchMap(async uid => {
    if (!uid) {
      console.info('🆔 virtual storage for unlogged-in');
      return null;
    }
    const sessionKey = 'sessionId_' + uid;
    let sessionId = localStorage.getItem(sessionKey);
    if (!sessionId) {
      sessionId = generateId();
      localStorage.setItem(sessionKey, sessionId);
    }
    const sessionPath = `/runtime/${uid}/sessions/${sessionId}`;
    // console.info('🆔 session:', sessionPath);
    const docRef = db.doc(sessionPath);
    await docRef.set(
      {
        ...sessionUnLoggedIn$.getValue(),
        userId: uid,
        updatedAt: firebase.firestore.Timestamp.now(),
      },
      { merge: true },
    );
    _userSessionRef = docRef;
    // clearing state for unlogged-in
    setTimeout(() => {
      localStorage.removeItem(UNLOGGED_IN_DATA_KEY);
      sessionUnLoggedIn$.next(defaultStoreData());
    });
    return docRef;
  }),
  shareReplay(1),
);

export const sessionData$ = userId$.pipe(
  switchMap(uid => {
    if (!uid) {
      return sessionUnLoggedIn$;
    }
    return sessionLoggedInRef$.pipe(
      switchMap(ref => {
        if (!ref) {
          return sessionUnLoggedIn$;
        }
        return docData(ref);
      }),
    );
  }),
  shareReplay(1),
);

/**
 * Updating store by mergeable set
 * @param obj that will be merged with store
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const setSessionData = async (obj: any) => {
  if (!obj || typeof obj !== 'object') {
    throw new TypeError('New state must be an object');
  }
  if (!auth.currentUser) {
    const currentState = sessionUnLoggedIn$.getValue();
    const nextSessionData = { ...currentState, ...obj };
    localStorage.setItem(
      UNLOGGED_IN_DATA_KEY,
      EJSON.stringify(nextSessionData),
    );
    sessionUnLoggedIn$.next(nextSessionData);
    return;
  }
  try {
    const ref = await sessionLoggedInRef$
      .pipe(first(ref => ref !== null))
      .toPromise();
    ref && (await ref.set(obj, { merge: true }));
  } catch (e) {
    console.error('setSessionData', e);
  }
};

export const getCurrentSessionData = async (key: string | null = null) => {
  const data = (await sessionData$.pipe(first()).toPromise()) as any;
  if (key) {
    return data[key];
  }
  return data;
};

export const clearLoggedInSessionDataAndLogOut = async () => {
  // keep language after sign out
  const language = await getCurrentSessionData('language');
  if (_userSessionRef) {
    await _userSessionRef.delete();
  }
  await auth.signOut();
  await setSessionData({ language });
};

if (typeof _devs === 'object') {
  _devs.setSessionData = setSessionData;
  _devs.showSessionData = async () => {
    const unLoggedInData = sessionUnLoggedIn$.getValue();
    console.info('🗄️ unLoggedInData', unLoggedInData);
    if (auth.currentUser) {
      const ref = await sessionLoggedInRef$
        .pipe(first(ref => ref !== null))
        .toPromise();
      const loggedInData = ref && (await ref.get()).data();
      console.info('🗄️ loggedInData', loggedInData);
    }
  };
}

type UserIdAndRole = {
  contextRole: keyof UserRoleTypes;
  userId: string;
};

export const profile$: Observable<UserProfile | null> = sessionData$.pipe(
  // @ts-ignore
  map(({ contextRole, userId }: UserIdAndRole) => ({
    contextRole,
    userId,
  })),
  switchMap(
    ({ contextRole, userId }): Observable<UserProfile | null> => {
      if (!userId) {
        return of(null);
      }
      const profileCollectionName = contextRole + 's';
      const ref = db.doc([profileCollectionName, userId].join('/'));
      return docData(ref).pipe(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        map((doc: any) =>
          doc ? ({ kind: contextRole, ...doc } as UserProfile) : null,
        ),
      );
    },
  ),
  shareReplay(1),
);

export const contextRole$: Observable<
  UserRoleTypes | undefined
> = sessionData$.pipe(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  map((state: any) => state?.contextRole),
  distinctUntilChanged(),
  shareReplay(1),
);

export const contextLanguage$: Observable<
  AvailableLanguages | undefined
> = sessionData$.pipe(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  map((state: any) => state?.language),
  distinctUntilChanged(),
  shareReplay(1),
);

/**
 * Get current state
 * @async
 */
export function getCurrentContextRole(): Promise<UserRoleTypes | undefined> {
  return contextRole$.pipe(first()).toPromise();
}

const tokenProfileRole$ = combineLatest(token$, profile$, contextRole$);

const { PATIENT, DOCTOR, LAB, RADIO } = UserRoleTypes;

export function forceTokenRefresh() {
  authSubject.getValue().user?.getIdToken(true);
}

tokenProfileRole$
  .pipe(
    filter(
      ([token, profile, role]) =>
        !!token &&
        !!profile &&
        !!role &&
        [PATIENT, DOCTOR, LAB, RADIO].includes(role) &&
        (!!token.claims[role] !== !!(profile as Verifiable).verified ||
          !!token.claims.testing?.[role] !== !!(profile as Testable).testing),
    ),
  )
  .subscribe(() => {
    // force token refresh in case of profile and token mismatch
    forceTokenRefresh();
  });

const geo$ = new BehaviorSubject(
  new GeoPoint(30.045241950590007, 31.229787606227585),
);

if (CURRENT_RUNTIME === RuntimeTypes.Browser && 'geolocation' in navigator) {
  navigator.geolocation.getCurrentPosition(
    ({ coords }) => {
      geo$.next(new GeoPoint(coords.latitude, coords.longitude));
    },
    warn => console.warn('geolocation observer', warn),
  );
  if ('watchPosition' in navigator.geolocation) {
    navigator.geolocation.watchPosition(
      ({ coords }) => {
        geo$.next(new GeoPoint(coords.latitude, coords.longitude));
      },
      warn => console.warn('geolocation observer', warn),
      {
        enableHighAccuracy: false,
        timeout: 5000,
        maximumAge: 60000 * 5,
      },
    );
  }
}

if (CURRENT_RUNTIME === RuntimeTypes.MobileApp) {
  import('../mobileAppOnly').then(({ geolocation }) => {
    geolocation.getCurrentPosition(
      ({ coords }) => {
        geo$.next(new GeoPoint(coords.latitude, coords.longitude));
      },
      warn => console.warn('geolocation observer', warn),
    );
    geolocation.watchPosition(
      ({ coords }) => {
        geo$.next(new GeoPoint(coords.latitude, coords.longitude));
      },
      warn => console.warn('geolocation observer', warn),
      {
        enableHighAccuracy: false,
        timeout: 5000,
        maximumAge: 60000 * 5,
      },
    );
  });
}

export const currentGEOLocation$ = geo$;

const _isConnected$ = new BehaviorSubject(true);
firebase
  .database()
  .ref('.info/connected')
  .on('value', snapshot => {
    const isConnected: boolean = snapshot.val();
    // eslint-disable-next-line no-console
    console.log('🌐 connection:', isConnected ? 'connected' : 'disconnected');
    _isConnected$.next(isConnected);
  });

export const isConnected$ = _isConnected$.pipe(shareReplay(1));

isConnected$.subscribe(isOnline => {
  if (isOnline) {
    db.enableNetwork();
  } else {
    db.disableNetwork();
  }
});

export const isCurrentlyConnected = () => _isConnected$.getValue();
