import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { reauthenticateWithPhoneNumber } from '../api/actions/auth';
import { useUser } from '../api/usages';
import { RecaptchaVerifier } from '../lib/firebase';
import ConfirmCode from './PhoneVerification/ConfirmCode';

type Props = {
  onClose: () => void;
  onReauthenticated: () => void;
};

const ReauthWithPhoneDialog = ({ onClose, onReauthenticated }: Props) => {
  const { t } = useTranslation();
  const user = useUser();
  const [recaptchaVerifier, setRecaptchaVerifier] = useState<any>(null);
  const [onConfirmCode, setOnConfirmCode] = useState<Function | null>(null);
  const [recaptchaEl, setRecaptchEl] = useState<any>(null);

  useEffect(() => {
    if (!recaptchaEl) {
      return () => undefined;
    }

    const captchaVerifier = new RecaptchaVerifier(recaptchaEl, {
      size: 'invisible',
    });

    captchaVerifier
      .render()
      .then(() => setRecaptchaVerifier(captchaVerifier))
      .catch(err => console.warn(err));

    return () => {
      captchaVerifier.clear();
    };
  }, [recaptchaEl]);

  useEffect(() => {
    if (!recaptchaVerifier) {
      return;
    }

    const { complete } = reauthenticateWithPhoneNumber({
      recaptchaVerifier,
      onReauthenticated,
    });

    setOnConfirmCode(complete);
  }, [recaptchaVerifier, onReauthenticated]);

  return (
    <Dialog fullWidth onClose={onClose} open>
      <div ref={setRecaptchEl} />
      <DialogContent>
        <h2>{t('auth.reauthRequired')}</h2>
        <ConfirmCode
          onConfirmCode={onConfirmCode ?? (() => undefined)}
          phoneNumber={user?.phoneNumber ?? ''}
          disabled={!onConfirmCode}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ReauthWithPhoneDialog;
