import { createBridge, JSONSchema } from '../../lib/utils';
const startDate: JSONSchema = {
  title: 'Guest Booking',
  type: 'object',
  properties: {
    startDate: { type: 'string' },
    endDate: { type: 'string' },
  },
  required: ['startDate'],
};

const complaint: JSONSchema = {
  title: 'Guest Booking',
  type: 'object',
  properties: {
    complaint: { type: 'string', minLength: 1, maxLength: 256 },
  },
  required: ['complaint'],
};

const pdfWithResult: JSONSchema = {
  title: 'Order PDF',
  type: 'object',
  properties: {
    pdfUrl: {
      type: 'string',
      uniforms: {
        acceptedFiles: ['application/pdf'],
      },
    },
  },
  required: ['pdfUrl'],
};

export const startDateSchema = createBridge(startDate);
export const complaintSchema = createBridge(complaint);
export const pdfSchema = createBridge(pdfWithResult);
