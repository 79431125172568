import firebase from 'firebase/app';
import 'firebase/firestore';

import { Query, SimpleQuery, FullQuery } from '../../api/types';

export const db = firebase.firestore();
export type Timestamp = firebase.firestore.Timestamp;
export const Timestamp = firebase.firestore.Timestamp;
export type GeoPoint = firebase.firestore.GeoPoint;
export const GeoPoint = firebase.firestore.GeoPoint;

export const timestampNow = () =>
  firebase.firestore.FieldValue.serverTimestamp();

export const existsDoc = async (path: string) => {
  const ref = await db.doc(path).get();
  return ref.exists;
};

export const writeData = async (
  documentPath: Array<string> | string,
  userData: any,
) => {
  if (Array.isArray(documentPath)) {
    documentPath = documentPath.join('/');
  }
  return await db
    .doc(documentPath)
    .set({ ...userData, updatedAt: timestampNow() });
};

export const deleteData = async (documentPath: Array<string> | string) => {
  if (Array.isArray(documentPath)) {
    documentPath = documentPath.join('/');
  }
  return await db.doc(documentPath).delete();
};

export const mergeData = async (
  documentPath: Array<string> | string,
  userData: any,
) => {
  if (Array.isArray(documentPath)) {
    documentPath = documentPath.join('/');
  }
  return await db
    .doc(documentPath)
    .set({ ...userData, updatedAt: timestampNow() }, { merge: true });
};

export const removeData = async (documentPath: Array<string> | string) => {
  if (Array.isArray(documentPath)) {
    documentPath = documentPath.join('/');
  }
  return await db.doc(documentPath).delete();
};

const getSimpleQueryRef = (
  collName: string,
  simpleQuery?: SimpleQuery,
): firebase.firestore.Query<firebase.firestore.DocumentData> => {
  const ref = db.collection(collName);

  if (!simpleQuery || !simpleQuery.length) {
    return ref;
  }

  let queryRef: any;

  simpleQuery.forEach(
    ({ fieldPath, opStr, value }) =>
      (queryRef = (queryRef || ref).where(fieldPath, opStr, value)),
  );

  return queryRef;
};

const getOrderedQuery = (
  queryRef: firebase.firestore.Query<firebase.firestore.DocumentData>,
  orderBy: string | [string, 'asc' | 'desc'] | [string, 'asc' | 'desc'][],
): firebase.firestore.Query<firebase.firestore.DocumentData> => {
  if (!Array.isArray(orderBy)) {
    return queryRef.orderBy(orderBy);
  }

  if (!Array.isArray(orderBy[0])) {
    return queryRef.orderBy(
      orderBy[0],
      (orderBy as [string, 'asc' | 'desc'])[1],
    );
  }

  return ((orderBy as [string, 'asc' | 'desc'][]).reduce(
    (queryRef, orderBy) => getOrderedQuery(queryRef, orderBy),
    queryRef,
  ) as unknown) as firebase.firestore.Query<firebase.firestore.DocumentData>;
};

const getFullQueryRef = (collName: string, fullQuery?: FullQuery) => {
  if (!fullQuery) {
    return getSimpleQueryRef(collName);
  }

  const { where, orderBy, limit } = fullQuery;

  let queryRef = getSimpleQueryRef(collName, where);

  if (orderBy) {
    queryRef = getOrderedQuery(queryRef, orderBy);
  }

  if (limit) {
    queryRef = queryRef.limit(limit);
  }

  return queryRef;
};

export const getQueryRef = (collName: string, query?: Query) =>
  Array.isArray(query)
    ? getSimpleQueryRef(collName, query)
    : getFullQueryRef(collName, query);
