import { Plugins } from '@capacitor/core';
import {
  useContext,
  useEffect,
  createContext,
  useState,
  useRef,
  useCallback,
} from 'react';
import { useObservable } from 'rxjs-hooks';
import {
  AudioTrackPublication,
  LocalAudioTrack,
  LocalTrackPublication,
  LocalVideoTrack,
  Participant,
  RemoteParticipant,
  RemoteTrackPublication,
  Room,
  VideoTrackPublication,
} from 'twilio-video';

import { IS_IOS } from '../../constants/app';
import RouteTypes from '../../constants/routes';
import {
  room$,
  token$,
  roomId$,
  roomState$,
  roomError$,
} from '../../lib/video';
import { navigate } from '../actions/navigation';
import { showInfoToast } from '../actions/uiControls';
import { UserRoleTypes } from '../types';
import { Booking } from '../types/Booking';
import { useDocument } from './document';
import { useContextRole } from './sessionData';

const noop = () => {
  // do nothing
};

type TrackPublication = LocalTrackPublication | RemoteTrackPublication;

const RoomContext = createContext<Room | null>(null);

export const useVideoToken = () => {
  return useObservable(() => token$);
};

/**
 * This hook will setup audio/video and connect to the video call room.
 * It can be used only in a single place in the app, possible in a top level call component.
 * All other usages should make use of useRoom instead!
 */
export const useVideoCall = () => {
  return {
    room: useObservable(() => room$),
    state: useObservable(() => roomState$),
    error: useObservable(() => roomError$),
    RoomProvider: RoomContext.Provider,
  };
};
export const SetRoom = (roomId: string | null) => {
  useEffect(() => {
    roomId$.next(roomId);
    return () => roomId$.next(null);
  }, [roomId]);
};
export const useRoom = () => useContext(RoomContext);

/**
 * This hook will transform the `room.participants` map to an array and always keep it up to date,
 * including re-renders when participant changes and cleanup.
 */
export const useParticipants = () => {
  const room = useRoom();
  const [participants, setParticipants] = useState<RemoteParticipant[]>([]);

  useEffect(() => {
    if (!room) {
      setParticipants([]);
      return noop;
    }

    if (window.location.search.includes('debug')) {
      // Debug mode, to emulate remote participant using a local feed
      setParticipants([
        (room.localParticipant as unknown) as RemoteParticipant,
      ]);
      console.warn('[video] DEBUG MODE: Emulating remote participant');
      return noop;
    }

    // Set initial list
    setParticipants(Array.from(room.participants.values()));

    // Check for updates
    const connected = (participant: RemoteParticipant) =>
      setParticipants(prev => [...prev, participant]);

    const disconnected = (participant: RemoteParticipant) =>
      setParticipants(prev => prev.filter(p => p !== participant));

    room.on('participantConnected', connected);
    room.on('participantDisconnected', disconnected);

    return () => {
      room.off('participantConnected', connected);
      room.off('participantDisconnected', disconnected);
    };
  }, [room]);

  return participants;
};

/**
 * This hook extracts publications from a participant.
 * Intended as internal helper.
 */
const usePublications = (participant?: Participant) => {
  const [publications, setPublications] = useState<TrackPublication[]>([]);

  useEffect(() => {
    if (!participant) {
      setPublications([]);
      return noop;
    }

    setPublications(
      Array.from(participant.tracks.values()) as TrackPublication[],
    );

    const published = (track: TrackPublication) =>
      setPublications(prev => [...prev, track]);
    const unpublished = (track: TrackPublication) =>
      setPublications(prev => prev.filter(p => p !== track));

    participant.on('trackPublished', published);
    participant.on('trackUnpublished', unpublished);

    return () => {
      participant.off('trackPublished', published);
      participant.off('trackUnpublished', unpublished);
    };
  }, [participant]);

  return publications;
};

/**
 * This hook will sync a DOM element with an audio or video publication and expose a ref.
 * Intended as internal helper.
 */
const useTrackRef = <El extends HTMLMediaElement>(
  publication?: AudioTrackPublication | VideoTrackPublication,
) => {
  const [track, setTrack] = useState(publication?.track);
  const ref = useRef<El>(null);

  useEffect(() => {
    if (!publication) {
      setTrack(null);
      return noop;
    }

    setTrack(publication.track);
    const unsubscribed = () => setTrack(null);

    publication.on('subscribed', setTrack);
    publication.on('unsubscribed', unsubscribed);

    return () => {
      publication.off('subscribed', setTrack);
      publication.off('unsubscribed', unsubscribed);
    };
  }, [publication]);

  useEffect(() => {
    const el = ref.current;
    if (!track || !el) {
      return noop;
    }

    if (track.kind === 'video') {
      el.muted = true;
    }
    track.attach(el);

    return () => {
      track.detach(el);
    };
  }, [track]);

  return [ref, track] as const;
};

/**
 * This hook will expose a connected and synced audio/video ref for a given participant.
 * This is the hook you are looking for if you want to get participant audio/video feed.
 */
export const useMediaRefs = (participant?: Participant) => {
  const publications = usePublications(participant);

  // For now, pick the first publications of each kind
  // There may be a need to make this logic more robust in the future
  const audioPub = publications.find(t => t.kind === 'audio') as
    | AudioTrackPublication
    | undefined;
  const videoPub = publications.find(t => t.kind === 'video') as
    | VideoTrackPublication
    | undefined;

  const [audioRef, audioTrack] = useTrackRef<HTMLAudioElement>(audioPub);
  const [videoRef, videoTrack] = useTrackRef<HTMLVideoElement>(videoPub);

  return { audioRef, audioTrack, videoRef, videoTrack };
};

/**
 * Used to mute/unmute local audio and pause/unpause local video tracks
 */
export const useToggleTrack = (track?: LocalAudioTrack | LocalVideoTrack) => {
  const [isEnabled, setEnabled] = useState<boolean>(track?.isEnabled || true);

  const toggleTrack = useCallback(
    (enable = !isEnabled) => {
      track?.enable(enable);
    },
    [isEnabled, track],
  );

  useEffect(() => {
    if (!track) {
      return noop;
    }
    const enabled = () => setEnabled(true);
    const disabled = () => setEnabled(false);
    track.on('enabled', enabled);
    track.on('disabled', disabled);

    return () => {
      track.off('enabled', enabled);
      track.off('disabled', disabled);
    };
  }, [track]);

  return [isEnabled, toggleTrack] as const;
};

/**
 * End the call if the booking is finished
 */
export const useVideoCallEnd = (bookingId: string) => {
  const role = useContextRole();

  const { result: booking } = useDocument<Booking>({
    path: `bookings/${bookingId}`,
  });

  useEffect(() => {
    if (booking?.status !== 'finished') {
      return;
    }

    if (role === UserRoleTypes.DOCTOR) {
      navigate(RouteTypes.SESSION_SUMMARY, { bookingId });
    }

    if (role === UserRoleTypes.PATIENT) {
      if (IS_IOS) {
        const { TwilioVideoIos } = Plugins;
        TwilioVideoIos.disconnect();
      }
      navigate(RouteTypes.CALL_REVIEW, { id: bookingId });
    }
  }, [booking, role, bookingId]);
};

export const useNotifyDoctor = () => {
  const role = useContextRole();

  useEffect(() => {
    if (role === UserRoleTypes.DOCTOR) {
      showInfoToast({
        message: `
        Check EHR before making any conclusive remarks in the ADD section.
        Please note that all fields in ADD section are Required to be filled satisfactorily
        for the fees to be transferred
      `,
        time: 15000,
      });
    }
  }, [role]);
};
