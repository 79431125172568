import { Query, CertificateCategories, CertificateTypes } from '../types';
import { UseCollectionOptions, useCollection } from './collection';

export const useCertificatesCategories = (
  query?: Query,
  options?: UseCollectionOptions,
) => {
  const { results: certificateCategories, loading, error } = useCollection<
    CertificateCategories
  >(
    {
      collectionPath: 'certificateCategories',
      query,
    },
    options,
  );

  return { certificateCategories, loading, error };
};

export const useCertificatesTypes = (
  query?: Query,
  options?: UseCollectionOptions,
) => {
  const { results: certificateTypes, loading, error } = useCollection<
    CertificateTypes
  >(
    {
      collectionPath: 'certificateTypes',
      query,
    },
    options,
  );

  return { certificateTypes, loading, error };
};
