import { Button, Box } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import get from 'lodash/get';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { signOutAndLogin, signOutAndRegister } from '../../api/actions/auth';
import { useProfile, useDocument } from '../../api/usages';
import { getTranslation, calcAge } from '../../lib/utils';
import { i18next } from '../../locales';
import StandardList from '../StandardList';
interface EnterNoteProps {
  onBack: () => void;
  onSubmit?: any;
  style?: React.CSSProperties;
  onSubmitLabRadio?: any;
}

const getListItems = (rawItems: string[]) => {
  return rawItems.map((content: string, i) => {
    return {
      key: i.toString(),
      value: <div dangerouslySetInnerHTML={{ __html: content }} />,
    };
  });
};

const Confirming = ({
  onBack,
  onSubmit,
  onSubmitLabRadio,
  style,
}: EnterNoteProps) => {
  const { t } = useTranslation();
  const profile = useProfile();

  const lang = i18next.language;
  let mobile = get(profile, 'phoneNumber', '');
  const name = get(profile, 'name', '');
  const gender =
    get(profile, 'gender', '') === 'male'
      ? t('booking.male')
      : t('booking.female');
  const address = get(profile, 'address', [{}]);
  const fetchedBirthday = get(profile, 'birthday', '');

  // Temp fix - as used in HealthRecordsForm.tsx
  moment.locale('en');
  const age = calcAge(fetchedBirthday);
  moment.locale(lang);

  const areaId = get(profile, 'areaId', '');
  const governorateId = address[0].governorateId;

  const { result: governorateData }: any = useDocument(
    {
      path: `governorates/${governorateId}`,
    },
    { skip: !governorateId },
  );

  const governorate = getTranslation(governorateData);

  const { result: areaData }: any = useDocument(
    {
      path: `areas/${areaId}`,
    },
    { skip: !areaId },
  );

  const area = getTranslation(areaData);

  if (lang === 'ar') {
    const y = mobile.replace('+', '');
    mobile = y + '+';
  }

  const listItems = getListItems([
    t('booking.enterNoteInfo_1'),
    t('booking.enterNoteInfo_2', {
      name,
      gender,
      age,
      mobile,
      area,
      governorate,
    }),
    t('booking.enterNoteInfo_3'),
  ]);

  const LogRegisterCard = ({ text, textBtn, action }: any) => {
    return (
      <div
        style={{
          backgroundColor: 'white',
          borderRadius: 10,
          padding: 10,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          margin: '0 10px',
          flex: 1,
        }}
      >
        <Button
          variant="contained"
          style={{ backgroundColor: '#e0e0e0', color: '#dc01e4' }}
          onClick={() => {
            action();
          }}
        >
          {textBtn}
        </Button>
        <Typography style={{ marginTop: 10, fontSize: 7 }}>{text}</Typography>
      </div>
    );
  };

  return (
    <div style={style}>
      <Grid
        style={{
          paddingLeft: '30px',
          paddingRight: '30px',
        }}
        container
        justify="center"
        spacing={2}
      >
        <Grid item>
          <StandardList
            subheader={t('booking.readBeforeConfirming')}
            items={listItems}
          />
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-around',
              margin: '10px 0',
            }}
          >
            <LogRegisterCard
              text={t('booking.noteButton1info')}
              textBtn={t('booking.noteButton1')}
              action={signOutAndLogin}
            />
            <LogRegisterCard
              text={t('booking.noteButton2info')}
              textBtn={t('booking.noteButton2')}
              action={signOutAndRegister}
            />
          </div>
          <Box display="flex" justifyContent="space-between">
            <Button
              style={{ backgroundColor: '#e0e0e0', color: '#dc01e4' }}
              variant="contained"
              onClick={onBack}
            >
              {t('common.back')}
            </Button>
            <Button
              onClick={() =>
                onSubmitLabRadio
                  ? onSubmitLabRadio()
                  : onSubmit && onSubmit({ confirmed: true })
              }
              color="primary"
              variant="contained"
            >
              {t('booking.confirmed')}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default Confirming;
