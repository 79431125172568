import {
  Button,
  Dialog,
  DialogContent,
  Grid,
  List,
  ListItem,
  ListItemText,
  Paper,
  TextField,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { ChevronRight, ExpandMore } from '@material-ui/icons';
import { TreeItem, TreeView } from '@material-ui/lab';
import React, { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import { useQueryClient, useMutation, useQuery } from 'react-query';

import { showErrorToast, showSuccessToast } from '../api/actions/uiControls';
import LayoutDefault from '../components/LayoutDefault';
import { db } from '../lib/firebase';

function Dialogs() {
  return (
    <>
      <LayoutDefault title="Dialogs" description="Dialogs">
        <MarkdownEditor />
      </LayoutDefault>
    </>
  );
}

export default Dialogs;
const fetchDoctorDialogs = async () => {
  try {
    const doctorDialogsRef = db.collection('dialogs');
    const doc = await doctorDialogsRef.doc('dr').get();

    if (doc.exists) {
      return doc.data();
    }

    throw new Error('Doctor document not found');
  } catch (error) {
    throw new Error(
      `Error fetching doctor dialogs: ${(error as Error).message}`,
    );
  }
};

const useMutatDoctorDialogs = () => {
  const QueryClient = useQueryClient();
  return useMutation(
    async (data: any) => {
      const doctorDialogsRef = db.collection('dialogs').doc('dr');
      const updatedDialog = { ...data };
      await doctorDialogsRef.update(updatedDialog);
    },
    {
      onSuccess: () => {
        showSuccessToast("Doctor's dialogs updated successfully");
        QueryClient.invalidateQueries('dialogs');
      },
      onError: err => {
        showErrorToast('Error updating doctor dialogs: ' + err);
      },
    },
  );
};

const useDoctorDialogs = () => {
  return useQuery(['dialogs'], () => fetchDoctorDialogs(), {
    refetchOnWindowFocus: false,
  });
};

const MarkdownEditor = () => {
  const [markdownValue, setMarkdownValue] = useState('');
  const [markdownKey, setMarkdownKey] = useState<any>();
  const [modalOpen, setModalOpen] = useState(false);
  const [expanded, setExpanded] = useState<string[]>([]);
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const { mutate } = useMutatDoctorDialogs();
  const { data, isLoading } = useDoctorDialogs();
  if (isLoading) {
    return <div>Loading...</div>;
  }
  type NestedObject = {
    [key: string]: string | NestedObject;
  };

  const handleToggle = (event: React.ChangeEvent<{}>, nodeIds: string[]) => {
    setExpanded(nodeIds);
  };

  const RecursiveTreeView: React.FC<{
    obj: NestedObject;
    parentKey?: string[];
  }> = ({ obj, parentKey = [] }) => (
    <TreeView
      defaultCollapseIcon={<ExpandMore />}
      defaultExpandIcon={<ChevronRight />}
      expanded={expanded}
      onNodeToggle={handleToggle}
    >
      {Object.keys(obj).map(key => {
        const value = obj[key];
        return (
          <Fragment key={key}>
            {key === 'en' || key === 'ar' || typeof value === 'string' ? (
              <TreeItem
                nodeId={[...parentKey, key].join('.')}
                label={
                  <div>
                    <Typography variant="h6">{key}</Typography>
                    <Typography variant="caption" color="inherit">
                      {typeof value === 'string'
                        ? value.slice(0, 50) + (value.length > 50 ? '...' : '')
                        : ''}
                    </Typography>
                  </div>
                }
                onClick={() => {
                  setMarkdownValue(value as string);
                  setMarkdownKey([...parentKey, key]);
                  setModalOpen(true);
                }}
              />
            ) : (
              // <Button>{`${key}`}</Button>
              <TreeItem
                nodeId={[...parentKey, key].join('.')}
                label={<Typography variant="h6">{key}</Typography>}
              >
                <RecursiveTreeView
                  parentKey={[...parentKey, key]}
                  obj={value}
                />
              </TreeItem>
            )}
          </Fragment>
        );
      })}
    </TreeView>
  );
  return (
    <>
      <Dialog
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        maxWidth="md"
      >
        <DialogContent>
          <MarkdownEditorForm
            markdownValue={markdownValue}
            setMarkdownValue={setMarkdownValue}
            markdownKey={markdownKey}
            mutate={mutate}
            setModalOpen={setModalOpen}
          />
        </DialogContent>
      </Dialog>
      <br />
      {data && <RecursiveTreeView obj={data} />}
      {/* {data && (
        <ListItemRender
          data={data}
          language={language}
          setMarkdownKey={setMarkdownKey}
          setMarkdownValue={setMarkdownValue}
        />
      )} */}
      <br />
    </>
  );
};

const ListItemRender = ({
  data,
  language,
  setMarkdownKey,
  setMarkdownValue,
}: any) => {
  return (
    <List>
      {Object.keys(data).map((key1, index1) => {
        const value = data[key1];
        // console.log(value, 'value');
        if (typeof value === 'string') {
          return (
            <ListItem
              key={index1}
              button
              // onClick={() => setMarkdown(data[key1]?.body || { en: '', ar: '' })}
              onClick={() => console.log(value)}
            >
              <ListItemText primary={key1} secondary={value} />
            </ListItem>
          );
        }
        if (value.en) {
          return (
            <ListItem
              key={index1}
              button
              onClick={
                () => {
                  setMarkdownKey(key1);
                  setMarkdownValue(value);
                }
                // onClick={() => console.log(value)}
              }
            >
              <ListItemText
                primary={key1}
                secondary={value[language].slice(0, 50) + '...'}
              />
            </ListItem>
          );
        }
        if (value.title) {
          const title = value.title;
          const body = value.body;

          return (
            <ListItem
              key={index1}
              button
              // onClick={() => setMarkdown(data[key1]?.body || { en: '', ar: '' })}
              onClick={() => console.log(value)}
            >
              <ListItemText
                primary={key1}
                secondary={
                  <>
                    <Typography component="span" variant="body2">
                      <strong>Title:</strong>{' '}
                      {title[language].slice(0, 50) + '...'}
                    </Typography>
                    <br />
                    <Typography component="span" variant="body2">
                      <strong>Body:</strong>{' '}
                      {body[language].slice(0, 50) + '...'}
                    </Typography>
                  </>
                }
              />
            </ListItem>
          );
        }
      })}
    </List>
  );
};

const useStyles = makeStyles(theme => ({
  customTextField: {
    minHeight: 300,
    '& textarea': {
      height: '100%',
    },
    '& .MuiInputBase-root': {
      height: '100%',
    },
  },
}));
const MarkdownEditorForm = ({
  markdownValue,
  markdownKey,
  mutate,
  setModalOpen,
}: any) => {
  const {
    i18n: { language },
  } = useTranslation();
  const classes = useStyles();
  const [modalData, setModalData] = useState(markdownValue);
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          {/* <Typography>Edit</Typography> */}
          <TextField
            rows={14}
            multiline
            placeholder="Write your Markdown here..."
            value={modalData}
            onChange={e => {
              const value = e.target.value;
              setModalData(value);
            }}
            variant="outlined"
            fullWidth
            style={{ height: '100%' }}
            className={classes.customTextField}
            autoFocus
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          {/* <Typography>Preview</Typography> */}
          <Paper
            elevation={3}
            style={{ flex: 1, padding: '15px', height: '100%' }}
          >
            <Typography component="div">
              <ReactMarkdown>{modalData}</ReactMarkdown>
            </Typography>
          </Paper>
        </Grid>
      </Grid>
      <br />

      <Button
        variant="contained"
        color="primary"
        onClick={() => {
          const key = markdownKey.join('.');
          const updatedDialog = {
            [key]: modalData,
          };
          mutate(updatedDialog);
          setModalOpen(false);
        }}
      >
        {language === 'en' ? 'Save' : 'حفظ'}
      </Button>
      <br />
    </>
  );
};
