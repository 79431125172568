/* eslint-disable complexity */
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import {
  EventNote,
  Share,
  ArrowBackIos,
  ArrowForwardIos,
} from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import get from 'lodash/get';
import React, { useEffect, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { withErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';

import { getPaymentConfig } from '../../api/actions/payments';
import { Doctor, Specialty, UserRoleTypes, Language } from '../../api/types';
import { useDocument, useContextRole } from '../../api/usages';
import { MetaTags } from '../../common';
import RouteTypes from '../../constants/routes';
import { formatPrice, isMobile } from '../../lib/utils';
import getDoctorName from '../../lib/utils/getDoctorName';
import { i18next } from '../../locales';
import { getTranslatedSpeciality } from '../../screens/Specialists';
import GetLink from '../GenerateLinkForCopy';
import DoctorRow from './DoctorRow';

interface DoctorDetailsProps {
  doctor: Doctor;
  setNextStep: (id: any) => void;
  setPreviousStep: (specialtyId: string) => void;
  profile: { [index: string]: any } | null;
  handleSetFavouriteDoctor: (doctorId: string, makeFavourite: boolean) => void;
}

const DoctorDetails = ({
  doctor,
  setNextStep,
  setPreviousStep,
  profile,
  handleSetFavouriteDoctor,
}: DoctorDetailsProps) => {
  const { t } = useTranslation();
  const lang = i18next.language as Language;
  const direction = lang === 'ar' ? 'rtl' : 'ltr';
  const role = useContextRole();

  const [copiedToClipboard, setCopiedToClipboard] = useState(false);
  const [paymentConfig, setPaymentConfig] = useState<{
    egDrCut: number;
    surCharge: number;
  }>();

  const doctorLink = GetLink('doctor', doctor.id);
  const favouriteDoctorsIds = get(profile, 'favouriteDoctorsIds', []);

  const { result: specialty, error, loading } = useDocument<Specialty>(
    {
      path: `specialties/${doctor.specialtyId}`,
    },
    { skip: !doctor.specialtyId },
  );

  let translatedSpecialty;

  if (!(error || loading)) {
    translatedSpecialty = specialty
      ? getTranslatedSpeciality(specialty)
      : specialty;
  }
  getPaymentConfig().then(value => {
    if (!paymentConfig) {
      value.surCharge *= 100;
      setPaymentConfig(value);
    }
  });
  // useEffect(() => {
  //   const metas: any = document.getElementsByTagName('META');
  //   metas['description'].content = `${t('common.desc_lin1')}\n${getDoctorName(
  //     doctor,
  //     'en',
  //   )}\n${doctor?.medTitle}\n${t('common.desc_lin2')}:${
  //     doctor.price && formatPrice(doctor.price)
  //   }\n${t('common.desc_lin3')}:${doctor.rating}\n${
  //     doctor.about?.[lang] || doctor.about?.en
  //   }`;
  //   const meta: any = document.createElement('meta');
  //   meta.property = 'og:image';
  //   meta.content = doctor.photoURL;
  //   document.getElementsByTagName('head')[0].appendChild(meta);
  // }, [doctor, t, lang]);
  return (
    <Box margin={1}>
      <h1>{t('booking.doctorsDetails')}</h1>
      <Box overflow="auto">
        <Table>
          <TableBody>
            <DoctorRow
              doctor={doctor}
              favouriteDoctorsIds={favouriteDoctorsIds}
              handleSetFavouriteDoctor={handleSetFavouriteDoctor}
            />
          </TableBody>
        </Table>
      </Box>
      <h2>{t('booking.moreInfo')}</h2>
      <Table size="medium" aria-label="doctor-details">
        <TableBody>
          <TableRow key={'specialtyId'}>
            <TableCell>{t('profile.specialtyId')}</TableCell>
            <TableCell>
              {translatedSpecialty && translatedSpecialty.name}
            </TableCell>
          </TableRow>
          <TableRow key={'about'}>
            <TableCell>{t('profile.about')}</TableCell>
            <TableCell>{doctor.about?.[lang] || doctor.about?.en}</TableCell>
          </TableRow>
          <TableRow key={'yearsOfExperience'}>
            <TableCell>{t('profile.yearsOfExperience')}</TableCell>
            <TableCell>
              {doctor.yearsOfExperience && doctor.yearsOfExperience}
            </TableCell>
          </TableRow>
          <TableRow key={'price'}>
            <TableCell>{t('booking.priceForVisit')}</TableCell>
            <TableCell>
              {doctor.price &&
                formatPrice(doctor.price, paymentConfig?.surCharge)}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <Grid justify="space-between" container>
        <Grid item>
          {role !== UserRoleTypes.DOCTOR && (
            <Button
              variant="contained"
              style={{ marginTop: '1em' }}
              onClick={() => setPreviousStep(doctor.specialtyId)}
              startIcon={
                direction === 'ltr' ? <ArrowBackIos /> : <ArrowForwardIos />
              }
            >
              {t('booking.changeDoctor')}
            </Button>
          )}
        </Grid>

        <Grid item>
          <CopyToClipboard text={doctorLink}>
            <Button
              variant="contained"
              style={{ marginTop: '1em' }}
              onClick={() => {
                setCopiedToClipboard(true);
              }}
              startIcon={<Share />}
            >
              {copiedToClipboard
                ? t('booking.copiedToClipboard')
                : t('booking.shareLink')}
            </Button>
          </CopyToClipboard>
        </Grid>
        <Grid item>
          {role !== UserRoleTypes.DOCTOR && (
            <Button
              variant="contained"
              color="primary"
              style={{ marginTop: '1em' }}
              onClick={() => setNextStep({ doctorId: doctor.id })}
              startIcon={<EventNote />}
            >
              {t('booking.bookAVisit')}
            </Button>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default withErrorBoundary(DoctorDetails, {
  fallback: <Alert severity="error">Unable to load doctor details.</Alert>,
});
