import {
  Backdrop,
  CircularProgress,
  Table,
  TableBody,
  TableFooter,
  TableRow,
  TableContainer,
  TablePagination,
  Paper,
} from '@material-ui/core';
import get from 'lodash/get';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Query, Doctor } from '../../api/types';
import { useUser } from '../../api/usages';
import { useVerifiedDoctors } from '../../api/usages/doctors';
import DoctorRow from './DoctorRow';

interface DoctorsTableProps {
  onChooseDoctor: (doctor: Doctor) => void;
  profile?: { [index: string]: any } | null;
  handleSetFavouriteDoctor?: (doctorId: string, makeFavourite: boolean) => void;
  query?: Query;
  filteringName?: string;
  setDisableSearchBar?: React.Dispatch<React.SetStateAction<boolean>>;
}

const DoctorsTable = ({
  onChooseDoctor,
  profile,
  handleSetFavouriteDoctor,
  query,
  filteringName,
  setDisableSearchBar,
}: DoctorsTableProps) => {
  const { t } = useTranslation();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const favouriteDoctorsIds = get(profile, 'favouriteDoctorsIds', []);
  const currentUser = useUser();
  const phoneNumber = currentUser?.phoneNumber;
  const { error, loading, doctors } = useVerifiedDoctors(query);
  // A doctor with the same email address as a patient shouldn't be available
  const doctorsToBook = doctors.filter(
    doctor =>
      doctor?.video?.active === true && doctor?.phoneNumber !== phoneNumber,
  );
  const handleChangePage = (_event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  if (error) {
    return null;
  }

  if (loading) {
    return (
      <Backdrop open style={{ zIndex: 10 }}>
        <CircularProgress color="primary" />
      </Backdrop>
    );
  }
  const filteredDoctors = filteringName
    ? doctorsToBook.filter(
        (doctor: any) =>
          doctor.name?.en
            ?.toLowerCase()
            .includes(filteringName.toLowerCase()) ||
          doctor.name?.ar
            ?.toLowerCase()
            .includes(filteringName.toLowerCase()) ||
          doctor.about?.en
            ?.toLowerCase()
            .includes(filteringName.toLowerCase()) ||
          doctor.about?.ar?.toLowerCase().includes(filteringName.toLowerCase()),
      )
    : doctorsToBook;
  return filteredDoctors.length ? (
    <>
      <h1>{t('booking.chooseDoctor')}</h1>
      <TableContainer component={Paper}>
        <Table>
          <TableBody>
            {(rowsPerPage > 0
              ? filteredDoctors.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage,
                )
              : filteredDoctors
            ).map((doctor: Doctor) => (
              <DoctorRow
                key={doctor.id}
                doctor={doctor}
                onChooseDoctor={onChooseDoctor}
                favouriteDoctorsIds={favouriteDoctorsIds}
                handleSetFavouriteDoctor={handleSetFavouriteDoctor}
              />
            ))}
          </TableBody>

          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[10, 25, 100, { label: 'All', value: -1 }]}
                colSpan={3}
                count={filteredDoctors.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                labelRowsPerPage={t('booking.rowsPerPage')}
                SelectProps={{
                  inputProps: { 'aria-label': 'rows per page' },
                  native: true,
                }}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </>
  ) : (
    <>
      <h1>{t('booking.errorNoDoctorsWithThisFeatures')}</h1>
    </>
  );
};

export default DoctorsTable;
