import RouteTypes from '../constants/routes';
import { isMobile } from '../lib/utils';

const GetLink = (role: string, id: string) => {
  const clinicLinkFragment = '/' + role + '/';
  const baseLink = isMobile
    ? 'https://shoofdoctor.com'
    : window.location.href
        .split(RouteTypes.CLINIC)[0]
        .split(RouteTypes.FAVOURITES)[0]
        .split(RouteTypes.SPECIALISTS)[0]
        .split(RouteTypes.PRIMARY_DOCTORS)[0]
        .split(clinicLinkFragment)[0];
  const doctorLink = baseLink + clinicLinkFragment + id;
  return doctorLink;
};

export default GetLink;
