import moment from 'moment';
import { collectionData } from 'rxfire/firestore';
import { Observable, from, NEVER, combineLatest } from 'rxjs';
import {
  delay,
  skipWhile,
  startWith,
  switchMap,
  switchMapTo,
  take,
  distinct,
  distinctUntilChanged,
  share,
} from 'rxjs/operators';

import { UserRoleTypes } from '../../api/types';
import { contextLanguage$, contextRole$ } from '../sessionData';
import { initializingAuth$ } from './auth';
import { db } from './firestore';

type Notification = {
  title?: string;
  body: string;
  ts: number;
  topic: string;
  id?: string;
};

const getNotificationColRef = (
  lang: string,
  topic: UserRoleTypes | 'ALL' = 'ALL',
) =>
  db
    .collection(`notifications/${topic.toUpperCase()}/${lang}`)
    .where('ts', '>', moment.now());

const queueingNotifications = (delayTs: number) =>
  switchMap((notifications: Notification[]) => {
    if (!notifications || notifications.length === 0) {
      return NEVER as Observable<Notification>;
    }
    if (notifications.length === 1) {
      return from(notifications);
    }
    const [first, ...items] = notifications;
    return from(items).pipe(delay(delayTs), startWith(first));
  });

export const forAllNotifications$: Observable<Notification> = initializingAuth$.pipe(
  skipWhile((initialization: boolean) => initialization),
  take(1),
  switchMapTo(contextLanguage$),
  distinctUntilChanged(),
  // @ts-ignore
  switchMap(lang => collectionData(getNotificationColRef(lang || 'ar'), 'id')),
  queueingNotifications(1500),
  distinct(n => n.id),
  share(),
);

export const forTypeNotifications$: Observable<Notification> = combineLatest(
  contextRole$.pipe(
    skipWhile((contextRole: UserRoleTypes | undefined) => !contextRole),
  ),
  contextLanguage$,
).pipe(
  distinctUntilChanged((arr1, arr2) => {
    return arr1[0] === arr2[0] && arr1[1] === arr2[1];
  }),
  // @ts-ignore
  switchMap(([contextRole, lang]) =>
    collectionData(getNotificationColRef(lang || 'ar', contextRole), 'id'),
  ),
  queueingNotifications(1500),
  distinct(n => n.id),
  share(),
);
