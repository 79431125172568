import { Plugins } from '@capacitor/core';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
const { App: CapApp } = Plugins;

const AppUrlListener: React.FC<any> = () => {
  const history = useHistory();
  useEffect(() => {
    CapApp.addListener(
      'appUrlOpen',
      (data: any) => {
        const slug = data.url.split('.app').pop();
        if (slug) {
          history.push(slug);
        }
        // If no match, do nothing - let regular routing
        // logic take over
      },
      // @ts-ignore
      [],
    );
  });

  return null;
};

export default AppUrlListener;
