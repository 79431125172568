import { Box, Button, InputBase } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Search } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useSpecialties } from '../../api/usages';
// import { getHomeReferrals } from '../lib/admin';
import { db, storage } from '../../lib/firebase';
import { getTranslatedSpeciality } from '../Specialists';
import LeadItem from './LeadItem';

const filterItems = (Query: string, array: any[]) => {
  const filteredArray = array.filter(
    (item: any) =>
      item.name.toLowerCase().includes(Query.toLowerCase()) ||
      item.mobile.toLowerCase().includes(Query.toLowerCase()),
  );

  return filteredArray;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      width: '100%',
      position: 'sticky',
      top: '64px',
      [theme.breakpoints.down('sm')]: {
        top: '56px',
      },
      display: 'flex',
      alignItems: 'center',
      gap: '10px',
      padding: '10px',
      backgroundColor: 'white',
      zIndex: 1,
      height: '56px',
    },
  }),
);

const HHLeads = () => {
  const { t, i18n } = useTranslation();
  const [leads, setLeads] = useState<any>([]);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [displayedItems, setDisplayedItems] = useState<any>([]);
  const cx = useStyles();
  const { specialties } = useSpecialties();
  const translatedSpecialties = specialties.map(specialty =>
    getTranslatedSpeciality(specialty),
  );
  useEffect(() => {
    async function getLeads() {
      const patientsRef = db
        .collection('referralsHome')
        .orderBy('createdAt', 'desc');
      const snapshot = await patientsRef.get();
      const fetchedData = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));
      setLeads(fetchedData);
    }
    getLeads();
  }, []);
  useEffect(() => {
    if (searchQuery.trim() === '') {
      setDisplayedItems(leads);
    } else if (searchQuery && searchQuery.length > 1) {
      const filteredItems = filterItems(searchQuery, leads);
      setDisplayedItems(filteredItems);
    }
  }, [searchQuery, leads]);
  const handleDownload = async () => {
    const url = await storage.ref('dump/referralsHome.zip').getDownloadURL();
    window.open(url, '_blank');
  };
  // console.log(leads);
  return (
    <>
      <Box className={cx.container} boxShadow={5}>
        <img src="/leads.svg" />
        <Search color="primary" />
        <InputBase
          style={{ flex: '1' }}
          //{t('admin.adminPanel', { name: profile?.name })}
          placeholder={t('admin.referralsHome.searchplaceholder', {
            leadsLength: leads.length.toLocaleString(
              i18n.language === 'en' ? 'en' : 'ar-EG',
            ),
          })}
          onChange={event => {
            setSearchQuery(event.target.value);
          }}
          value={searchQuery}
        />
        <Button
          variant="contained"
          color="primary"
          style={{ backgroundColor: '#190099' }}
          onClick={() => handleDownload()}
        >
          {t('admin.referralsHome.download')}
        </Button>
      </Box>

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '10px',
        }}
      >
        {displayedItems ? (
          displayedItems.map((item: any) => {
            const speciality = translatedSpecialties.filter(
              el => el.id === item.specialtyId,
            );
            item = { ...item, speciality };
            return <LeadItem key={item.id} lead={item} />;
          })
        ) : (
          <div>Loading...</div>
        )}
      </div>
    </>
  );
};

export default HHLeads;
