import { Grid, makeStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import WalletIcon from '@material-ui/icons/AccountBalanceWallet';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import MobileWalletIcon from '@material-ui/icons/ScreenLockPortrait';
import KioskIcon from '@material-ui/icons/Storefront';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Currency,
  PaymentMethod,
  PaymentWallet,
  Price,
} from '../../api/types/Payments';
import { formatPrice, getWalletAmount } from '../../lib/utils';
import BillingForm from '../Payments/BillingForm';
import PaymentBox from './PaymentBox';

type Props = {
  allowedMethods: PaymentMethod[];
  disabled?: boolean;
  onPay: (
    method: PaymentMethod,
    billingInfo?: PaymentWallet['billingInfo'],
  ) => void;
  currency: Currency;
  wallet?: PaymentWallet | null;
  isTesting?: boolean;
  price: { amount: number | null; currency: Price['currency'] };
  withConfirm?: boolean;
};

const useStyles = makeStyles({
  card: {
    minHeight: 200,
  },
});

const PaymentMethods = ({
  allowedMethods,
  disabled,
  onPay,
  currency,
  wallet = null,
  isTesting = false,
  price,
  withConfirm = false,
}: Props) => {
  const { t } = useTranslation();
  const cx = useStyles();
  const [method, setMethod] = useState<PaymentMethod | null>(null);
  const [triedToPay, setTriedToPay] = useState(false);

  if (method) {
    return (
      <BillingForm
        wallet={wallet}
        onSubmit={billingInfo => onPay(method, billingInfo)}
      />
    );
  }

  const walletAmount = getWalletAmount(wallet, currency);

  return (
    <Grid
      container
      spacing={4}
      justify="center"
      direction="row"
      alignItems="center"
    >
      {allowedMethods.includes(PaymentMethod.FREE) && (
        <PaymentBox
          onClick={() => {
            setTriedToPay(true);
            onPay(PaymentMethod.FREE);
          }}
          className={cx.card}
          disabled={disabled || triedToPay}
          price={price}
          method={PaymentMethod.FREE}
        >
          <Typography align="center">
            <WalletIcon fontSize="large" color="primary" />
          </Typography>
          <Typography variant="h5" align="center">
            {t(isTesting ? 'payments.freeVisitTesting' : 'payments.freeVisit')}
          </Typography>
        </PaymentBox>
      )}

      {allowedMethods.includes(PaymentMethod.WALLET) && walletAmount > 0 && (
        <PaymentBox
          onClick={() => {
            setTriedToPay(true);
            onPay(PaymentMethod.WALLET);
          }}
          className={cx.card}
          disabled={disabled || triedToPay}
          price={price}
          method={PaymentMethod.WALLET}
          withConfirm={withConfirm}
        >
          <Typography align="center">
            <WalletIcon fontSize="large" color="primary" />
          </Typography>
          <Typography variant="h5" align="center">
            {t('payments.payWithWallet')}
          </Typography>
          <Typography align="center">
            ({formatPrice({ amount: walletAmount, currency })})
          </Typography>
        </PaymentBox>
      )}

      {allowedMethods.includes(PaymentMethod.PAYMOB_CARD) && (
        <PaymentBox
          disabled={disabled}
          onClick={() => {
            setMethod(PaymentMethod.PAYMOB_CARD);
          }}
          className={cx.card}
          price={price}
          method={PaymentMethod.PAYMOB_CARD}
          withConfirm={withConfirm}
        >
          <Typography align="center">
            <CreditCardIcon fontSize="large" color="primary" />
          </Typography>
          <Typography variant="h5" align="center">
            {t('payments.payWithCard')}
          </Typography>
        </PaymentBox>
      )}

      {allowedMethods.includes(PaymentMethod.PAYMOB_WALLET) && (
        <PaymentBox
          disabled={disabled}
          onClick={() => {
            setMethod(PaymentMethod.PAYMOB_WALLET);
          }}
          className={cx.card}
          price={price}
          method={PaymentMethod.PAYMOB_WALLET}
          withConfirm={withConfirm}
        >
          <Typography align="center">
            <MobileWalletIcon fontSize="large" color="primary" />
          </Typography>
          <Typography variant="h5" align="center">
            {t('payments.payWithVodafoneCash')}
          </Typography>
        </PaymentBox>
      )}

      {allowedMethods.includes(PaymentMethod.PAYMOB_KIOSK) && (
        <PaymentBox
          disabled={disabled}
          onClick={() => {
            setMethod(PaymentMethod.PAYMOB_KIOSK);
          }}
          className={cx.card}
          price={price}
          method={PaymentMethod.PAYMOB_KIOSK}
          withConfirm={withConfirm}
        >
          <Typography align="center">
            <KioskIcon fontSize="large" color="primary" />
          </Typography>
          <Typography variant="h5" align="center">
            {t('payments.payWithKiosk')}
          </Typography>
        </PaymentBox>
      )}
    </Grid>
  );
};

export default PaymentMethods;
