import React, { lazy, useEffect } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import { UserRoleTypes } from '../api/types';
import { useContextRole, useLanguage, useProfile } from '../api/usages';
import { useRuntime } from '../api/usages/uiControls';
import CustomRoute from '../components/CustomRoute';
import CustomRouteDoctor from '../components/CustomRouteDoctor';
import ROUTES from '../constants/routes';
import { init } from '../lib/firebase/analytics';
import Dialogs from './Dialogs';
import HHFormLeads from './HHFormLeads';

// import AdminDataDumps from './AdminDataDumps';
// import AdminEditDoctor from './AdminEditDoctor';
// import AdminEditLab from './AdminEditLab';
// import AdminEditPatient from './AdminEditPatient';
// import AdminEditRadio from './AdminEditRadio';
// import AdminHome from './AdminHome';
// import AdminManageAdmins from './AdminManageAdmins';
// import AdminManageDoctors from './AdminManageDoctors';
// import AdminManageLabs from './AdminManageLabs';
// import AdminManagePatients from './AdminManagePatients';
// import AdminManageRadios from './AdminManageRadios';
// import AdminNotifications from './AdminNotifications';
// import AdminSignIn from './AdminSignIn';
// import BookingSuccess from './BookingSuccess';
// import Bookings from './Bookings';
// import CallReview from './CallReview';
// import CallSummary from './CallSummary';
// import ChangeEmail from './ChangeEmail';
// import ChangePass from './ChangePass';
// import ChangePhone from './ChangePhone';
// import ComingSoon from './ComingSoon';
// import CreateEmailProfile from './CreateEmailProfile';
// import CreateProfile from './CreateProfile';
// import CreateProfileDoctor from './CreateProfileDoctor';
// import CreateProfileLabRadio from './CreateProfileLabRadio';
// import DoctorDetailsFromLink from './DoctorDetailsFromLink';
// import EditDoctorClinic from './EditDoctorClinic';
// import EditDoctorHome from './EditDoctorHome';
// import EditDoctorVideo from './EditDoctorVideo/index';
// import Favourites from './Favourites';
// import FilteredDoctors from './FilteredDoctors';
// import FollowUpFromLink from './FollowUpFromLink';
// import ForDoctor from './ForDoctor';
// import ForLab from './ForLab';
// import ForRadio from './ForRadio';
// import ForgotPass from './ForgotPass';
// import HealthRecords from './HealthRecords';
// import HealthRecordsPrescription from './HealthRecordsPrescription';
// import HealthRecordsVisit from './HealthRecordsVisit';
// import Help from './Help';
// import Home from './Home';
// import LabDetails from './LabRadio/LabDetails';
// import Labs from './LabRadio/Labs';
// import Radios from './LabRadio/Radio';
// import RadioDetails from './LabRadio/RadioDetails';
// import Orders from './Orders';
// import PaymentCashIn from './PaymentCashIn';
// import PaymentPay from './PaymentPay';
// import PaymentWallet from './PaymentWallet';
// import Prescriptions from './Prescriptions';
// import PrimaryDoctors from './PrimaryDoctors';
// import Profile from './Profile';
// import ProfileDoctor from './ProfileDoctor';
// import ProfileLabRadio from './ProfileLabRadio';
// import RadiosLabs from './RadiosLabs';
// import Schedule from './Schedule';
// import SessionSummary from './SessionSummary';
// import Settings from './Settings';
// import SignIn from './SignIn';
// import SignUp from './SignUp';
// import SignUpDoctor from './SignUpDoctor';
// import SignUpLab from './SignUpLab';
// import SignUpRadio from './SignUpRadio';
// import Specialists from './Specialists';
// import VideoCall from './VideoCall';
const AdminDataDumps = lazy(() => import('./AdminDataDumps'));
const AdminEditDoctor = lazy(() => import('./AdminEditDoctor'));
const AdminEditLab = lazy(() => import('./AdminEditLab'));
const AdminEditPatient = lazy(() => import('./AdminEditPatient'));
const AdminEditRadio = lazy(() => import('./AdminEditRadio'));
const AdminHome = lazy(() => import('./AdminHome'));
const AdminManageAdmins = lazy(() => import('./AdminManageAdmins'));
const AdminManageDoctors = lazy(() => import('./AdminManageDoctors/index'));
const AdminManageLabs = lazy(() => import('./AdminManageLabs'));
const AdminManagePatients = lazy(() => import('./AdminManagePatients'));
const AdminManageRadios = lazy(() => import('./AdminManageRadios'));
const AdminNotifications = lazy(() => import('./AdminNotifications'));
const AdminSignIn = lazy(() => import('./AdminSignIn'));
const BookingSuccess = lazy(() => import('./BookingSuccess'));
const Bookings = lazy(() => import('./Bookings'));
const CallReview = lazy(() => import('./CallReview'));
const CallSummary = lazy(() => import('./CallSummary'));
const ChangeEmail = lazy(() => import('./ChangeEmail'));
const ChangePass = lazy(() => import('./ChangePass'));
const ChangePhone = lazy(() => import('./ChangePhone'));
const ComingSoon = lazy(() => import('./ComingSoon'));
const CreateEmailProfile = lazy(() => import('./CreateEmailProfile'));
const CreateProfile = lazy(() => import('./CreateProfile'));
const CreateProfileDoctor = lazy(() => import('./CreateProfileDoctor'));
const CreateProfileLabRadio = lazy(() => import('./CreateProfileLabRadio'));
const DoctorDetailsFromLink = lazy(() => import('./DoctorDetailsFromLink'));
const EditDoctorClinic = lazy(() => import('./EditDoctorClinic'));
const EditDoctorHome = lazy(() => import('./EditDoctorHome'));
const EditDoctorVideo = lazy(() => import('./EditDoctorVideo/index'));
const Favourites = lazy(() => import('./Favourites'));
const FilteredDoctors = lazy(() => import('./FilteredDoctors'));
const FollowUpFromLink = lazy(() => import('./FollowUpFromLink'));
const ForDoctor = lazy(() => import('./ForDoctor'));
const ForLab = lazy(() => import('./ForLab'));
const ForRadio = lazy(() => import('./ForRadio'));
const ForgotPass = lazy(() => import('./ForgotPass'));
const HealthRecords = lazy(() => import('./HealthRecords'));
const HealthRecordsPrescription = lazy(() =>
  import('./HealthRecordsPrescription'),
);
const HealthRecordsVisit = lazy(() => import('./HealthRecordsVisit'));
const Help = lazy(() => import('./Help'));
const Home = lazy(() => import('./Home'));
const LabDetails = lazy(() => import('./LabRadio/LabDetails'));
const Labs = lazy(() => import('./LabRadio/Labs'));
const Radios = lazy(() => import('./LabRadio/Radio'));
const RadioDetails = lazy(() => import('./LabRadio/RadioDetails'));
const Orders = lazy(() => import('./Orders'));
const PaymentCashIn = lazy(() => import('./PaymentCashIn'));
const PaymentPay = lazy(() => import('./PaymentPay'));
const PaymentWallet = lazy(() => import('./PaymentWallet'));
const Prescriptions = lazy(() => import('./Prescriptions'));
const PrimaryDoctors = lazy(() => import('./PrimaryDoctors'));
const Profile = lazy(() => import('./Profile'));
const ProfileDoctor = lazy(() => import('./ProfileDoctor'));
const ProfileLabRadio = lazy(() => import('./ProfileLabRadio'));
const RadiosLabs = lazy(() => import('./RadiosLabs'));
const Schedule = lazy(() => import('./Schedule'));
const SessionSummary = lazy(() => import('./SessionSummary'));
const Settings = lazy(() => import('./Settings'));
const SignIn = lazy(() => import('./SignIn'));
const SignUp = lazy(() => import('./SignUp'));
const SignUpDoctor = lazy(() => import('./SignUpDoctor'));
const SignUpLab = lazy(() => import('./SignUpLab'));
const SignUpRadio = lazy(() => import('./SignUpRadio'));
const Specialists = lazy(() => import('./Specialists'));
const VideoCall = lazy(() => import('./VideoCall'));
const Clinic = lazy(() => import('./Clinic'));
const ClinicDetailsFromLink = lazy(() => import('./ClinicDetailsFromLink'));

// const AdminDataDumps = lazy(() => import('./AdminDataDumps'));
// const AdminEditDoctor = lazy(() => import('./AdminEditDoctor'));
// const AdminEditLab = lazy(() => import('./AdminEditLab'));
// const AdminEditPatient = lazy(() => import('./AdminEditPatient'));
// const AdminEditRadio = lazy(() => import('./AdminEditRadio'));
// const AdminHome = lazy(() => import('./AdminHome'));
// const AdminManageAdmins = lazy(() => import('./AdminManageAdmins'));
// const AdminManageDoctors = lazy(() => import('./AdminManageDoctors'));
// const AdminManageLabs = lazy(() => import('./AdminManageLabs'));
// const AdminManagePatients = lazy(() => import('./AdminManagePatients'));
// const AdminManageRadios = lazy(() => import('./AdminManageRadios'));
// const AdminNotifications = lazy(() => import('./AdminNotifications'));
// const AdminSignIn = lazy(() => import('./AdminSignIn'));
// const BookingSuccess = lazy(() => import('./BookingSuccess'));
// const Bookings = lazy(() => import('./Bookings'));
// const CallReview = lazy(() => import('./CallReview'));
// const CallSummary = lazy(() => import('./CallSummary'));
// const ChangeEmail = lazy(() => import('./ChangeEmail'));
// const ChangePass = lazy(() => import('./ChangePass'));
// const ChangePhone = lazy(() => import('./ChangePhone'));
// const ComingSoon = lazy(() => import('./ComingSoon'));
// const CreateEmailProfile = lazy(() => import('./CreateEmailProfile'));
// const CreateProfile = lazy(() => import('./CreateProfile'));
// const CreateProfileDoctor = lazy(() => import('./CreateProfileDoctor'));
// const CreateProfileLabRadio = lazy(() => import('./CreateProfileLabRadio'));
// const DoctorDetailsFromLink = lazy(() => import('./DoctorDetailsFromLink'));
// const Favourites = lazy(() => import('./Favourites'));
// const FilteredDoctors = lazy(() => import('./FilteredDoctors'));
// const FollowUpFromLink = lazy(() => import('./FollowUpFromLink'));
// const ForDoctor = lazy(() => import('./ForDoctor'));
// const ForLab = lazy(() => import('./ForLab'));
// const ForRadio = lazy(() => import('./ForRadio'));
// const ForgotPass = lazy(() => import('./ForgotPass'));
// const HealthRecords = lazy(() => import('./HealthRecords'));
// const HealthRecordsPrescription = lazy(() =>
//   import('./HealthRecordsPrescription'),
// );
// const HealthRecordsVisit = lazy(() => import('./HealthRecordsVisit'));
// const Help = lazy(() => import('./Help'));
// const Home = lazy(() => import('./Home'));
// const LabDetails = lazy(() => import('./LabRadio/LabDetails'));
// const Labs = lazy(() => import('./LabRadio/Labs'));
// const Radios = lazy(() => import('./LabRadio/Radio'));
// const RadioDetails = lazy(() => import('./LabRadio/RadioDetails'));
// const Orders = lazy(() => import('./Orders'));
// const PaymentCashIn = lazy(() => import('./PaymentCashIn'));
// const PaymentPay = lazy(() => import('./PaymentPay'));
// const PaymentWallet = lazy(() => import('./PaymentWallet'));
// const Prescriptions = lazy(() => import('./Prescriptions'));
// const PrimaryDoctors = lazy(() => import('./PrimaryDoctors'));
// const Profile = lazy(() => import('./Profile'));
// const ProfileDoctor = lazy(() => import('./ProfileDoctor'));
// const ProfileLabRadio = lazy(() => import('./ProfileLabRadio'));
// const RadiosLabs = lazy(() => import('./RadiosLabs'));
// const Schedule = lazy(() => import('./Schedule'));
// const SessionSummary = lazy(() => import('./SessionSummary'));
// const Settings = lazy(() => import('./Settings'));
// const SignIn = lazy(() => import('./SignIn'));
// const SignUp = lazy(() => import('./SignUp'));
// const SignUpDoctor = lazy(() => import('./SignUpDoctor'));
// const SignUpLab = lazy(() => import('./SignUpLab'));
// const SignUpRadio = lazy(() => import('./SignUpRadio'));
// const Specialists = lazy(() => import('./Specialists'));
// const VideoCall = lazy(() => import('./VideoCall'));

if (
  window.location.host === 'stg.shoofdoctor.com' ||
  window.location.host === 'www.shoofdoctor.com'
) {
  window.location.href = 'https://shoofdoctor.com';
}

const HomeRedirect = () => (
  <Route path="*" component={() => <Redirect to={ROUTES.HOME} />} />
);

// eslint-disable-next-line complexity
export default function Screens() {
  const role = useContextRole() ?? 'no-user';
  const profile = useProfile() || {};
  const language = useLanguage();
  const { isMobileApp } = useRuntime();

  useEffect(init, []);

  if (!language) {
    return null;
  }

  switch (role) {
    case UserRoleTypes.DOCTOR:
      return (
        <Switch>
          {/* <CustomRoute
            path={ROUTES.SCHEDULE}
            component={Schedule}
            profile={profile}
          /> */}
          <CustomRouteDoctor
            path={ROUTES.PROFILE_EDIT_VIDEO}
            component={EditDoctorVideo}
            profile={profile}
          />
          <CustomRouteDoctor
            path={ROUTES.PROFILE_EDIT_HOME}
            component={EditDoctorHome}
            profile={profile}
          />
          <CustomRouteDoctor
            path={ROUTES.PROFILE_EDIT_CLINIC}
            component={EditDoctorClinic}
            profile={profile}
          />
          <CustomRouteDoctor
            path={ROUTES.PROFILE}
            component={ProfileDoctor}
            profile={profile}
          />
          <CustomRouteDoctor
            path={ROUTES.SETTINGS}
            component={Settings}
            profile={profile}
          />
          <CustomRouteDoctor
            path={ROUTES.DOCTOR}
            component={DoctorDetailsFromLink}
            profile={profile}
          />
          <Route path={ROUTES.HELP} component={Help} profile={profile} />
          <CustomRouteDoctor
            path={ROUTES.VIDEO_CALL}
            component={VideoCall}
            profile={profile}
          />
          <CustomRouteDoctor
            path={ROUTES.HOME}
            component={Bookings}
            profile={profile}
          />
          <Route path={ROUTES.SESSION_SUMMARY} component={SessionSummary} />
          <Route path={ROUTES.PROFILE_CREATE} component={CreateProfileDoctor} />
          <Route
            path={ROUTES.PROFILE_CREATE_EMAIL}
            component={CreateEmailProfile}
          />
          <CustomRouteDoctor
            path={ROUTES.HEALTH_RECORDS_VISIT}
            component={HealthRecordsVisit}
            profile={profile}
          />
          <CustomRouteDoctor
            path={ROUTES.HEALTH_RECORDS_PRESCRIPTION}
            component={HealthRecordsPrescription}
            profile={profile}
          />
          <Route path={ROUTES.FORGOT_PASSWORD} component={ForgotPass} />
          <Route path={ROUTES.CHANGE_PASSWORD} component={ChangePass} />
          <Route path={ROUTES.CHANGE_EMAIL} component={ChangeEmail} />
          <Route path={ROUTES.CHANGE_PHONE} component={ChangePhone} />
          <CustomRouteDoctor
            path={ROUTES.PAYMENT_WALLET}
            component={PaymentWallet}
            profile={profile}
          />
          <Route path={ROUTES.PAYMENT_PAY} component={PaymentPay} />
          <CustomRouteDoctor
            profile={profile}
            path={ROUTES.INDEX}
            component={Bookings}
            exact
          />
          <Route path="*" component={HomeRedirect} />
        </Switch>
      );
    case UserRoleTypes.LAB:
      return (
        <Switch>
          <CustomRoute
            path={ROUTES.PROFILE}
            component={ProfileLabRadio}
            profile={profile}
          />
          <CustomRoute
            path={ROUTES.SETTINGS}
            component={Settings}
            profile={profile}
          />
          <CustomRoute path={ROUTES.HELP} component={Help} profile={profile} />
          <CustomRoute
            path={ROUTES.HOME}
            component={Orders}
            profile={profile}
          />
          <Route
            path={ROUTES.PROFILE_CREATE}
            component={CreateProfileLabRadio}
          />
          <Route
            path={ROUTES.PROFILE_CREATE_EMAIL}
            component={CreateEmailProfile}
          />
          <Route path={ROUTES.FORGOT_PASSWORD} component={ForgotPass} />
          <Route path={ROUTES.CHANGE_PASSWORD} component={ChangePass} />
          <Route path={ROUTES.CHANGE_EMAIL} component={ChangeEmail} />
          <Route path={ROUTES.CHANGE_PHONE} component={ChangePhone} />
          <Route path={ROUTES.INDEX} component={Orders} exact />
          <Route path="*" component={HomeRedirect} />
        </Switch>
      );
    case UserRoleTypes.RADIO:
      return (
        <Switch>
          <CustomRoute
            path={ROUTES.PROFILE}
            component={ProfileLabRadio}
            profile={profile}
          />
          <CustomRoute
            path={ROUTES.SETTINGS}
            component={Settings}
            profile={profile}
          />
          <CustomRoute path={ROUTES.HELP} component={Help} profile={profile} />
          <CustomRoute
            path={ROUTES.HOME}
            component={Orders}
            profile={profile}
          />
          <Route
            path={ROUTES.PROFILE_CREATE}
            component={CreateProfileLabRadio}
          />
          <Route
            path={ROUTES.PROFILE_CREATE_EMAIL}
            component={CreateEmailProfile}
          />
          <Route path={ROUTES.FORGOT_PASSWORD} component={ForgotPass} />
          <Route path={ROUTES.CHANGE_PASSWORD} component={ChangePass} />
          <Route path={ROUTES.CHANGE_EMAIL} component={ChangeEmail} />
          <Route path={ROUTES.CHANGE_PHONE} component={ChangePhone} />
          <Route path={ROUTES.INDEX} component={Orders} exact />
          <Route path="*" component={HomeRedirect} />
        </Switch>
      );
    case UserRoleTypes.PATIENT:
      return (
        <Switch>
          <CustomRoute
            path={ROUTES.BOOKINGS}
            component={Bookings}
            profile={profile}
          />
          <CustomRoute
            path={ROUTES.DOCTORS}
            component={FilteredDoctors}
            profile={profile}
          />
          <CustomRoute
            path={ROUTES.PROFILE}
            component={Profile}
            profile={profile}
          />
          <CustomRoute
            path={ROUTES.PRESCRIPTIONS}
            component={Prescriptions}
            profile={profile}
          />
          <CustomRoute
            path={ROUTES.HEALTH_RECORDS}
            component={HealthRecords}
            profile={profile}
          />
          <CustomRoute
            path={ROUTES.HEALTH_RECORDS_VISIT}
            component={HealthRecordsVisit}
            profile={profile}
          />
          <CustomRoute
            path={ROUTES.HEALTH_RECORDS_PRESCRIPTION}
            component={HealthRecordsPrescription}
            profile={profile}
          />
          <CustomRoute
            path={ROUTES.FAVOURITES}
            component={Favourites}
            profile={profile}
          />
          <CustomRoute path={ROUTES.LABS} component={Labs} profile={profile} />
          <CustomRoute
            path={ROUTES.LAB_DETAILS}
            component={LabDetails}
            profile={profile}
          />
          <CustomRoute
            path={ROUTES.RADIOS}
            component={Radios}
            profile={profile}
          />
          <CustomRoute
            path={ROUTES.RADIO_DETAILS}
            component={RadioDetails}
            profile={profile}
          />
          <CustomRoute
            path={ROUTES.SETTINGS}
            component={Settings}
            profile={profile}
          />
          <CustomRoute
            path={ROUTES.DOCTOR}
            component={DoctorDetailsFromLink}
            profile={profile}
          />

          <CustomRoute
            path={ROUTES.DOCTOR_CLINIC}
            component={ClinicDetailsFromLink}
            profile={profile}
          />
          <CustomRoute
            path={ROUTES.FOLLOW_UP}
            component={FollowUpFromLink}
            profile={profile}
          />

          <CustomRoute path={ROUTES.HELP} component={Help} profile={profile} />
          {/*<CustomRoute*/}
          {/*  path={ROUTES.VIDEO_CALL}*/}
          {/*  component={VideoCall}*/}
          {/*  profile={profile}*/}
          {/*/>*/}
          <CustomRoute
            path={ROUTES.VIDEO_CALL}
            component={VideoCall}
            profile={profile}
          />
          <Route path={ROUTES.CALL_SUMMARY} component={CallSummary} />
          <Route path={ROUTES.CALL_REVIEW} component={CallReview} />
          <Route path={ROUTES.PRIMARY_DOCTORS} component={PrimaryDoctors} />
          <Route path={ROUTES.CLINIC} component={Clinic} />
          <Route path={ROUTES.SPECIALISTS} component={Specialists} />
          <Route path={ROUTES.RADIOS_LABS} component={RadiosLabs} />
          <CustomRoute path={ROUTES.HOME} component={Home} profile={profile} />
          <Route path={ROUTES.PROFILE_CREATE} component={CreateProfile} />
          <Route
            path={ROUTES.PROFILE_CREATE_EMAIL}
            component={CreateEmailProfile}
          />
          <Route path={ROUTES.FORGOT_PASSWORD} component={ForgotPass} />
          <Route path={ROUTES.CHANGE_PASSWORD} component={ChangePass} />
          <Route path={ROUTES.CHANGE_EMAIL} component={ChangeEmail} />
          <Route path={ROUTES.CHANGE_PHONE} component={ChangePhone} />
          <Route path={ROUTES.PAYMENT_CASHIN} component={PaymentCashIn} />
          <Route path={ROUTES.PAYMENT_WALLET} component={PaymentWallet} />
          <Route path={ROUTES.PAYMENT_PAY} component={PaymentPay} />
          <Route path={ROUTES.BOOKING_SUCCESS} component={BookingSuccess} />
          <Route path={ROUTES.INDEX} component={Home} exact />
          <Route path="*" component={HomeRedirect} />
        </Switch>
      );
    case UserRoleTypes.ADMIN:
      return (
        <Switch>
          <Route path={ROUTES.PROFILE_EDIT_VIDEO} component={EditDoctorVideo} />
          <Route path={ROUTES.PROFILE_EDIT_HOME} component={EditDoctorHome} />
          <Route
            path={ROUTES.PROFILE_EDIT_CLINIC}
            component={EditDoctorClinic}
          />
          <Route
            path={ROUTES.MANAGE_PATIENTS_EDIT}
            component={AdminEditPatient}
          />
          <Route
            path={ROUTES.MANAGE_PATIENTS}
            component={AdminManagePatients}
          />
          <Route path={ROUTES.HH_FORM_LEADS} component={HHFormLeads} />
          <Route
            path={ROUTES.MANAGE_DOCTORS_EDIT}
            component={AdminEditDoctor}
          />
          <Route path={ROUTES.MANAGE_DOCTORS} component={AdminManageDoctors} />
          <Route path={ROUTES.MANAGE_DIALOGS} component={Dialogs} />
          <Route path={ROUTES.MANAGE_LABS_EDIT} component={AdminEditLab} />
          <Route path={ROUTES.MANAGE_LABS} component={AdminManageLabs} />
          <Route path={ROUTES.MANAGE_RADIOS_EDIT} component={AdminEditRadio} />
          <Route path={ROUTES.MANAGE_RADIOS} component={AdminManageRadios} />
          <Route path={ROUTES.MANAGE_ADMINS} component={AdminManageAdmins} />
          <Route path={ROUTES.DUMPS} component={AdminDataDumps} />
          <Route path={ROUTES.FORGOT_PASSWORD} component={ForgotPass} />
          <Route path={ROUTES.CHANGE_PASSWORD} component={ChangePass} />
          <Route path={ROUTES.CHANGE_EMAIL} component={ChangeEmail} />
          <Route path={ROUTES.CHANGE_PHONE} component={ChangePhone} />
          <Route
            path={ROUTES.SEND_NOTIFICATIONS}
            component={AdminNotifications}
          />
          <Route path={ROUTES.HOME} component={AdminHome} />
          <Route path="*" component={HomeRedirect} />
        </Switch>
      );
    default:
      // unlogged-in should be a condition before this `switch - case of user role` ,
      // another way is make no user as a default case (but this can be controversial too).
      // There is one more, but in my feelings bad too, making it next option in enum user roles.
      return (
        <Switch>
          <Route path={ROUTES.SIGN_IN} component={SignIn} />
          <Route path={ROUTES.COMING_SOON} component={ComingSoon} />
          <Route path={ROUTES.SIGN_UP} component={SignUp} />
          <Route path={ROUTES.HELP} component={Help} />
          {!isMobileApp && (
            <Route path={ROUTES.FOR_DOCTOR} component={ForDoctor} />
          )}
          {!isMobileApp && <Route path={ROUTES.FOR_LAB} component={ForLab} />}
          {!isMobileApp && (
            <Route path={ROUTES.FOR_RADIO} component={ForRadio} />
          )}
          <Route path={ROUTES.SIGN_UP_DOCTOR} component={SignUpDoctor} />
          <Route path={ROUTES.SIGN_UP_LAB} component={SignUpLab} />
          <Route path={ROUTES.SIGN_UP_RADIO} component={SignUpRadio} />
          <Route path={ROUTES.PRIMARY_DOCTORS} component={PrimaryDoctors} />
          <Route
            path={ROUTES.DOCTOR_CLINIC}
            component={ClinicDetailsFromLink}
          />
          <Route path={ROUTES.CLINIC} component={Clinic} />
          <Route path={ROUTES.SPECIALISTS} component={Specialists} />
          <Route path={ROUTES.RADIOS_LABS} component={RadiosLabs} />
          <Route path={ROUTES.DOCTOR} component={DoctorDetailsFromLink} />
          <Route path={ROUTES.HOME} component={Home} />
          <Route path={ROUTES.FORGOT_PASSWORD} component={ForgotPass} />
          <Route path={ROUTES.CHANGE_PASSWORD} component={ChangePass} />
          <Route path={ROUTES.CHANGE_EMAIL} component={ChangeEmail} />
          <Route path={ROUTES.CHANGE_PHONE} component={ChangePhone} />
          <Route path={ROUTES.INDEX} component={Home} exact />
          {!isMobileApp && (
            <Route path={ROUTES.ADMIN_SIGN_IN} component={AdminSignIn} />
          )}
          <Route path="*" component={HomeRedirect} />
        </Switch>
      );
  }
}
