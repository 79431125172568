/* eslint-disable consistent-return */
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { AutoForm, SubmitField } from 'uniforms-material';

import { CodeFormSchema } from '../../api/schemas/CreateProfile';
import PhoneCodeField from '../../components/PhoneCodeField';
import { getI18nOptions } from '../../lib/utils';

type ConfirmProps = {
  phoneNumber: string;
  onConfirmCode: Function;
  disabled?: boolean;
  onSentCode?: any;
  fullPhone?: string;
  current?: string;
};
const useStyles = makeStyles(() =>
  createStyles({
    submit: {
      marginTop: `24px`,
    },
    buttonsParent: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      width: '100%',
    },
    resendBox: {
      display: 'flex',
      flexDirection: 'row',
    },
    countDown: {
      alignSelf: 'center',
      marginLeft: 16,
      fontSize: 16,
    },
    button: {
      margin: '24px 0',
    },
  }),
);
const ConfirmCode = ({
  phoneNumber,
  onConfirmCode,
  disabled = false,
  onSentCode,
  fullPhone,
}: ConfirmProps) => {
  const [resendStatus, setResendStatus] = useState<boolean>(false);
  const { t } = useTranslation();
  const cx = useStyles();
  const location = useLocation();
  const options = getI18nOptions(location.pathname);
  const properCodeLength = 6;
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [countDown, setCountDown] = useState<number>(45);

  const handleChangeCodeLength = (codeLength: number) => {
    setIsSubmitDisabled(codeLength !== properCodeLength);
  };
  const timer = useCallback(() => {
    setCountDown(countDown - 1);
  }, [countDown]);

  const sendSms = useCallback(() => {
    if (resendStatus) {
      onSentCode(fullPhone);
      setResendStatus(false);
      setCountDown(45);
    }
  }, [onSentCode, fullPhone, resendStatus]);

  useEffect(() => {
    if (countDown <= 0) {
      setResendStatus(true);
      return;
    }
    const id = setInterval(timer, 1000);
    return () => clearInterval(id);
  }, [countDown, timer]);
  return (
    <AutoForm
      schema={CodeFormSchema}
      onSubmit={({ code }: { code: number }) => {
        return onConfirmCode(code);
      }}
    >
      <PhoneCodeField
        name="code"
        label={t('common.enterPhoneCode', options)}
        phoneNumber={phoneNumber}
        properCodeLength={properCodeLength}
        handleChangeCodeLength={handleChangeCodeLength}
        disabled={disabled}
      />
      <Box className={cx.buttonsParent}>
        <SubmitField
          color="primary"
          label={t('common.submit', options)}
          className={cx.button}
          disabled={isSubmitDisabled}
        />
        <Box className={cx.resendBox}>
          <Button
            className={cx.button}
            variant="contained"
            color={resendStatus ? 'primary' : 'inherit'}
            disabled={!resendStatus}
            onClick={sendSms}
          >
            {t('common.resend', options)}
          </Button>
          <Box className={cx.countDown}>
            <p>{`${countDown}${' '}${t('common.resendUnit')}`}</p>
          </Box>
        </Box>
      </Box>
    </AutoForm>
  );
};

export default ConfirmCode;
