import React from 'react';

export const PhoneValidation = (phoneNumber: string) => {
  let errorMessage = '';
  const mobileRegex = /^\d{1,100}$/;

  if (
    phoneNumber.startsWith('+') ||
    phoneNumber.startsWith('2') ||
    phoneNumber.startsWith('0')
  ) {
    errorMessage = 'zeroError';
  } else if (
    // eslint-disable-next-line @typescript-eslint/prefer-string-starts-ends-with
    phoneNumber[0] === '0' &&
    phoneNumber[1] === '0' &&
    phoneNumber[2] === '2'
  ) {
    errorMessage = 'zeroError';
  } else if (!mobileRegex.test(phoneNumber)) {
    errorMessage = 'errors.Phone.notvalid';
  }

  if (errorMessage !== '') {
    return {
      valid: false,
      message: errorMessage,
    };
  }
  return {
    valid: true,
    message: '',
  };
};
