import TextField from '@material-ui/core/TextField';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { connectField } from 'uniforms';

function CustomDateField(props: any) {
  const { value = '', onChange, label, disabled, required } = props;
  const [maxDate, setMaxDate] = useState<any>();
  function onSelectChange({ target: { value } }: any) {
    onChange(value);
  }
  useEffect(() => {
    const currentDate = new Date();
    const today = currentDate.getDate();
    let day = `${today}`;
    if (today < 10) {
      day = `0${today}`;
    }
    let month = currentDate.getMonth();
    const year = currentDate.getFullYear();
    month = month + 1;
    let currenMonth = `${month}`;
    if (month < 10) {
      currenMonth = `0${month}`;
    }
    const fullDates = `${year}-${currenMonth}-${day}`;
    setMaxDate(fullDates);
  }, []);
  return (
    <TextField
      required={required}
      style={{ width: '100%' }}
      disabled={disabled}
      id="date"
      label={label}
      type="date"
      value={value}
      InputLabelProps={{
        shrink: true,
      }}
      inputProps={{ max: maxDate }}
      onChange={onSelectChange}
    />
  );
}

export default connectField(CustomDateField);
