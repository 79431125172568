import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { useVerificationStatus } from '../../api/usages/utils/useVerificationStatus';
import RouteTypes from '../../constants/routes';

const routesToCheck = [
  RouteTypes.PRIMARY_DOCTORS,
  RouteTypes.SPECIALISTS,
  RouteTypes.DOCTOR,
].map(path => path.split('/')[1] ?? '');

export const VerificationRedirect = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const pathname = history.location.pathname;

  const isVerified = useVerificationStatus();

  const isCheckedRoute = (path: string) =>
    routesToCheck.some(route => path.startsWith(`/${route}`));

  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (isVerified) {
      setOpen(false);
    }
  }, [isVerified]);

  useEffect(
    () =>
      history.listen(location => {
        if (isCheckedRoute(location.pathname) && !isVerified) {
          setOpen(true);
          history.replace(pathname);
        }
      }),
    [history, pathname, isVerified],
  );

  useEffect(() => {
    if (isCheckedRoute(pathname) && !isVerified) {
      setOpen(true);
    }
  }, [pathname, isVerified]);

  const handleAcknowledge = () => {
    setOpen(false);
    if (isCheckedRoute(pathname)) {
      history.replace(RouteTypes.HOME);
    }
  };

  return (
    <>
      {open ? (
        <Dialog
          open={open}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {t('common.accountDeactivated')}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleAcknowledge}>
              {t('booking.acknowledge')}
            </Button>
          </DialogActions>
        </Dialog>
      ) : null}
    </>
  );
};
export default VerificationRedirect;
