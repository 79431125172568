import { Button } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { ArrowBackIos, ArrowForwardIos } from '@material-ui/icons';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { ErrorField, AutoForm, SubmitField } from 'uniforms-material';

import { startDateSchema } from '../../../api/schemas/Booking';
import { i18next } from '../../../locales';
import DateTimeField from './DateTimeField';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
  },
  submitButton: {
    alignSelf: 'flex-end',
    marginRight: theme.spacing(5),
  },
  errorText: {
    alignSelf: 'center',
  },
}));

interface DateTimeProps {
  doctorId: string;
  updateTimeslot: (timeslot: number) => void;
  onSubmit: (startDate: any) => void;
  setPreviousStep: () => void;
  startDate: any;
  bookDoctorFromFavourite?: boolean;
}

const DateTime = ({
  doctorId,
  updateTimeslot,
  onSubmit,
  setPreviousStep,
  startDate,
  bookDoctorFromFavourite,
}: DateTimeProps) => {
  const [modelStartDate, setModelStartDate] = useState<any>(startDate);
  const [timeslot, setTimeslot] = useState<number>(15);
  const [timeInEgypt, setTimeInEgypt] = useState({});
  const { language } = i18next;
  const history = useHistory();
  const direction = language === 'ar' ? 'rtl' : 'ltr';
  const cx = useStyles();
  const { t } = useTranslation();

  const handleChangeStartDate = (newStartDate: any) => {
    setModelStartDate(newStartDate);
  };

  const handleSubmit = (startDate: any) => {
    // console.log('handleSubmit', startDate.toISOString(true));
    updateTimeslot(timeslot);
    const startDateAndTimeInEgypt = { ...startDate, timeInEgypt };
    onSubmit(startDateAndTimeInEgypt);
  };

  useEffect(() => {
    fetch('http://worldtimeapi.org/api/timezone/Africa/Cairo')
      .then(response => response.json())
      .then(data => {
        // Extract the current time from the API response
        const dateTime = data.datetime;
        // console.log(dateTime);
        const currentTime = moment(dateTime).zone(dateTime);
        // console.log(currentTime.format('h:mm'));
        setTimeInEgypt({
          time: currentTime.format('h:mm'),
          format: 'hh:mm',
          offset: currentTime.utcOffset() / 60,
        });
      })
      .catch(error => {
        console.log('Error:', error);
      });
  }, []);
  // console.log(timeInEgypt);
  return (
    <>
      <AutoForm
        schema={startDateSchema}
        model={{ startDate: modelStartDate }}
        onChange={handleChangeStartDate}
        onSubmit={handleSubmit}
        className={cx.root}
      >
        <SubmitField
          color="primary"
          className={cx.submitButton}
          label={t('common.submit')}
        />
        <ErrorField
          name="startDate"
          errorMessage={t('booking.timeslotError')}
          className={cx.errorText}
        />
        <DateTimeField
          name="startDate"
          doctorId={doctorId}
          value={modelStartDate}
          handleTimeslotChange={setTimeslot}
        />
      </AutoForm>

      {bookDoctorFromFavourite ? (
        <Button
          variant="contained"
          style={{ marginTop: '1em' }}
          onClick={() => history.goBack()}
          startIcon={
            direction === 'ltr' ? <ArrowBackIos /> : <ArrowForwardIos />
          }
        >
          {t('booking.backToFavorites')}
        </Button>
      ) : (
        <Button
          variant="contained"
          style={{ marginTop: '1em' }}
          onClick={() => setPreviousStep()}
          startIcon={
            direction === 'ltr' ? <ArrowBackIos /> : <ArrowForwardIos />
          }
        >
          {t('booking.backToDetails')}
        </Button>
      )}
    </>
  );
};

export default DateTime;
