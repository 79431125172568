import { Query, Doctor } from '../types';
import { useCollection, UseCollectionOptions } from './collection';
import { useDocument } from './document';
import { useVerifiedTestingQuery } from './utils/useVerifiedTestingQuery';

export function useDoctors(query?: Query, options?: UseCollectionOptions) {
  const { results, loading, error } = useCollection<Doctor>(
    {
      collectionPath: 'doctors',
      query,
    },
    options,
  );

  return {
    error,
    loading,
    doctors: results,
  };
}

export function useVerifiedDoctors(
  query?: Query,
  options?: UseCollectionOptions,
) {
  const newQuery = useVerifiedTestingQuery(query);

  return useDoctors(newQuery, options);
}

export function useDoctor(doctorId?: string | null) {
  const { result, error, loading } = useDocument<Doctor>(
    {
      path: `doctors/${doctorId}`,
    },
    {
      skip: !doctorId,
    },
  );

  return {
    error,
    loading,
    doctor: result,
  };
}
