import { useTranslation } from 'react-i18next';

export function PasswordValidation(password: string, login = false) {
  // str = str.replace(/\s/g, '');
  const errors: Array<string> = [];
  if (login) {
    if (password.length < 1) {
      errors.push('atLeast6');
      return {
        valid: false,
        message: errors,
      };
    }
    return {
      valid: true,
      message: '',
    };
  }

  if (/\s/.test(password)) {
    errors.push('withoutSpaces');
  }
  if (password.length < 6) {
    errors.push('atLeast6');
  }
  if (!/[a-z]{1,}/.test(password)) {
    errors.push('atLeastOneLower');
  }
  if (!/[A-Z]{1,}/.test(password)) {
    errors.push('atLeastOneCaps');
  }
  if (errors.length > 0) {
    // errors.unshift(t('errors.password.errorTitle'));
    return {
      valid: false,
      message: errors,
    };
  }
  return {
    valid: true,
    message: '',
  };
}
