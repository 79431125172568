import { Typography } from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import ROUTES from '../../constants/routes';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    link: {
      textDecoration: 'none',
    },
  }),
);

function BookingFinished() {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <Typography className={classes.instructions} align="center" variant="h5">
        {t('booking.finishedBooking')}{' '}
        <Link to={ROUTES.BOOKINGS} className={classes.link}>
          {t('common.bookings')}
        </Link>
      </Typography>
      <Typography className={classes.instructions} align="center" variant="h5">
        {t('booking.backToHome')}{' '}
        <Link to={ROUTES.HOME} className={classes.link}>
          {t('common.home')}
        </Link>
      </Typography>
    </>
  );
}

export default BookingFinished;
