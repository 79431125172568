import { Query, Observation } from '../types';
import { UseCollectionOptions, useCollection } from './collection';

export const useObservations = (
  query?: Query,
  options?: UseCollectionOptions,
) => {
  const { results: observations, loading, error } = useCollection<Observation>(
    {
      collectionPath: 'observations',
      query,
    },
    options,
  );

  return { observations, loading, error };
};
