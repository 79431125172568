import { Query, Governorates, Area } from '../types';
import { UseCollectionOptions, useCollection } from './collection';

// export const useCountries = (query?: Query, options?: UseCollectionOptions) => {
//   const { results: governorates, loading, error } = useCollection<Governorates>(
//     {
//       collectionPath: 'countries',
//       query,
//     },
//     options,
//   );

//   return { governorates, loading, error };
// };

export const useGovernorates = (
  query?: Query,
  options?: UseCollectionOptions,
) => {
  const { results: governorates, loading, error } = useCollection<Governorates>(
    {
      collectionPath: 'governorates',
      query,
    },
    options,
  );

  return { governorates, loading, error };
};

export const useArea = (query?: Query, options?: UseCollectionOptions) => {
  const { results: area, loading, error } = useCollection<Area>(
    {
      collectionPath: 'areas',
      query,
    },
    options,
  );

  return { area, loading, error };
};
