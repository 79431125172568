import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/storage';
import 'firebase/analytics';
import React from 'react';
import ReactDOM from 'react-dom';
import { from, of } from 'rxjs';
import { fromFetch } from 'rxjs/fetch';
import { catchError, shareReplay, switchMap } from 'rxjs/operators';

// eslint-disable-next-line no-duplicate-imports
import {
  APP_URL,
  CURRENT_RUNTIME,
  GOOGLE_MAPS_SCRIPT_URL,
  PUBLIC_URL,
  RuntimeTypes,
} from './constants/app';

import './globals.d';

// initialising boot stream
const boot$ = of(CURRENT_RUNTIME);

const doFetch = (confPath: string) => {
  return fromFetch(confPath, { method: 'get' }).pipe(
    catchError(err => {
      console.warn('⚠️1:', err);
      const initUrl = APP_URL + confPath;
      console.warn('retry for ', initUrl);
      return fromFetch(initUrl);
    }),
    // checking for PUBLIC_URL
    catchError(err => {
      console.warn('⚠️2:', err);
      const initUrl = PUBLIC_URL + confPath;
      console.warn('retry for ', initUrl);
      return fromFetch(initUrl, { method: 'get' });
    }),
    // checking for PUBLIC_URL
    catchError(err => {
      console.warn('⚠️3:', err);
      const initUrl = window.location.origin + '/__/firebase/init.json';
      console.warn('retry for ', initUrl);
      return fromFetch(initUrl, { method: 'get' });
    }),
    // getting firebase conf
    switchMap(response => response.json()),
    shareReplay(),
  );
};

// Initialisation with firebase credentials
const firebaseConf$ = boot$.pipe(
  switchMap(runtimeType => {
    const linkRef = document.getElementById('firebase-config');
    const remoteInitUrl =
      (linkRef && (linkRef as HTMLLinkElement).href) ||
      '/__/firebase/init.json';
    if (runtimeType === RuntimeTypes.MobileApp) {
      const modulePromise = import('./mobileAppOnly');
      return from(modulePromise).pipe(
        switchMap(async ({ getFirebaseConfig }) => {
          return getFirebaseConfig(remoteInitUrl);
        }),
        catchError(err => {
          console.error('💾 init.json', err);
          return doFetch(remoteInitUrl);
        }),
      );
    }
    return doFetch(remoteInitUrl);
  }),
);

const prepareLibs$ = firebaseConf$.pipe(
  // configuring firebase
  switchMap(async (firebaseConfig: any) => {
    // eslint-disable-next-line no-console
    // console.log('firebaseConfig', firebaseConfig);
    firebase.initializeApp(firebaseConfig);
    // firebase.initializeApp({
    //   apiKey: 'AIzaSyAS3KYoOstjAeKv4qdsSYehRI7eezJDVpQ',
    //   authDomain: 'shoof-doctor-staging.firebaseapp.com',
    //   databaseURL: 'https://shoof-doctor-staging-default-rtdb.firebaseio.com',
    //   projectId: 'shoof-doctor-staging',
    //   storageBucket: 'shoof-doctor-staging.appspot.com',
    //   messagingSenderId: '325831426037',
    //   appId: '1:325831426037:web:f74cb6cf7243a862732abc',
    //   measurementId: 'G-PLR3Z1RRN1',
    // });

    if (process.env.REACT_APP_DEBUG) {
      firebase.firestore.setLogLevel('debug');
    }
    // if (
    //   __DEV__ &&
    //   process.env.REACT_APP_EMULATORS &&
    //   CURRENT_RUNTIME !== RuntimeTypes.MobileApp
    // ) {
    //   console.info('🕹 Using emulators:', process.env.REACT_APP_EMULATORS);
    //   process.env.PUBSUB_EMULATOR_HOST = 'pubsub.localhost:3000';
    //   if (process.env.REACT_APP_EMULATORS.includes('firestore')) {
    //     firebase.firestore().settings({
    //       host: 'firestore.localhost:3000',
    //       ssl: false,
    //       experimentalForceLongPolling: true,
    //     });
    //   }
    //   firebase
    //     .app()
    //     .functions('europe-west3')
    //     .useFunctionsEmulator('http://functions.localhost:3000');
    // }
    if (CURRENT_RUNTIME === RuntimeTypes.MobileApp) {
      try {
        await firebase.firestore().enablePersistence();
      } catch (e) {
        console.error('firestore', e);
      }
    }
    console.info('firebase initialized');
  }),
  // configuring maps
  switchMap(async () => {
    // getting configs
    const snapConf = await firebase
      .firestore()
      .doc('/publicConfigs/googleMaps')
      .get();

    if (!snapConf.exists) {
      console.warn(
        '⚠️ Missing Google Maps API KEY in `/publicConfigs/googleMaps`',
      );
    }
    // adding maps script
    const mapApiKey = snapConf.get('apiKey') || '';
    const mapScript = document.createElement('script');
    mapScript.setAttribute('id', 'google-map');
    mapScript.src = GOOGLE_MAPS_SCRIPT_URL + mapApiKey;
    (document.querySelector('head') as any).appendChild(mapScript);
  }),
);

// Start UI
prepareLibs$
  .pipe(
    // The app needs to wait until firebase will be initialized
    // and then we can proceed with mode eager -> to load import without waiting on execution
    switchMap(() => import(/* webpackMode: "eager" */ './startup')),
  )
  .subscribe(
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    async ({ App }: { App: React.FC }) => {
      // render App tree
      ReactDOM.render(<App />, document.getElementById('root'));

      if (CURRENT_RUNTIME === RuntimeTypes.MobileApp) {
        try {
          const { hideSplash, notifications } = await import('./mobileAppOnly');
          await hideSplash();
          await notifications.init();
        } catch (e) {
          console.error('notifications', e);
        }
      }
    },
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    async err => {
      // render minimal sets of App, for showing error
      console.error(err);
      try {
        const { AppError } = await import(
          /* webpackMode: "eager" */ './screens/AppError'
        );
        ReactDOM.render(
          <AppError error={err} />,
          document.getElementById('root'),
        );
      } catch (e) {
        // if error page also has a problem
        if (__DEV__) {
          alert(err.toString());
          return;
        }
        alert('Something went wrong!');
      }
    },
  );
