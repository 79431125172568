import { LocationState } from 'history';
import { generatePath } from 'react-router';

import RouteTypes from '../../constants/routes';
import { history } from '../../lib/utils';

export const navigate = (
  route: RouteTypes,
  params?: { [paramName: string]: string | number | boolean | undefined },
  state?: LocationState,
) => {
  const path = generatePath(route, params);
  if (state) {
    history.push(path, state);
  } else {
    history.push(path);
  }
};

export const replace = (
  route: RouteTypes,
  params?: { [paramName: string]: string | number | boolean | undefined },
  state?: LocationState,
) => {
  const path = generatePath(route, params);
  if (state) {
    history.replace(path, state);
  } else {
    history.replace(path);
  }
};
