import { Query, Diagnosis } from '../types';
import { UseCollectionOptions, useCollection } from './collection';

export const useDiagnoses = (query?: Query, options?: UseCollectionOptions) => {
  const { results: diagnoses, loading, error } = useCollection<Diagnosis>(
    {
      collectionPath: 'diagnoses',
      query,
    },
    options,
  );

  return { diagnoses, loading, error };
};
