import { Button } from '@material-ui/core';
import ListItem from '@material-ui/core/ListItem';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { signOut } from '../../api/actions/auth';
import SummaryDialog, { useToggle } from '../CallSummary/SummaryDialog';

type SignOutListItemProps = {
  children: React.ReactNode;
};

const SignOutListItem = ({ children }: SignOutListItemProps) => {
  const [showModal, toggleModal] = useToggle(false);
  const { t } = useTranslation();

  return (
    <>
      <ListItem button component="button" onClick={toggleModal}>
        {children}
      </ListItem>
      {showModal ? (
        <SummaryDialog
          open={showModal}
          title={t('common.areYouSureWannaSignOut')}
          onClose={toggleModal}
          extraButton={() => (
            <Button
              color="primary"
              variant="contained"
              onClick={() => signOut()}
            >
              {t('common.signOut')}
            </Button>
          )}
          customCloseText={t('common.back')}
        />
      ) : null}
    </>
  );
};

export default SignOutListItem;
