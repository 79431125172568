import { Button, Typography } from '@material-ui/core';
import React from 'react';
import { FallbackProps } from 'react-error-boundary';

const ErrorFallback = ({ error, resetErrorBoundary }: FallbackProps) => {
  // send error to firebase
  // sendErrorToFirebase(error);
  console.log(error);
  return (
    <div
      style={{
        width: '100%',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Typography color="error" variant="h5">
        🚨 🛑 🚧
      </Typography>
      <Typography color="error" variant="h5">
        Something went wrong
      </Typography>
      <pre>{error.message}</pre>
      <Button onClick={resetErrorBoundary} variant="contained">
        Try again
      </Button>
    </div>
  );
};
export default ErrorFallback;
