import firebase from 'firebase';
import React, { useState, useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { doPhoneUpdate, verifyPhone } from '../../api/actions/auth';
import { UserRoleTypes } from '../../api/types';
import ConfirmCode from './ConfirmCode';
import EnterPhoneNumber from './EnterPhoneNumber';

type ConfirmState = {
  phoneNumber: string;
  onConfirmCode: Function;
};

type onAuthorizedArgs = { user: firebase.User; profileType: UserRoleTypes };

type PhoneVerificationProps = {
  profileType: string;
  updateNumber?: boolean;
  current?: string;
  setPhoneNumber?: any;
  setErrorValidtion?: any;
  setValidtionMsg?: any;
  setValidtionPass?: Function;
  errorStatus?: any;
  setErrorStatus?: Function;
  onAuthorized?: ((arg: onAuthorizedArgs) => any) | true;
  isDialog?: boolean;
};

const PhoneVerification = ({
  profileType,
  onAuthorized,
  updateNumber,
  current,
  setPhoneNumber,
  setErrorValidtion,
  setValidtionMsg,
  setValidtionPass,
  errorStatus,
  setErrorStatus,
  isDialog,
}: PhoneVerificationProps) => {
  const [confirmState, setConfirmState] = useState<ConfirmState | null>(null);
  const [fullPhone, setFullPhone] = useState<string>('');
  const [phoneNumbers, setPhoneNumbers] = useState<string>('');
  const history = useHistory();
  useEffect(() => {
    return history.listen(() => {
      if (
        history.action === 'POP' &&
        phoneNumbers.length > 1 &&
        history.location.pathname === '/sign-up'
      ) {
        history.push({
          pathname: '/sign-up',
        });
        setConfirmState(null);
        setPhoneNumbers && setPhoneNumbers('');
      } else if (history.action === 'POP') {
        setConfirmState(null);
        setPhoneNumbers && setPhoneNumbers('');
      }
    });
  }, [phoneNumbers.length, history]);
  const handleSendSms = async (phoneNumber: string) => {
    setPhoneNumbers && setPhoneNumbers(phoneNumber);
    const method = updateNumber ? doPhoneUpdate : verifyPhone;
    try {
      const { complete } = await method({
        phoneNumber,
        profileType,
        onAuthorized,
      });
      setConfirmState({ onConfirmCode: complete, phoneNumber });
      if (
        phoneNumber.length > 1 &&
        history.location.pathname !== '/sign-up' &&
        history.location.pathname !== '/sign-up-doctor' &&
        history.location.pathname !== '/forgot-password/patient' &&
        history.location.pathname !== '/forgot-password/doctor' &&
        history.location.pathname !== '/forgot-password/lab' &&
        history.location.pathname !== '/forgot-password/radio'
      ) {
        history.push({
          pathname: history.location.pathname,
          state: {
            ...confirmState,
          },
        });
      }
    } catch (err) {
      console.warn(err);
      setConfirmState(null);
    }
  };

  return (
    <div>
      {confirmState ? (
        <ConfirmCode
          {...confirmState}
          onSentCode={handleSendSms}
          fullPhone={fullPhone}
          current={current}
        />
      ) : (
        <EnterPhoneNumber
          current={current}
          canSend
          onSentCode={handleSendSms}
          setPhoneNumber={setPhoneNumber}
          setFullPhone={setFullPhone}
          setErrorValidtion={setErrorValidtion}
          setValidtionMsg={setValidtionMsg}
          setValidtionPass={setValidtionPass}
          errorStatus={errorStatus}
          setErrorStatus={setErrorStatus}
          profileType={profileType}
          isDialog={isDialog}
        />
      )}
    </div>
  );
};

export default PhoneVerification;
