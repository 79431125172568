import { Query, Booking, UnrealizedBooking } from '../types';
import { useCollection, UseCollectionOptions } from './collection';
import { useDocument } from './document';

export function useBookings(query?: Query, options?: UseCollectionOptions) {
  const { results, error, loading } = useCollection<Booking>(
    {
      collectionPath: 'bookings',
      query,
    },
    options,
  );
  return {
    error,
    loading,
    bookings: results,
  };
}

export function useBooking(bookingId?: string | null) {
  const { result, error, loading } = useDocument<Booking>(
    {
      path: `bookings/${bookingId}`,
    },
    {
      skip: !bookingId,
    },
  );

  return {
    error,
    loading,
    booking: result,
  };
}

export function useLabBookings(query?: Query, options?: UseCollectionOptions) {
  const { results, error, loading } = useCollection<any>(
    {
      collectionPath: 'labBookings',
      query,
    },
    options,
  );

  return {
    error,
    loading,
    labBookings: results,
  };
}

export function useRadioBookings(
  query?: Query,
  options?: UseCollectionOptions,
) {
  const { results, error, loading } = useCollection<any>(
    {
      collectionPath: 'radioBookings',
      query,
    },
    options,
  );
  return {
    error,
    loading,
    radioBookings: results,
  };
}

export function useUnrealizedBookings(
  query?: Query,
  options?: UseCollectionOptions,
) {
  const { results, error, loading } = useCollection<UnrealizedBooking>(
    {
      collectionPath: 'unrealizedBookings',
      query,
    },
    options,
  );
  return {
    error,
    loading,
    unrealizedBookings: results,
  };
}
