import { SimpleQuery, Query, Testable } from '../../types';
import { useProfile } from '../auth';

export const useVerifiedTestingQuery = (query?: Query) => {
  const { testing = false } = (useProfile() as Testable) ?? {};

  const onlyVerified: SimpleQuery = [
    {
      fieldPath: 'verified',
      opStr: '==',
      value: true,
    },
    {
      fieldPath: 'testing',
      opStr: '==',
      value: testing,
    },
  ];

  const newQuery: Query = !query
    ? onlyVerified
    : Array.isArray(query)
    ? [...onlyVerified, ...query]
    : { ...query, where: [...onlyVerified, ...query.where] };

  return newQuery;
};
