import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import RouteTypes from '../../constants/routes';

const GeoLimitationPopup = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const [open, setOpen] = useState(false);

  useEffect(() => {
    fetch('https://ipapi.co/json')
      .then(res => res.json())
      .then(({ country }) => {
        if (country && country !== 'EG') {
          setOpen(true);
        }
      });
  }, []);

  const handleAcknowledge = () => {
    setOpen(false);
  };

  const handleRevert = () => {
    history.replace(RouteTypes.HOME);
  };

  return (
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {t('booking.geographicalLimitation')}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {t('booking.geographicalLimitationDetails')}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleAcknowledge}>{t('booking.acknowledge')}</Button>
        <Button onClick={handleRevert}>{t('booking.revert')}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default GeoLimitationPopup;
