export enum RecordType {
  WEIGHT = 'weight',
  HEIGHT = 'height',
}

export type Record = {
  dates: Date;
  values: number;
};

export type HealthRecords = {
  weights: {
    dates: Array<Date>;
    values: Array<number>;
  };
  heights: {
    dates: Array<Date>;
    values: Array<number>;
  };
};
