import { i18next } from '../../locales';

export const getTranslation = (obj: any): string => {
  if (!obj?.translations) {
    return '';
  }
  const name = obj.translations[i18next.language];
  return name || obj.translations['en'];
};

export const getTranslations = (arr: any[]): string => {
  return arr.map((obj: any) => getTranslation(obj)).join(', ');
};

export const getCommercialTranslation = (obj: any): string => {
  if (!obj?.commercialTranslations) {
    return '';
  }
  const name = obj.commercialTranslations[i18next.language];
  return name || obj.commercialTranslations['en'];
};

export const getCommercialTranslationsAlphabetically = (arr: any[]): string => {
  return arr
    .map((obj: any) => getCommercialTranslation(obj))
    .sort((a, b) => a.localeCompare(b))
    .join(', ');
};
