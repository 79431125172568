import { Accordion, Grid } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { i18next } from '../../locales';
import ExpansionElementBooking from '../ExpansionElementBooking';
import RadioGroupFilter from './RadioGroupFilter';

const en = i18next.getFixedT('en');

const priceLabels = [
  'priceUnder50',
  'priceFrom50To100',
  'priceFrom100To200',
  'priceFrom200',
];

const priceOptions = [
  { label: `booking.${priceLabels[0]}`, value: priceLabels[0] },
  { label: `booking.${priceLabels[1]}`, value: priceLabels[1] },
  { label: `booking.${priceLabels[2]}`, value: priceLabels[2] },
  { label: `booking.${priceLabels[3]}`, value: priceLabels[3] },
];

const medTitleOptions = [
  {
    label: 'profile.title_consultant',
    value: en('profile.title_consultant').toLowerCase(),
  },
  {
    label: 'profile.title_practitioner',
    value: en('profile.title_practitioner').toLowerCase(),
  },
  {
    label: 'profile.title_specialist',
    value: en('profile.title_specialist').toLowerCase(),
  },
];

const genderOptions = [
  {
    label: 'profile.male',
    value: en('profile.male').toLowerCase(),
  },
  {
    label: 'profile.female',
    value: en('profile.female').toLowerCase(),
  },
];

const languageOptions = [
  { label: 'booking.arabic', value: 'arabicProficiency' },
  { label: 'booking.english', value: 'englishProficiency' },
  { label: 'booking.french', value: 'frenchProficiency' },
];

type DoctorFiltersProps = {
  priceRange: string;
  medTitle: string;
  gender: string;
  language: string;
  handlePriceRangeChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    uncheck: boolean,
  ) => void;
  handleMedTitleChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    uncheck: boolean,
  ) => void;
  handleGenderChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    uncheck: boolean,
  ) => void;
  handleLanguageChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    uncheck: boolean,
  ) => void;
  disableMedTitleFilter: boolean;
};

const DoctorFilters = ({
  priceRange,
  medTitle,
  gender,
  language,
  handlePriceRangeChange,
  handleMedTitleChange,
  handleGenderChange,
  handleLanguageChange,
  disableMedTitleFilter,
}: DoctorFiltersProps) => {
  const { t } = useTranslation();

  const FiltersComponent = () => {
    return (
      <>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="stretch"
          spacing={3}
        >
          <Grid item sm={3} xs={6}>
            <RadioGroupFilter
              title={t('booking.priceForVisitShort')}
              value={priceRange}
              handleChange={handlePriceRangeChange}
              options={priceOptions}
            />
          </Grid>
          <Grid item sm={3} xs={6}>
            <RadioGroupFilter
              title={t('profile.medTitleShort')}
              value={medTitle}
              handleChange={handleMedTitleChange}
              options={medTitleOptions}
              disableFilter={disableMedTitleFilter}
            />
          </Grid>
          <Grid item sm={3} xs={4}>
            <RadioGroupFilter
              title={t('profile.gender')}
              value={gender}
              handleChange={handleGenderChange}
              options={genderOptions}
            />
          </Grid>
          <Grid item sm={3} xs={6}>
            <RadioGroupFilter
              title={t('booking.language')}
              value={language}
              handleChange={handleLanguageChange}
              options={languageOptions}
            />
          </Grid>
        </Grid>
      </>
    );
  };
  return (
    <ExpansionElementBooking
      isExpanded={false}
      title={t('booking.setFilters')}
      Component={FiltersComponent}
    />
  );
};

export default DoctorFilters;
