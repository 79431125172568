import { Button, Chip, Typography } from '@material-ui/core';
import { LocationOn } from '@material-ui/icons';
import React, { useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';

import { showSuccessToast } from '../../api/actions/uiControls';
import { Timestamp, db } from '../../lib/firebase';

const updateField = async (id: any, field: string, value: any) => {
  const referralsHomeCollectionRef = db.collection('referralsHome');
  const referralDocRef = referralsHomeCollectionRef.doc(id);
  try {
    const updateObject = {} as any;
    updateObject[field] = value;
    await referralDocRef.update(updateObject);
    return value;
  } catch (error) {
    console.log(error);
  }
};

const LeadItem = ({ lead }: any) => {
  const [closed, setClosed] = React.useState(lead.closed);
  const [registration, setRegistration] = useState(lead.registration);
  const { t, i18n } = useTranslation();
  const date = (lead.createdAt as Timestamp).toDate();
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const dateString = `${year}-${month}-${day}`;
  return (
    <div
      style={{
        display: 'flex',
        gap: '10px',
        padding: '10px',
        justifyContent: 'space-evenly',
      }}
    >
      <div
        style={{
          display: 'flex',
          gap: '10px',
          flexDirection: 'column',
          flex: 1,
          alignItems: 'center',
        }}
      >
        {lead.createdAt ? (
          <>
            <Typography>{dateString}</Typography>
            <Typography>
              {lead.createdAt
                .toDate()
                .toLocaleString(i18n.language === 'en' ? 'en-EG' : 'ar-EG', {
                  hour: 'numeric',
                  minute: '2-digit',
                  hour12: true,
                })}
            </Typography>
          </>
        ) : (
          <>
            <Typography>D/MM/YYYY</Typography>
            <Typography>hh:mm tt</Typography>
          </>
        )}
        {lead.locationURL ? (
          <Button
            color="primary"
            variant="contained"
            style={{
              backgroundColor: 'rgb(98, 9, 153)',
            }}
            onClick={() => {
              if (lead.locationURL) {
                return window.open(lead.locationURL, '_blank');
              }
              return null;
            }}
          >
            <LocationOn />
          </Button>
        ) : (
          <Button
            color="primary"
            variant="contained"
            style={{
              backgroundColor: '#e0e0e0',
            }}
            disabled
          >
            <LocationOn />
          </Button>
        )}
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          flex: 1,
        }}
      >
        <Typography color="textPrimary">{lead.name}</Typography>
        <Typography color="textSecondary">
          {lead.speciality.length > 0
            ? lead.speciality[0].name
            : t('admin.referralsHome.noSpeciality')}
        </Typography>
      </div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flex: 1,
        }}
      >
        <Chip
          label={lead.mobile ? lead.mobile : 'لا يوجد'}
          variant="outlined"
          onClick={() => {
            if (lead.mobile) {
              return window.open(`https://wa.me/${lead.mobile}`, '_blank');
            }
            return null;
          }}
        />
      </div>
      <div
        style={{
          display: 'flex',
          flex: 1,
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
          <Button
            variant="contained"
            style={{ backgroundColor: '#c26dbf', color: 'white' }}
            onClick={async () => {
              const result = await updateField(lead.id, 'closed', !closed);
              setClosed(result);
              console.log(result);
            }}
          >
            {closed
              ? t('admin.referralsHome.closed')
              : t('admin.referralsHome.quote')}
          </Button>
          <Button
            variant="contained"
            style={{ backgroundColor: 'rgb(98, 9, 153)', color: 'white' }}
            onClick={async () => {
              const result = await updateField(
                lead.id,
                'registration',
                !registration,
              );
              setRegistration(result);
              console.log(result);
            }}
          >
            {registration
              ? t('admin.referralsHome.registered')
              : t('admin.referralsHome.notRegistered')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default LeadItem;
