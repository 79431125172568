import { Query, Nationality } from '../types';
import { UseCollectionOptions, useCollection } from './collection';

export const useNationalities = (
  query?: Query,
  options?: UseCollectionOptions,
) => {
  const { results: nationalities, loading, error } = useCollection<Nationality>(
    {
      collectionPath: 'nationalities',
      query,
    },
    options,
  );

  return { nationalities, loading, error };
};
