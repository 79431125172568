import { JSONSchema } from '../../lib/utils';

const GeoPointSchema: JSONSchema = {
  type: 'object',
  properties: {
    latitude: {
      type: 'number',
    },
    longitude: {
      type: 'number',
    },
  },
  required: ['latitude', 'longitude'],
};

export default GeoPointSchema;
