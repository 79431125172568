import EJSON from 'ejson';

export const getSessionData = (key: string) => {
  const data = sessionStorage.getItem(key);
  if (data) {
    try {
      return EJSON.parse(data);
    } catch (err) {
      console.error('readUnloggedInData', err);
    }
  }
  return false;
};

export const setSessionData = (key: string, obj: any) => {
  if (!obj || typeof obj !== 'object') {
    throw new TypeError('New state must be an object');
  }
  sessionStorage.setItem(key, EJSON.stringify(obj));
};

export const popBookingFromSessionData = () => {
  const booking = getSessionData('booking');
  sessionStorage.clear();
  return booking;
};
