import { Step, StepButton, Stepper } from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { UserRoleTypes } from '../../api/types';
import { useContextRole } from '../../api/usages';
import BookingFinished from './BookingFinished';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: '100%',
      padding: 15,
    },
    stepper: {
      backgroundColor: 'transparent',
      [theme.breakpoints.down('xs')]: {
        padding: '24px 0 24px 0',
      },
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    step: {
      [theme.breakpoints.down('xs')]: {
        padding: '0 4px 0 4px',
      },
    },
    stepLabel: {
      textAlign: 'center',
      [theme.breakpoints.down('xs')]: {
        fontSize: '11px',
      },
    },
    link: {
      textDecoration: 'none',
    },
  }),
);

function getSteps(allSteps: any) {
  return allSteps.map((step: string) => step);
}

interface BookingStepperProps {
  stepsList: Array<string>;
  onStepChange?: any;
  activeStep: number;
  children?: React.ReactNode;
}

function BookingStepper(props: BookingStepperProps) {
  const cx = useStyles();
  const { t } = useTranslation();
  const { stepsList, children, activeStep, onStepChange } = props;
  const steps = getSteps(stepsList);
  const role = useContextRole();

  return (
    <div className={cx.root}>
      {role !== UserRoleTypes.DOCTOR && (
        <Stepper
          className={cx.stepper}
          activeStep={activeStep}
          alternativeLabel
        >
          {steps.map((label: any, index: number) => (
            <Step key={label} className={cx.step}>
              <StepButton onClick={() => onStepChange(index)}>
                <span className={cx.stepLabel}>{t(label)}</span>
              </StepButton>
            </Step>
          ))}
        </Stepper>
      )}
      <div>
        {activeStep === steps.length ? (
          <BookingFinished />
        ) : (
          <div>{children}</div>
        )}
      </div>
    </div>
  );
}

export default BookingStepper;
