import {
  Query,
  LabCommercialCategory,
  RadioCommercialCategory,
} from '../types';
import { useCollection, UseCollectionOptions } from './collection';

export const useLabCommercialCategories = (
  query?: Query,
  options?: UseCollectionOptions,
) => {
  const { results: labCommercialCategories, loading, error } = useCollection<
    LabCommercialCategory
  >(
    {
      collectionPath: 'labCommercialCategories',
      query,
    },
    options,
  );

  return { labCommercialCategories, loading, error };
};

export const useRadioCommercialCategories = (
  query?: Query,
  options?: UseCollectionOptions,
) => {
  const { results: radioCommercialCategories, loading, error } = useCollection<
    RadioCommercialCategory
  >(
    {
      collectionPath: 'radioCommercialCategories',
      query,
    },
    options,
  );

  return { radioCommercialCategories, loading, error };
};
