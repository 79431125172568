import { Query, DoctorReferral } from '../types';
import { LabReferral } from '../types/LabReferral';
import { RadioReferral } from '../types/RadioReferral';
import { UseCollectionOptions, useCollection } from './collection';

export const useDoctorReferrals = (
  query?: Query,
  options?: UseCollectionOptions,
) => {
  const { results: doctorReferrals, loading, error } = useCollection<
    DoctorReferral
  >(
    {
      collectionPath: 'doctorReferrals',
      query,
    },
    options,
  );

  return { doctorReferrals, loading, error };
};

export const useLabReferrals = (
  query?: Query,
  options?: UseCollectionOptions,
) => {
  const { results: labReferrals, loading, error } = useCollection<LabReferral>(
    {
      collectionPath: 'labReferrals',
      query,
    },
    options,
  );

  return { labReferrals, loading, error };
};

export const useRadioReferrals = (
  query?: Query,
  options?: UseCollectionOptions,
) => {
  const { results: radioReferrals, loading, error } = useCollection<
    RadioReferral
  >(
    {
      collectionPath: 'radioReferrals',
      query,
    },
    options,
  );

  return { radioReferrals, loading, error };
};
