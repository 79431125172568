export enum RouteTypes {
  INDEX = '/',
  // Please keep alphabetical order bellow
  ADMIN_SIGN_IN = '/admin-sign-in',
  BOOKING = '/booking',
  BOOKING_SUCCESS = '/booking/:bookingId/success',
  BOOKINGS = '/bookings',
  CALL_REVIEW = '/call-review/:id',
  CALL_SUMMARY = '/call-summary/:id',
  CHANGE_EMAIL = '/change-email',
  CHANGE_PASSWORD = '/change-password',
  CHANGE_PHONE = '/change-phone',
  COMING_SOON = '/coming-soon',
  DOCTOR = '/doctor/:id',
  DOCTOR_CLINIC = '/clinic/:id',
  DOCTORS = '/doctors/:specialtyId/:medTitle',
  DUMPS = '/dumps',
  FAVOURITES = '/favourites',
  FOLLOW_UP = '/follow-up/:bookingId',
  FOR_DOCTOR = '/for-doctor',
  FOR_LAB = '/for-lab',
  FOR_RADIO = '/for-radio',
  FORGOT_PASSWORD = '/forgot-password/:profileType',
  HEALTH_RECORDS = '/health_records',
  SEND_NOTIFICATIONS = '/send-notifications',
  HEALTH_RECORDS_VISIT = '/health_records_visit/:bookingId',
  HEALTH_RECORDS_PRESCRIPTION = '/health_records_prescription/:bookingId',
  HELP = '/help',
  HOME = '/home',
  LABS = '/labs/:serviceId',
  LAB_DETAILS = '/lab_details/:labId',
  MANAGE_ADMINS = '/manage-admins',
  MANAGE_DIALOGS = '/manage-dialogs',
  MANAGE_DOCTORS = '/manage-doctors',
  MANAGE_DOCTORS_EDIT = '/manage-doctors/:doctorId',
  MANAGE_LABS = '/manage-labs',
  MANAGE_LABS_EDIT = '/manage-labs/:labId',
  MANAGE_PATIENTS = '/manage-patients',
  HH_FORM_LEADS = '/hh_form_leads',
  MANAGE_PATIENTS_EDIT = '/manage-patients/:patientId',
  MANAGE_RADIOS = '/manage-radios',
  MANAGE_RADIOS_EDIT = '/manage-radios/:radioId',
  ORDERS = '/orders',
  PAYMENT_CASHIN = '/wallet/payments',
  PAYMENT_PAY = '/payments/pay/:orderId',
  PAYMENT_STATUS = '/payments/status/:orderId',
  PAYMENT_WALLET = '/wallet',
  PRESCRIPTIONS = '/prescriptions',
  PRIMARY_DOCTORS = '/primary_doctors',
  CLINIC = '/clinic',
  PROFILE = '/profile',
  PROFILE_EDIT_VIDEO = '/profile/edit/video',
  PROFILE_EDIT_CLINIC = '/profile/edit/clinic',
  PROFILE_EDIT_HOME = '/profile/edit/home',
  PROFILE_CREATE = '/create-profile',
  PROFILE_CREATE_EMAIL = '/create-email',
  RADIOS = '/radios/:serviceId',
  RADIO_DETAILS = '/radio_details/:radioId',
  RADIOS_LABS = '/radios_labs',
  SCHEDULE = '/schedule',
  SESSION_SUMMARY = '/session-summary/:bookingId',
  SETTINGS = '/settings',
  SIGN_IN = '/sign-in',
  SIGN_UP = '/sign-up',
  SIGN_UP_DOCTOR = '/sign-up-doctor',
  SIGN_UP_LAB = '/sign-up-lab',
  SIGN_UP_RADIO = '/sign-up-radio',
  SPECIALISTS = '/specialists',
  VIDEO_CALL = '/call/:bookingId',
}

export default RouteTypes;
