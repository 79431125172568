import { UserRoleTypes } from '../api/types';

export default [
  {
    name: 'default',
    palette: {
      primary: {
        main: '#DC00E4',
      },
      secondary: {
        main: '#620999',
      },
    },
  },
  {
    name: UserRoleTypes.ADMIN,
    palette: {
      primary: {
        main: '#c26dbf',
      },
      secondary: {
        main: '#620999',
      },
    },
  },
  {
    name: UserRoleTypes.PATIENT,
    palette: {
      primary: {
        main: '#ff00ff',
      },
      secondary: {
        main: '#620999',
      },
    },
  },
  {
    name: UserRoleTypes.DOCTOR,
    palette: {
      primary: {
        main: '#620999',
      },
      secondary: {
        main: '#ff00ff',
      },
    },
  },
  {
    name: UserRoleTypes.LAB,
    palette: {
      primary: {
        main: '#990909',
      },
      secondary: {
        main: '#ff00ff',
      },
    },
  },
  {
    name: UserRoleTypes.RADIO,
    palette: {
      primary: {
        main: '#a86525',
      },
      secondary: {
        main: '#ff00ff',
      },
    },
  },
];
