import Button from '@material-ui/core/Button';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import React, { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

export const useToggle = (initialState = false) => {
  const [state, setState] = useState(initialState);

  const toggle = useCallback(() => setState(open => !open), []);

  return [state, toggle] as const;
};

type SummaryDialogProps = {
  title: string;
  content?: string | React.ReactNode;
  open: boolean;
  onClose: () => void;
  extraContent?: () => React.ReactNode;
  extraButton?: () => React.ReactNode;
  customCloseText?: string;
  maxWidth?: DialogProps['maxWidth'];
};

const SummaryDialog = ({
  title,
  content,
  open,
  onClose,
  extraContent,
  extraButton,
  customCloseText,
  maxWidth = 'sm',
}: SummaryDialogProps) => {
  const { t } = useTranslation();

  const closeText = customCloseText ?? t('common.close');

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth={maxWidth}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      {content || extraContent ? (
        <DialogContent>
          {content && (
            <DialogContentText id="alert-dialog-description">
              {content}
            </DialogContentText>
          )}
          {extraContent && extraContent()}
        </DialogContent>
      ) : null}
      <DialogActions>
        {extraButton && extraButton()}
        <Button onClick={onClose} color="secondary" autoFocus={!extraButton}>
          {closeText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default SummaryDialog;
