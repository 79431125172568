import { Box, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import keys from 'lodash/keys';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useDocument } from '../../../api/usages/document';
import { dayName } from '../../../constants/ConfigMoment';
import { i18next } from '../../../locales';
import Timeslots from './Timeslots';

interface DayDateProps {
  doctorId: string;
  day: any;
  handleTimeslotChange: (timeslot: number) => void;
  checkedTimeslot: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formTextName: {
      color: theme.palette.text.primary,
      [theme.breakpoints.down('xs')]: {
        fontSize: '1.1rem',
      },
    },
  }),
);

// eslint-disable-next-line complexity
const DayData = ({
  doctorId,
  day,
  handleTimeslotChange,
  checkedTimeslot,
}: DayDateProps) => {
  const { language } = i18next;
  moment.locale(language);
  const { t } = useTranslation();
  const cx = useStyles();

  const { result: defaultWeek, error, loading } = useDocument<any>({
    path: `schedules/${doctorId}`,
  });
  const { result: customWeek, error: cError, loading: cLoading } = useDocument({
    path: `schedules/${doctorId}/weeks/${day.week()}-${day.year()}`,
  });

  if (loading || error || cError || cLoading) {
    return null;
  }

  const week = keys(customWeek).length > 1 ? customWeek : defaultWeek;

  if (keys(week).length <= 1 || !week.daysData) {
    return (
      <Typography variant="body1" color="textSecondary">
        {t('booking.doctorNotAvailable')}
      </Typography>
    );
  }

  const timeslot = defaultWeek.timeslot;
  const dateKey = moment(day).locale('en').format('dd');
  const dayData = week.daysData[dateKey];
  const isSameOrBefore = moment(day).isSameOrBefore(moment(), 'day');
  let dayWeekName;
  language === 'ar'
    ? (dayWeekName = day.locale('ar-SA').format(dayName))
    : (dayWeekName = day.locale(language).format(dayName));

  dayWeekName = dayWeekName.charAt(0).toUpperCase() + dayWeekName.slice(1);

  return (
    <Box display="flex" flexDirection="column">
      <Typography variant="h6" className={cx.formTextName}>
        {dayWeekName}
      </Typography>
      <Typography variant="subtitle1">
        {t('booking.visitTime', { timeslot })}
      </Typography>
      {!isSameOrBefore && day && dayData && dayData.checked ? (
        <Timeslots
          date={day}
          doctorId={doctorId}
          disabled={day.isBefore(moment(), 'day')}
          ranges={dayData.ranges}
          timeslot={timeslot}
          handleTimeslotChange={handleTimeslotChange}
          checkedTimeslot={checkedTimeslot}
        />
      ) : (
        <Typography variant="body1" color="textSecondary">
          {t('booking.doctorNotAvailableThisDay')}
        </Typography>
      )}
    </Box>
  );
};

export default DayData;
