import { Backdrop, CircularProgress } from '@material-ui/core';
import get from 'lodash/get';
import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import { useUser } from '../api/usages';
import ROUTES from '../constants/routes';

const CustomRoute = ({ component: Component, ...rest }: any) => {
  const profile = get(rest, 'profile', {});
  const profileKeys = Object.keys(profile);
  const user = useUser();

  if (!user) {
    return null;
  }

  const email = user?.email;
  const requiredFields = ['address', 'name', 'testing', 'email'].every(key =>
    profileKeys.includes(key),
  );

  if (profileKeys.length === 0) {
    return (
      <Backdrop open>
        <CircularProgress color="primary" />
      </Backdrop>
    );
  }

  return (
    <Route
      {...rest}
      render={props =>
        requiredFields ? (
          <Component {...props} />
        ) : email || profile.email ? (
          <Redirect to={ROUTES.PROFILE_CREATE} />
        ) : (
          <Redirect to={ROUTES.PROFILE_CREATE_EMAIL} />
        )
      }
    />
  );
};

export default CustomRoute;
