import { makeStyles } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import CheckIcon from '@material-ui/icons/Check';
import React from 'react';

const useStyles = makeStyles({
  subheader: {
    fontWeight: 'bold',
    fontSize: '1rem',
    color: '#620999',
  },
});

type StandardListItem = {
  key: string;
  value: any;
};

type StandardListProps = {
  subheader?: string;
  items: StandardListItem[];
  ignoreRTL?: boolean;
};

const StandardList = ({ subheader, items, ignoreRTL }: StandardListProps) => {
  const cx = useStyles();

  return (
    <List dense>
      <ListSubheader className={cx.subheader}>{subheader}</ListSubheader>
      {items.map(({ key, value: primary }) => (
        <ListItem key={key}>
          <ListItemIcon>
            <CheckIcon />
          </ListItemIcon>
          <ListItemText
            style={ignoreRTL ? { textAlign: 'left' } : undefined}
            primary={primary}
          />
        </ListItem>
      ))}
    </List>
  );
};

export default StandardList;
