import { Query, Prescription } from '../types';
import { UseCollectionOptions, useCollection } from './collection';

export const usePrescriptions = (
  query?: Query,
  options?: UseCollectionOptions,
) => {
  const { results: prescriptions, loading, error } = useCollection<
    Prescription
  >(
    {
      collectionPath: 'prescriptions',
      query,
    },
    options,
  );

  return { prescriptions, loading, error };
};
