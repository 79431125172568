import pick from 'lodash/pick';
import { Observable } from 'rxjs';
import { useObservable } from 'rxjs-hooks';
import { distinctUntilChanged, map } from 'rxjs/operators';

import {
  sessionData$,
  contextRole$,
  contextLanguage$,
  isConnected$,
  isCurrentlyConnected,
} from '../../lib/sessionData';

export function useSessionData<T = Record<string, any>>(keys?: string[]) {
  return useObservable(() => {
    if (!keys || keys.length === 0) {
      return sessionData$ as Observable<T>;
    }

    return sessionData$.pipe(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      distinctUntilChanged((o: any, n: any) => keys.every(k => o[k] === n[k])),
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      map((data: any) => {
        return pick(data || {}, ...keys);
      }),
    ) as Observable<T>;
  });
}

export function useContextRole() {
  return useObservable(() => contextRole$);
}

export function useLanguage() {
  return useObservable(() => contextLanguage$);
}

export function useConnected() {
  return useObservable(() => isConnected$, isCurrentlyConnected());
}
