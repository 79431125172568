import { useCallback, useRef, useState } from 'react';
import { useObservable } from 'rxjs-hooks';

import { CURRENT_RUNTIME, RuntimeTypes } from '../../constants/app';
import { initializingAuth$ } from '../../lib/firebase';
import {
  initialisingProgress$,
  toastNotifications$,
} from '../actions/uiControls';
import '../../lib/firebase/notifications';

export const useInitialisingProgress = () => {
  return useObservable(() => initialisingProgress$);
};

export const useToastNotifications = () => {
  return useObservable(() => toastNotifications$, null);
};

export const useInitialisingFirebase = () => {
  return useObservable(() => initializingAuth$);
};

export const useForceUpdate = () => {
  const [s, forceUpdate] = useState(false);

  return useCallback(() => {
    forceUpdate(!s);
  }, [s]);
};

export const useRuntime = () => {
  const { current } = useRef({
    isMobileApp: CURRENT_RUNTIME === RuntimeTypes.MobileApp,
    runtime: CURRENT_RUNTIME,
    // In future, there will be added some helpers or other stuff depend of platform and runtime.
  });
  return current;
};
