import { createHash, randomBytes } from 'crypto';

export const isMobile = (() => navigator?.userAgent.includes('Mobile'))();

export function generateId(name?: string) {
  if (!name) {
    name = randomBytes(32).toString();
  }
  return createHash('md5').update(name).digest('hex');
}
