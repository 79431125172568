import { Query, LabCategory, RadioCategory } from '../types';
import { useCollection, UseCollectionOptions } from './collection';

export const useLabCategories = (
  query?: Query,
  options?: UseCollectionOptions,
) => {
  const { results: labCategories, loading, error } = useCollection<LabCategory>(
    {
      collectionPath: 'labCategories',
      query,
    },
    options,
  );

  return { labCategories, loading, error };
};

export const useRadioCategories = (
  query?: Query,
  options?: UseCollectionOptions,
) => {
  const { results: radioCategories, loading, error } = useCollection<
    RadioCategory
  >(
    {
      collectionPath: 'radioCategories',
      query,
    },
    options,
  );

  return { radioCategories, loading, error };
};

export function useCategories(
  query?: Query,
  options?: UseCollectionOptions,
  isLab?: boolean,
) {
  const { results, error, loading } = useCollection<
    LabCategory | RadioCategory
  >(
    {
      collectionPath: isLab ? 'labCategories' : 'radioCategories',
      query,
    },
    options,
  );
  return {
    error,
    loading,
    categories: results,
  };
}
