import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Joyride, { CallBackProps, STATUS, ACTIONS, EVENTS } from 'react-joyride';
import { useLocation } from 'react-router-dom';

import { useUserId } from '../api/usages';
import { useRuntime } from '../api/usages/uiControls';
import { desktopSteps, mobileSteps } from '../constants/ConfigJoyride';

const JoyRide = () => {
  const location = useLocation();
  const userId = useUserId();
  const { isMobileApp } = useRuntime();
  const { t } = useTranslation();

  const steps = isMobileApp ? mobileSteps : desktopSteps;
  const joyride = localStorage.getItem('joyride');
  const pathname = location.pathname;

  const [state, setState] = useState({
    steps,
    stepIndex: 0,
    action: '',
  });

  const handleJoyrideCallback = (data: CallBackProps) => {
    const { action, index, status, type } = data;
    // @ts-ignore
    if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      setState(prevState => ({
        ...prevState,
        stepIndex: index + (action === ACTIONS.PREV ? -1 : 1),
        action,
      }));
    }

    const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED];

    if (finishedStatuses.includes(status) || action === 'close') {
      localStorage.setItem('joyride', 'launched');
    }
  };

  const joyrideRun = !userId
    ? !joyride
      ? pathname === '/home' || pathname === '/'
      : false
    : false;

  type Step = {
    disableOverlayClose?: boolean;
    disableBeacon?: boolean;
    styles?: any;
    placement?: string;
    locale: { next: string; back: string; skip: string; last: string };
    title?: string | null;
    hideCloseButton?: boolean;
    content: string;
    target: string;
    spotlightClicks?: boolean;
  };

  const stepsTranslated: Step[] = state.steps.map((step: Step) => {
    const title = step?.title ? t(step?.title) : null;
    const locale = {
      skip: t('joyride.skip'),
      next: t('joyride.next'),
      back: t('joyride.back'),
      last: t('joyride.last'),
    };

    return {
      ...step,
      content: t(step.content),
      title,
      locale,
    };
  });

  return (
    <Joyride
      callback={handleJoyrideCallback}
      // @ts-ignore
      steps={stepsTranslated}
      run={joyrideRun}
      continuous
      scrollToFirstStep
      showSkipButton
      stepIndex={state.stepIndex}
      styles={{
        options: {
          zIndex: 10000,
          primaryColor: '#ff00ff',
        },
      }}
    />
  );
};

export default JoyRide;
