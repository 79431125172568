import React from 'react';

import LayoutDefault from '../../components/LayoutDefault';
import HHLeads from './HHLeads';

const HHFormLeads = () => {
  return (
    <LayoutDefault>
      <HHLeads />
    </LayoutDefault>
  );
};

export default HHFormLeads;
