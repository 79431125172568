import { Query, Specialty } from '../types';
import { useCollection, UseCollectionOptions } from './collection';

export function useSpecialties(query?: Query, options?: UseCollectionOptions) {
  const { results: specialties, loading, error } = useCollection<Specialty>(
    {
      collectionPath: 'specialties',
      query,
    },
    options,
  );

  return { specialties, loading, error };
}
