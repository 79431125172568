import { Divider, IconButton } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Close } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { navigate } from '../api/actions/navigation';
import { UserRoleTypes } from '../api/types';
// import { useWindowDimensions } from '../common';
import SignInForm from '../components/SignInForm';
import SignUpForm from '../components/SignUpForm';
import ROUTE from '../constants/routes';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      marginTop: theme.spacing(4),
    },
  }),
);

interface BookingRegistrationProps {
  onAuthorization: () => void;
  isDialog?: boolean;
  onClose?: any;
}

const BookingRegistration = ({
  onAuthorization,
  isDialog,
  onClose,
}: BookingRegistrationProps) => {
  const { t } = useTranslation();
  const cx = useStyles();
  const [signup, setSignup] = useState(false);
  // const [mobileMode, setMobileMode] = useState({
  //   border: 2,
  //   shadow: 5,
  //   radius: 10,
  // });
  // const [largMode, setLargMode] = useState({
  //   border: 2,
  //   shadow: 5,
  //   radius: 10,
  // });
  // const { height, width } = useWindowDimensions();

  // useEffect(() => {
  //   if (width < 500) {
  //     setLargMode({ border: 0, shadow: 0, radius: 0 });
  //     setMobileMode({ border: 2, shadow: 5, radius: 10 });
  //   } else {
  //     setLargMode({ border: 2, shadow: 5, radius: 10 });
  //     setMobileMode({ border: 0, shadow: 0, radius: 0 });
  //   }
  // }, [width]);

  const handleSignUp = () => {
    onAuthorization();
    if (window.location.pathname === '/radios_labs') {
      localStorage.setItem('signUpFromBooking', 'true');
      navigate(ROUTE.SIGN_UP);
    } else if (window.location.pathname === '/primary_doctors') {
      localStorage.setItem('signUpFromBookingPrimaryDoctor', 'true');
      navigate(ROUTE.SIGN_UP);
    } else if (window.location.pathname === '/specialists') {
      localStorage.setItem('signUpFromBookingSpecialists', 'true');
      navigate(ROUTE.SIGN_UP);
    } else if (window.location.pathname === '/clinic') {
      localStorage.setItem('signUpFromBookingClinic', 'true');
      navigate(ROUTE.SIGN_UP);
    }
  };

  return (
    <Box
      p={3}
      style={{ borderColor: '#e8e8e8' }}
      // border={largMode.border}
      // boxShadow={largMode.shadow}
      // borderRadius={largMode.radius}
    >
      {isDialog ? (
        <Box display="flex" alignItems="center">
          <Box>
            <IconButton onClick={onClose}>
              <Close />
            </IconButton>
          </Box>
          <Box flexGrow={1} />
        </Box>
      ) : null}

      {signup ? (
        <SignUpForm onAuthorized />
      ) : (
        <Grid container spacing={2}>
          <Grid item sm={6} xs={12}>
            <Box
              p={3}
              style={{ borderColor: '#e8e8e8' }}
              // border={mobileMode.border}
              // boxShadow={mobileMode.shadow}
              // borderRadius={mobileMode.radius}
            >
              <Typography variant="h5" gutterBottom>
                {t('common.signUp')}
              </Typography>
              <div style={{ marginTop: '16px' }}>
                {/* <Typography variant="body1" gutterBottom>
                  <strong>{t('common.sign_in_text1')}</strong>
                  {t('common.sign_in_text2')}
                </Typography>
                <Typography variant="body1" gutterBottom>
                  <strong>{t('common.sign_in_text3')}</strong>
                  {t('common.sign_in_text4')}
                </Typography> */}
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSignUp}
                >
                  {t('common.signUpButton')}
                </Button>
                <Typography variant="body1" style={{ marginTop: '16px' }}>
                  <strong>{t('common.registrationAdvantages')}</strong>
                </Typography>
                <ul style={{ listStyleType: 'none', paddingInlineStart: 0 }}>
                  <li>{t('common.advantage1')}</li>
                  <li>{t('common.advantage2')}</li>
                  <li>{t('common.advantage3')}</li>
                  <li>{t('common.advantage4')}</li>
                  <li>{t('common.advantage5')}</li>
                </ul>
              </div>
            </Box>
          </Grid>

          <Grid item sm={6} xs={12}>
            <Box
              p={3}
              style={{ borderColor: '#e8e8e8' }}
              // border={mobileMode.border}
              // boxShadow={mobileMode.shadow}
              // borderRadius={mobileMode.radius}
            >
              <Typography variant="h5" gutterBottom>
                {t('common.orSignInAsBelow')}
              </Typography>
              <SignInForm
                profileType={UserRoleTypes.PATIENT}
                onAuthorized={onAuthorization}
                setBookingSession={onAuthorization}
                isDialog={isDialog}
              />
            </Box>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default BookingRegistration;
