import firebase from 'firebase';
import moment from 'moment';

let serverTimeOffset = 0;

moment.now = () => Date.now() + serverTimeOffset;

function syncTime() {
  firebase
    .database()
    .ref('.info/serverTimeOffset')
    .on('value', res => {
      serverTimeOffset = res.val();
    });
}

syncTime();

// sync every minute
setInterval(syncTime, 36000);
