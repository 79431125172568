/* eslint-disable complexity */
import {
  Divider,
  Button,
  Box,
  ButtonGroup,
  Typography,
  InputAdornment,
  TextField,
  IconButton,
  Hidden,
} from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { CheckCircle, Visibility, VisibilityOff } from '@material-ui/icons';
import firebase from 'firebase';
import React, { useState, useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import {
  doSignInWithEmailAndPassword,
  doSignInWithEmailAndPasswordPromise,
} from '../api/actions/auth';
import { navigate } from '../api/actions/navigation';
import {
  updateInitialisingProgress,
  showErrorToast,
} from '../api/actions/uiControls';
import SignInSchema from '../api/schemas/SignIn';
import { UserRoleTypes } from '../api/types';
import { PasswordValidation, useWindowDimensions } from '../common';
import PhoneVerification from '../components/PhoneVerification';
import RouteTypes from '../constants/routes';
import { functions } from '../lib/firebase';
import { goToLandingByContextRole } from '../lib/goToRouteHelpers';
import { getI18nOptions } from '../lib/utils';
import PasswordRequisite from './PasswordRequisite';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    // wrapper: {
    //   '& .MuiTextField-root': {
    //     margin: '0',
    //   },
    //   '& .MuiInput-root': {
    //     marginBottom: theme.spacing(2),
    //   },
    //   '& .Mui-error': {
    //     marginBottom: theme.spacing(1),
    //   },
    //   '& form': {
    //     marginBottom: theme.spacing(2),
    //   },
    // },
    // inputParent: {
    //   display: 'flex',
    //   flexDirection: 'row',
    //   width: '100%',
    // },
    // submit: {
    //   margin: `8px 0`,
    // },
    // submitBox: {
    //   display: 'flex',
    //   width: '100%',
    // },
    // passwordfieldRTL: {
    //   marginLeft: 22,
    //   width: 266,
    //   backgroundColor: '#FFFFFF',
    //   '& :-webkit-autofill': {
    //     'transition-delay': '9999s',
    //   },
    // },
    // passwordfield: {
    //   marginLeft: 52,
    //   width: 266,
    //   backgroundColor: '#FFFFFF',
    //   '& :-webkit-autofill': {
    //     'transition-delay': '9999s',
    //   },
    // },
    forgotPassWrapper: {
      display: 'flex',
      width: '100%',
    },
    // forgotButton: {
    //   marginRight: 'auto',
    // },
    errorBox: {
      display: 'flex',
      alignSelf: 'center',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    errormsg: {
      fontSize: 16,
      color: 'red',
      textAlign: 'left',
      alignSelf: 'center',
    },
    // errors: {
    //   color: 'red',
    // },
  }),
);

type onAuthorizedArgs = { user: firebase.User; profileType: UserRoleTypes };

interface SignInForm {
  profileType: string;
  onAuthorized?: ((arg: onAuthorizedArgs) => any) | true;
  setBookingSession?: any;
  isDialog?: boolean;
}

type loginError = {
  code: string;
  message: string;
};
function findBestError(errorCodes: any[]) {
  if (errorCodes.length === 1) {
    return errorCodes[0];
  }

  let userNotFoundError = null;
  let otherError = null;

  for (const errorCode of errorCodes) {
    if (errorCode === 'auth/user-not-found') {
      userNotFoundError = errorCode;
    } else if (errorCode !== 'auth/user-not-found') {
      otherError = errorCode;
    }
  }
  return otherError ? otherError : userNotFoundError;
}
function getTranslationKey(errorCode: string) {
  switch (errorCode) {
    case 'auth/user-not-found':
      return 'auth.noUser';
    case 'auth/wrong-password':
      return 'auth.invalidPassword';
    default:
      return 'auth.error';
  }
}

const SignInForm = ({
  profileType,
  onAuthorized,
  setBookingSession,
  isDialog,
}: SignInForm) => {
  const { t, i18n } = useTranslation();
  const direction = i18n.language === 'ar' ? 'rtl' : 'ltr';
  const [phoneNumber, setPhoneNumber] = useState<any>('');
  const [validtionPass, setValidtionPass] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorStatus, setErrorStatus] = useState(false);
  const [password, setPassword] = useState('');
  const [passwordValid, setPasswordValid] = useState<boolean>();
  const [displayedError, setDisplayedError] = useState<Array<string>>();
  const passwordRef = useRef<any>(null);
  const [showPassword, setShowPassword] = useState(false);

  const handleForgotPasswordClick = useCallback(() => {
    navigate(
      RouteTypes.FORGOT_PASSWORD,
      { profileType },
      { phoneNumberFromSignIn: phoneNumber },
    );
  }, [profileType, phoneNumber]);
  const location = useLocation<any>();
  const options = getI18nOptions(location.pathname);
  if (!onAuthorized) {
    onAuthorized = goToLandingByContextRole;
  }

  async function signInWithEmailAndPassword(email: string, password: string) {
    return doSignInWithEmailAndPasswordPromise({
      email,
      password,
      profileType,
      onAuthorized,
    });
  }
  // eslint-disable-next-line consistent-return
  const handleSubmit = async (e: any) => {
    e.preventDefault();
    let errorsMessages: string[] = [];
    let loginWithPhoneNumberDone = false;

    if (setBookingSession) {
      setBookingSession();
    }

    if (phoneNumber === '' || !phoneNumber) {
      setShowError(true);
      return;
    }

    if (validtionPass && passwordValid) {
      setErrorStatus(false);
      updateInitialisingProgress(true);

      try {
        const emailResponse = await functions.httpsCallable(
          'getEmailFromPhone',
        )({
          phoneNumber,
          collection: `${profileType}s`,
        });

        if (
          emailResponse?.data?.email &&
          emailResponse.data.email.includes('@')
        ) {
          await signInWithEmailAndPassword(
            emailResponse?.data?.email,
            password,
          );
        } else {
          const email = `${phoneNumber}@shoofdoctor.com`;
          loginWithPhoneNumberDone = true;
          await signInWithEmailAndPassword(email, password);
        }
      } catch (error) {
        const { code } = error as loginError;
        errorsMessages = [...errorsMessages, code];

        if (!loginWithPhoneNumberDone) {
          try {
            const email = phoneNumber + '@shoofdoctor.com';
            await signInWithEmailAndPassword(email, password);
          } catch (error) {
            const { code } = error as loginError;
            errorsMessages = [...errorsMessages, code];
          }
        }
      } finally {
        if (errorsMessages.length > 0) {
          const errorCode = findBestError(errorsMessages);
          const translationKey = getTranslationKey(errorCode);
          showErrorToast(t(translationKey || ''));
        }
        updateInitialisingProgress(false);
      }
    } else {
      updateInitialisingProgress(false);
      setErrorStatus(true);
    }
  };
  useEffect(() => {
    if (phoneNumber !== '') {
      setShowError(false);
    }
  }, [phoneNumber]);
  useEffect(() => {
    if (location.state?.forcedMobileNumber && passwordRef.current) {
      passwordRef.current?.focus();
    }
  }, [location.state?.forcedMobileNumber]);
  const notPatient = profileType !== 'patient'; // For displaying English text only in other profiles

  const cx = useStyles();

  const handlePasswordChange = (e: any) => {
    setPassword(e.target.value);
    const errors = PasswordValidation(e.target.value, true);
    if (!errors.valid) {
      setPasswordValid(false);
      const translatedErrors: Array<string> = [];
      for (const error of errors.message) {
        translatedErrors.push(t(`errors.password.${error}`));
      }
      setDisplayedError(translatedErrors);
    } else if (errors.valid) {
      setPasswordValid(true);
      setDisplayedError(undefined);
    }
  };
  const handleTogglePasswordVisibility = () => {
    setShowPassword(prevShowPassword => !prevShowPassword);
  };

  return (
    <Box style={{ direction }}>
      <form
        onSubmit={handleSubmit}
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '10px',
          width: '100%',
        }}
      >
        <PhoneVerification
          profileType={profileType}
          onAuthorized={onAuthorized}
          setPhoneNumber={setPhoneNumber}
          setValidtionPass={setValidtionPass}
          errorStatus={errorStatus}
          setErrorStatus={setErrorStatus}
          current={'login'}
          isDialog={isDialog}
        />
        {showError && (
          <Box className={cx.errorBox}>
            <p className={cx.errormsg}>{`*${t('common.emptyPhone')}`}</p>
          </Box>
        )}
        <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
          <Hidden xsDown smDown={isDialog}>
            <div style={{ display: 'flex', flex: 3 }}>
              <Typography style={{ whiteSpace: 'nowrap' }}>
                {t('profile.password')}
              </Typography>
            </div>
          </Hidden>
          <div style={{ display: 'flex', flex: 7 }}>
            {' '}
            <TextField
              onChange={handlePasswordChange}
              value={password}
              type={showPassword ? 'text' : 'password'}
              autoComplete="off"
              placeholder={t('auth.enterPassword')}
              inputRef={passwordRef}
              fullWidth
              inputProps={{
                autoComplete: 'new-password',
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleTogglePasswordVisibility}>
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </div>
        </div>
        <br />
        <div>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disabled={!(phoneNumber !== '' && passwordValid)}
            type="submit"
          >
            {t('common.submit')}
          </Button>
        </div>
      </form>
      <br />
      <Divider />

      <Box className={cx.forgotPassWrapper}>
        <Button onClick={handleForgotPasswordClick} color="primary">
          {profileType !== UserRoleTypes.PATIENT
            ? t('englishOnly.forgotPassword')
            : t('auth.forgotPassword')}
        </Button>
      </Box>
    </Box>
  );
};

export default SignInForm;
