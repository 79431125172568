import moment from 'moment';

import { Booking } from '../../api/types';
import { Timestamp } from '../../lib/firebase';

export const FixSummerTime = (bookings: Booking[]) => {
  //   console.log(bookings);
  const offset = moment().utcOffset() / 60;
  // console.log(offset);
  // most add endDate
  const updatedBookings: Booking[] =
    bookings &&
    bookings.map((booking: any) => {
      const patientTimezoneOffset = booking.TimezoneOffsetUserBooked / 60;
      if (patientTimezoneOffset === 2 && offset === 3) {
        // console.log('doctor up', patientTimezoneOffset - offset);
        const StartDate = booking.startDate.toDate();
        StartDate.setHours(StartDate.getHours() - 1);
        const endDate = booking.endDate.toDate();
        endDate.setHours(endDate.getHours() - 1);
        // console.log(StartDate);
        return {
          ...booking,
          startDate: Timestamp.fromDate(StartDate),
          endDate: Timestamp.fromDate(endDate),
        };
      } else if (patientTimezoneOffset === 3 && offset === 2) {
        // console.log('doctor down', patientTimezoneOffset - offset);
        const StartDate = booking.startDate.toDate();
        // console.log(StartDate);
        StartDate.setHours(StartDate.getHours() + 1);
        const endDate = booking.endDate.toDate();
        // console.log(endDate);
        endDate.setHours(endDate.getHours() + 1);
        // console.log(endDate);
        {
          return {
            ...booking,
            startDate: Timestamp.fromDate(StartDate),
            endDate: Timestamp.fromDate(endDate),
          };
        }
      }
      return booking;
    });
  //   console.log(updatedBookings);
  return updatedBookings;
};
