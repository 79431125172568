import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import React from 'react';

import { hideToast } from '../api/actions/uiControls';
import { useToastNotifications } from '../api/usages/uiControls';
import { t, i18next } from '../locales';

const Toast = () => {
  const notification = useToastNotifications();
  if (!notification) {
    return null;
  }
  if (
    notification.message ===
      'There is no user record corresponding to this identifier. The user may have been deleted.' &&
    i18next.language === 'ar'
  ) {
    notification.message =
      'لا يوجد سجل مستخدم متطابق مع هذا المعرف. قد تم حذف المستخدم.';
  }
  return (
    <Snackbar open={!!notification}>
      <Alert
        onClose={() => hideToast()}
        severity={notification.type}
        elevation={6}
        variant="filled"
      >
        {notification.title && <AlertTitle>{notification.title}</AlertTitle>}
        {notification.message === 'TOO_SHORT'
          ? t('errors.Phone.TooShort')
          : notification.message}
      </Alert>
    </Snackbar>
  );
};

export default Toast;
