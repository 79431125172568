import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Price, PaymentMethod } from '../../api/types/Payments';
import { formatPrice } from '../../lib/utils';
import SummaryDialog, { useToggle } from '../CallSummary/SummaryDialog';

type BoxProps = {
  onClick: () => void;
  className: string;
  disabled?: boolean;
  price: { amount: number | null; currency: Price['currency'] };
  method: PaymentMethod;
  withConfirm?: boolean;
};

const PaymentBox: React.FC<BoxProps> = ({
  onClick,
  className,
  children,
  disabled,
  price,
  method,
  withConfirm = false,
}) => {
  const [isOpen, toggle] = useToggle();
  const { t } = useTranslation();

  const methodToString = (method: PaymentMethod) => {
    switch (method) {
      case PaymentMethod.PAYMOB_CARD:
        return `${t('payments.payMethodCard')}`;
      case PaymentMethod.PAYMOB_WALLET:
        return `${t('payments.payMethodVodafoneCash')}`;
      case PaymentMethod.WALLET:
        return `${t('payments.payMethodWallet')}`;
      case PaymentMethod.PAYMOB_KIOSK:
        return `${t('payments.payMethodKiosk')}`;
      default:
        return '';
    }
  };

  return (
    <>
      {isOpen && (
        <SummaryDialog
          title={t('payments.confirmPayment', {
            amount: formatPrice(price),
            method: methodToString(method),
          })}
          open={isOpen}
          onClose={toggle}
          extraButton={() => (
            <Button
              color="primary"
              variant="contained"
              disabled={disabled}
              onClick={onClick}
            >
              {t('common.ok')}
            </Button>
          )}
          customCloseText={t('common.cancel')}
        />
      )}
      <Grid item md={6} lg={3}>
        <Card variant="outlined">
          <CardActionArea
            onClick={withConfirm ? toggle : onClick}
            className={className}
            disabled={disabled}
          >
            <CardContent>{children}</CardContent>
          </CardActionArea>
        </Card>
      </Grid>
    </>
  );
};

export default PaymentBox;
