import { Query, LabService, RadioService } from '../types';
import { useCollection, UseCollectionOptions } from './collection';

export const useLabServices = (
  query?: Query,
  options?: UseCollectionOptions,
) => {
  const { results: labServices, loading, error } = useCollection<LabService>(
    {
      collectionPath: 'labServices',
      query,
    },
    options,
  );

  return { labServices, loading, error };
};

export const useRadioServices = (
  query?: Query,
  options?: UseCollectionOptions,
) => {
  const { results: radioServices, loading, error } = useCollection<
    RadioService
  >(
    {
      collectionPath: 'radioServices',
      query,
    },
    options,
  );

  return { radioServices, loading, error };
};

export function useServices(
  query?: Query,
  options?: UseCollectionOptions,
  isLab?: boolean,
) {
  const { results, error, loading } = useCollection<LabService | RadioService>(
    {
      collectionPath: isLab ? 'labServices' : 'radioServices',
      query,
    },
    options,
  );
  return {
    error,
    loading,
    services: results,
  };
}
