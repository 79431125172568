import Typography from '@material-ui/core/Typography';
import React from 'react';

interface TitleProps {
  title: string;
  variant?: 'caption' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  className?: any;
}

const Title = ({ title, variant, className }: TitleProps) => (
  <>
    <Typography variant={variant || 'h2'} align="center" className={className}>
      {title}
    </Typography>
  </>
);

export default Title;
