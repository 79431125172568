import { isEqual, uniq, toPairs, fromPairs } from 'lodash';
import { docData, collectionData } from 'rxfire/firestore';
import { of, combineLatest } from 'rxjs';
import { useObservable } from 'rxjs-hooks';
import {
  catchError,
  map,
  switchMap,
  distinctUntilChanged,
  skipWhile,
} from 'rxjs/operators';

import { db } from '../../lib/firebase';
import { AnyRecord } from '../../lib/types';
import { normalize } from '../../lib/utils/normalize';
import { showErrorToast } from '../actions/uiControls';

type Dependency = {
  id: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
};

export type DepConfig = {
  collectionPath: string;
  mapFrom: string;
  mapTo: string;
};

type UseDocumentParams = {
  path: string;
  dependencies?: DepConfig[];
  noId?: boolean;
  subCollection?: string;
};

type UseDocumentOptions = {
  skip?: boolean;
};

type UseDocResult<T> = {
  result: T | null;
  loading: boolean;
  error: null | Error;
};

export const mergeOriginalDocWithDeps = <T extends AnyRecord>(
  depsConfigNormalized: Record<string, DepConfig>,
  originalDoc: T,
  depsNormalized: Record<string, Dependency>,
) => {
  const docPairs = toPairs(originalDoc);

  const merged = docPairs.map(keyVal => {
    const [origDocKey, origDocVal] = keyVal;

    const depConfig = depsConfigNormalized[origDocKey];
    const isForeignKey = !!depConfig;

    if (!isForeignKey) {
      return keyVal;
    }

    const dep = depsNormalized[origDocVal];

    return [depConfig.mapTo, dep];
  });

  return fromPairs(merged) as T;
};

const fetchDependencies = <T extends AnyRecord>(
  depsConfigNormalized: Record<string, DepConfig>,
  originalDoc: T,
) => {
  const depsPaths = uniq(
    Object.values(depsConfigNormalized).map(
      depConfig =>
        `${depConfig.collectionPath}/${originalDoc[depConfig.mapFrom]}`,
    ),
  );

  const deps$ = depsPaths.map(path => docData<Dependency>(db.doc(path), 'id'));

  return combineLatest(deps$).pipe(
    map(deps =>
      mergeOriginalDocWithDeps(
        depsConfigNormalized,
        originalDoc,
        normalize('id', deps),
      ),
    ),
  );
};

const initialResult = { result: null, loading: true, error: null };

export function useDocument<T>(
  params: UseDocumentParams,
  options: UseDocumentOptions = { skip: false },
) {
  const { dependencies = [], noId = false, subCollection } = params;

  const depsConfigNormalized = normalize('mapFrom', dependencies);

  const result = useObservable<UseDocResult<T>, [UseDocumentOptions, string]>(
    (state$, inputs$) =>
      inputs$.pipe(
        distinctUntilChanged(isEqual),
        skipWhile(([opts]) => !!opts.skip),
        switchMap(([, path]) => {
          const docRef = db.doc(path);
          const docData$ = docData<T>(docRef, noId ? undefined : 'id');
          if (subCollection) {
            return combineLatest([
              collectionData<any>(docRef.collection(subCollection), 'id'),
              docData$,
            ]).pipe(
              map(([subCollData, docData]) => ({
                ...docData,
                [subCollection]: subCollData,
              })),
            );
          }

          return docData$;
        }),
        switchMap(result =>
          dependencies.length
            ? fetchDependencies<T>(depsConfigNormalized, result)
            : of(result),
        ),
        map(result => ({ result, loading: false, error: null })),
        catchError(error => {
          showErrorToast(`${error}`);
          return of({ result: null, loading: false, error });
        }),
      ),
    initialResult,
    [options, params.path],
  );

  return result;
}

// export function useDocumentOutside(params: any, options = { skip: false }) {
//   const { dependencies = [], noId = false, subCollection } = params;

//   const depsConfigNormalized = normalize('mapFrom', dependencies);

//   return useObservable(
//     (state$, inputs$) =>
//       inputs$.pipe(
//         distinctUntilChanged(isEqual),
//         skipWhile(([opts]) => !!opts.skip),
//         switchMap(([, path]) => {
//           const docRef = db.doc(path);
//           const docData$ = docData<T>(docRef, noId ? undefined : 'id');
//           if (subCollection) {
//             return combineLatest([
//               collectionData(docRef.collection(subCollection), 'id'),
//               docData$,
//             ]).pipe(
//               map(([subCollData, docData]) => ({
//                 ...docData,
//                 [subCollection]: subCollData,
//               })),
//             );
//           }

//           return docData$;
//         }),
//         switchMap(result =>
//           dependencies.length
//             ? fetchDependencies(depsConfigNormalized, result)
//             : of(result),
//         ),
//         map((result: any) => ({ result, loading: false, error: null })),
//         catchError(error => {
//           console.error(error);
//           return of({ result: null, loading: false, error });
//         }),
//       ),
//     initialResult,
//     [options, params.path],
//   );
// }
// import { useDocument } from './useDocument';

// const docObservable = useDocument({ path: 'path/to/document' });
// docObservable.subscribe((data) => {
//   console.log(data);
// });
